import SortableListBoxGroup from "../../table/SortableListBoxGroup.js";
import ObjectTranslation from "../../../objects/ObjectTranslation.js";
import CorrectionValue from "../../../objects/CorrectionValue.js";
import Objects from "../../../objects/Objects.js";
import Translation from "../../../objects/Translation.js";
import EditCorrectionValueOverlay from "./EditCorrectionValueOverlay.js";

class CorrectionValueList extends SortableListBoxGroup{
	constructor(config) {
		super(config);
		if (config) {
			if (config.standort) {
				this.standort = config.standort;
			}
			if (config.meterPoint) {
				this.meterPoint = config.meterPoint;
			}
		}
		let scope = this;
		ObjectTranslation.getObjectNameTranslation(CorrectionValue.OBJECT_DESCRIPTION_ID, function(objectNameTranslation){
			scope.contentHeadline.text(objectNameTranslation.namePlural);
		});
		this.contentHeadline.getNewButton().click(function(event) {
			new EditCorrectionValueOverlay({
				meterPoint: scope.meterPoint,
				standort: scope.standort,
				eventCallback: function(event){
					scope.pushRow(event.data, true);
				}
			});
		}, {});
		this.load();
	}
	
	load() {
		let scope = this;
		if(this.standort != null){
			CorrectionValue.loadAll(this.standort.id, function(correctionValues){
				scope.pushAll(correctionValues);
			});
		}
		if(this.meterPoint != null){
			CorrectionValue.loadAllByMeterPoint(this.meterPoint.id, function(correctionValues){
				scope.pushAll(correctionValues);
			});
		}
	}
	
	renderHead() {
		this.addSortHeaderByFDI(CorrectionValue.FIELD_DATE_ID, 'datum');
		this.addSortHeaderByFDI(CorrectionValue.FIELD_VALUE_ID, 'wert');
		this.addSortHeaderByFDI(CorrectionValue.FIELD_BALANCE_GROUP_ID, 'bilanzgruppe.name');
		this.addSortHeaderByFDI(CorrectionValue.FIELD_CATEGORY_ID, 'korrekturwertKategorie.name');
		this.addSortHeaderByFDI(CorrectionValue.FIELD_DESCRIPTION_ID, 'beschreibung');
	}
	
	renderRow(entry, row) {
		row.addCellDomObj(entry.getLink(),'date');
		let valueCell = row.addNumber(entry.wert);
		Translation.load(entry.einheit, function(translation){
			valueCell.setUnit(translation.text);
		});
		let balanceGroupCell = row.addText('--');
		entry.getBalanceGroup(function(balanceGroup){
			if (balanceGroup == null) {
				return;
			}
			Translation.load(balanceGroup.name, function(translation){
				balanceGroupCell.text(translation.text);
			});
		});
		
		let categoryCell = row.addText('--');
		entry.getCorrectionValueCategory(function(correctionValueCategory){
			if (correctionValueCategory == null) {
				return;
			}
			Translation.load(correctionValueCategory.name, function(translation){
				categoryCell.text(translation.text);
			});
		});
		row.addText(entry.beschreibung);
	}
}
export default CorrectionValueList;