import OverlayBox from "../../OverlayBox.js";
import Button from "../../Button.js";
import ObjectTranslation from "../../../objects/ObjectTranslation.js";
import AddressBookEntry from "../../../objects/AddressBookEntry.js";
import EditAddressBookEntryOverlay from "./EditAddressBookEntryOverlay.js";
import Observable from "../../../misc/Observable.js";
import AddressBookEntryDetailTable from "./AddressBookEntryDetailTable.js";

class ShowAddressBookEntryOverlay extends Observable {
	constructor(config) {
		super(config);
		if (config) {
			this.addressBookEntry = config.addressBookEntry;
		}
		let scope = this;
		this.overlayBox = new OverlayBox({
			isCloseable: true
		});
		ObjectTranslation.getObjectNameTranslation(AddressBookEntry.OBJECT_DESCRIPTION_ID, function(objectNameTranslation){
			scope.overlayBox.getHeader().text(objectNameTranslation.name);
			}
		);
		let editButton = Button.createEditButton();
		this.overlayBox.getFooter().append(editButton);
		editButton.click(function() {
			scope.overlayBox.remove();
			new EditAddressBookEntryOverlay({
				addressBookEntry: scope.addressBookEntry,
				eventCallback:function(event){scope.notify(event)}
			});
		});
		
		let detailTable = new AddressBookEntryDetailTable({
			addressBookEntry: this.addressBookEntry
		});
		this.overlayBox.append(detailTable);
	}
}
export default ShowAddressBookEntryOverlay;