import Objects from "./Objects.js";
import UI from "../ui/defaultUi.js";
import RequestChain from "../misc/RequestChain.js";
class Translation{

	/**
	 * use this to load your translations by a key text
	 */
	static load(text, callback) {
		Translation.requestChain.push(new LoadRequest(text, callback));
	}
	
	static loadKey(text, callback) {
		var endpoint = "/api/UebersetzungKey/" + encodeURIComponent(text);
		endpoint = Objects.rootURL + endpoint;
		$.ajax({   
			type: "GET",
			url: endpoint,
			dataType: "json",
			async: false,
			contentType: "application/json; charset=utf-8",
			success: function(key) {
				callback(key);
			},
			error: function(jqXHR, textStatus, errorThrown ) {
				if(jqXHR.status != 401){
					UI.addToError(jqXHR.responseText);
				} else {
					callback(null);
				}
			}
		});
	}
	
	static createKey(text, callback) {
		var entry = {key: text};
		Objects.save(entry, callback, "/api/UebersetzungKey/insert");
	}
	
	static loadTranslation(keyID, callback) {
		var endpoint = "/api/Uebersetzung/"+keyID;
		endpoint = Objects.rootURL + endpoint;
		$.ajax({   
			type: "GET",
			url: endpoint,
			dataType: "json",
			async: false,
			contentType: "application/json; charset=utf-8",
			success: function(translation) {
				callback(translation);
			},
			error: function(jqXHR, textStatus, errorThrown ) {
				if(jqXHR.status != 401){
					UI.addToError(jqXHR.responseText);
				}
			}
		});
	}
	
	static addFakeTranslation(text) {
		console.log("fake translation: " + text);
		let translation = new Object();
		translation.text = text;
		Translation.cache[text] = translation;
		return translation;
	}
}
Translation.cache = {};
Translation.requestChain = new RequestChain();
export default Translation;

class LoadRequest {
	
	constructor(text, callback){
		this.text = text;
		this.callback = callback;
	}
	
	run() {
		var translationCacheed = Translation.cache[this.text];
		if (translationCacheed) {
			this.callback(translationCacheed);
			Translation.requestChain.next();
			return;
		}
		var scope = this;
		Translation.loadKey(this.text,function(key){
			if(key != null && key.id){
				Translation.loadTranslation(key.id, function(translation) {
					if (translation == null) {
						translation = Translation.addFakeTranslation(scope.text);
						scope.callback(translation);
						Translation.requestChain.next();
						return;
					}
					Translation.cache[scope.text] = translation;
					scope.callback(translation);
					Translation.requestChain.next();
					
				});
			} else {
				Translation.createKey(scope.text, function(){});
				let translation = Translation.addFakeTranslation(scope.text);
				scope.callback(translation);
				Translation.requestChain.next();
				return;
			}
		});
	}
}