import DetailTable from "../../table/DetailTable.js";
import AddressBookEntry from "../../../objects/AddressBookEntry.js";

class AddressBookEntryDetailTable extends DetailTable{
	constructor(config) {
		super(config);
		if (config != null) {
			if (config.addressBookEntry != null) {
				this.setAddressBookEntry(config.addressBookEntry);
			}
		}
	}
	
	setAddressBookEntry(addressBookEntry) {
		this.addressBookEntry = addressBookEntry;
		this.addText(AddressBookEntry.FIRST_NAME_FIELD_DESCRIPTION_ID, this.addressBookEntry.firstName);
		this.addText(AddressBookEntry.LAST_NAME_FIELD_DESCRIPTION_ID, this.addressBookEntry.lastName);
		this.addText(AddressBookEntry.FORM_OF_ADDRESS_FIELD_DESCRIPTION_ID, this.addressBookEntry.formOfAddres);
		this.addText(AddressBookEntry.JOB_FIELD_DESCRIPTION_ID, this.addressBookEntry.job);
		this.addEmailAddress(AddressBookEntry.EMAIL_FIELD_DESCRIPTION_ID, this.addressBookEntry.email);
		this.addPhoneNumber(AddressBookEntry.PHONE_NUMBER_FIELD_DESCRIPTION_ID, this.addressBookEntry.phoneNumber);
		this.addPhoneNumber(AddressBookEntry.MOBILE_NUMBER_FIELD_DESCRIPTION_ID, this.addressBookEntry.mobileNumber);
		this.addDate(AddressBookEntry.GDPR_APPROVAL_FIELD_DESCRIPTION_ID, this.addressBookEntry.gdprApproval);
		this.addTextBig(AddressBookEntry.ADDITIONAL_INFORMATION_FIELD_DESCRIPTION_ID, this.addressBookEntry.additionalInformation);
	}
	
}
export default AddressBookEntryDetailTable;