import UI from "./defaultUi.js";
class OverlayCloseCross extends UI{
	constructor(){
		super();
		this.closeCross = $('<span class="overlayClose glyphicon glyphicon-remove-circle" aria-hidden="true"></span>');
		super.setBaseJQueryObject(this.closeCross);
	}
	
	click(clickHandler){
		this.closeCross.click(clickHandler);
	}
}
export default OverlayCloseCross;