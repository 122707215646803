import SortableListBoxGroup from "../../table/SortableListBoxGroup.js";
import ObjectTranslation from "../../../objects/ObjectTranslation.js";
import Meter from "../../../objects/Meter.js";
import Objects from "../../../objects/Objects.js";
import MeterPoint from "../../../objects/MeterPoint.js";
import EditMeterOverlay from "../meter/EditMeterOverlay.js";

class MeterList extends SortableListBoxGroup{
	constructor(config) {
		super(config);
		let scope = this;
		if (config) {
			if (config.meterPointId) {
				MeterPoint.load(config.meterPointId, function(meterPoint){
					scope.setMeterpoint(meterPoint);
				});
			}
			if (config.meterPoint) {
				this.setMeterpoint(config.meterPoint);
			}
		}
		ObjectTranslation.getObjectNameTranslation(Meter.OBJECT_DESCRIPTION_ID, function(objectNameTranslation){
			scope.contentHeadline.text(objectNameTranslation.namePlural);
		});
		this.contentHeadline.getNewButton().click(function(event) {
			new EditMeterOverlay({
				meterPoint: scope.meterPoint,
				eventCallback: function(event){
					if (event) {
						
					}
				}
			});
		}, {});
	}
	
	setMeterpoint(meterPoint) {
		this.meterPoint = meterPoint;
		let scope = this;
		Meter.loadAll(this.meterPoint.id, function(meters){
			scope.pushAll(meters);
		});
	}
	
	renderHead() {
		this.addSortHeaderByFDI(Meter.METER_NUMBER_FIELD_DESCRIPTION_ID, 'nr');
		this.addSortHeaderByFDI(Meter.METER_FACTOR_FIELD_DESCRIPTION_ID, 'faktor');
		this.addSortHeaderByFDI(Meter.READ_FIELD_DESCRIPTION_ID, 'ablesen');
	}
	
	renderRow(entry) {
		let areaA = $('<a href="' + Objects.rootURL + '/Frontend/Zaehler/page.jsp?zaehlerID=' + entry.id + '"></a>');
		areaA.text(entry.nr);
		this.table.addCellDomObj(areaA);
		this.table.addNumber(entry.faktor);
		this.table.addBoolean(entry.ablesen);
	}
}
export default MeterList;