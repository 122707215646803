import Objects from "./Objects.js";
import CachedLoadChain from "../misc/CachedLoadChain.js";
import SelectListEntryTranslation from "./SelectListEntryTranslation.js";

class SelectListEntry{
	constructor(obj) {
		obj && Object.assign(this, obj);
	}
	
	static load(sleId, callback){
		SelectListEntry.cachedLoadChain.load(sleId, function(data){
			callback(new SelectListEntry(data));
		});
	}
	
	getUsersTranslation(callback) {
		if (this.usersTranslation != null) {
			callback(this.usersTranslation);
		}
		let scope = this;
		SelectListEntryTranslation.load("byUser/" + this.id,function(slet){
			scope.usersTranslation = slet;
			callback(slet);
		});
	}
}
SelectListEntry.API_ENDPOINT = '/api/SelectListEntry/';
SelectListEntry.cachedLoadChain = new CachedLoadChain(SelectListEntry.API_ENDPOINT);

export default SelectListEntry;