import UI from "../defaultUi.js";
import Button from "../Button.js";
import License from "../../objects/License.js";
import Translation from "../../objects/Translation.js";

class LicenseUI extends UI{
	constructor(config){
		super();
	}
	static loadAllReadingsButton(){
		var button = new Button({
			iconCssClass: Button.DOWNLOAD_ICON
		});
		Translation.load('downloadAllReadings', function(translation){
			button.text(' ' + translation.text);
		});
		button.click(function(){
			window.location.assign(License.API_ENDPOINT + 'allReadings.csv');
		});
		return button;
	}
}
export default LicenseUI;