import UI from "../defaultUi.js";
import Label from "../content/Label.js";
import TableCell from "./TableCell.js";
import DateTableCell from "./DateTableCell.js";
import DateTimeTableCell from "./DateTimeTableCell.js";
import NumberTableCell from "./NumberTableCell.js";
import BooleanTableCell from "./BooleanTableCell.js";
import TableRow from "./TableRow.js";

class ListTable extends UI{
	constructor(columnCount){
		super();
		this.tableDiv = $('<table class="list"></table>');
		super.setBaseJQueryObject(this.tableDiv);
		this.renderEmpty();
	}
	
	renderEmpty() {
		this.tableDiv.html("");
		this.header = $('<thead></thead>');
		this.tableDiv.append(this.header);
		this.headerRow = $('<tr></tr>');
		this.header.append(this.headerRow);
		this.body = $('<tbody></tbody>');
		this.tableDiv.append(this.body);
	}
	
	headerAppend(toAppend) {
		let cell = $('<th></th>');
		this.headerRow.append(cell);
		if (toAppend instanceof UI) {
			cell.append(toAppend.getJQueryRepresentation());
		} else {
			cell.append(toAppend);
		}
		return cell;
	}
	
	headerAppendLable(fieldId) {
		let label = new Label({
			fieldId: fieldId,
			table: true
		});
		return this.headerAppend(label);
	}
	
	getTableDiv() {
		return this.tableDiv;
	}
	
	newRow(){
		this.curentTableRowDiv = new TableRow();
		this.tableDiv.append(this.curentTableRowDiv.getJQueryRepresentation());
		return this.curentTableRowDiv;
	}
	
	addCell(value, css){
		return this.curentTableRowDiv.addCell(value, css);
	}
	
	addCellDomObj(domObj, css){
		return this.curentTableRowDiv.addCellDomObj(domObj, css);
	}
	
	append(toAppend) {
		return this.curentTableRowDiv.append(toAppend);
	}
	
	addText(text){
		let cell = new TableCell({
			contentText: text,
			realTable:true
		});
		this.append(cell);
		return cell;
	}
	
	addDate(date){
		let cell = new DateTableCell({
			date: date,
			realTable:true
		});
		this.append(cell);
		return cell;
	}
	
	addDateTime(date){
		let cell = new DateTimeTableCell({
			date: date,
			realTable:true
		});
		this.append(cell);
		return cell;
	}
	
	addNumber(number, unit){
		let cell = new NumberTableCell({
			number: number,
			unit: unit,
			realTable:true
		});
		this.append(cell);
		return cell;
	}
	
	addBoolean(boolean){
		let cell = new BooleanTableCell({
			boolean: boolean,
			realTable:true
		});
		this.append(cell);
		return cell;
	}
	
	clear() {
		this.headerRow.html('');
		this.clearBody();
	}
	
	clearBody() {
		this.body.html('');
	}
}
export default ListTable;