import UI from "../defaultUi.js";
import ContentBoxGroup from "../content/ContentBoxGroup.js";
import ContentBox from "../ContentBox.js";
import ListDivHeader from "../content/ListDivHeader.js";
import SortableList from "../../misc/SortableList.js";
import ListTable from "./ListTable.js";
import DivContentList from "./DivContentList.js";
import Label from "../content/Label.js";

class SortableListBoxGroup extends UI{
	constructor(config) {
		super(config);
		this.contentBoxGroup = new ContentBoxGroup();
		super.setBaseJQueryObject(this.contentBoxGroup);
		this.contentHeadline = new ListDivHeader(config);
		this.contentBoxGroup.append(this.contentHeadline);
		this.rowDatas = new SortableList();
		this.showing = false;

		let scope = this;
		if (config == null || config.addDisplayAction == null || config.addDisplayAction) {
			this.contentHeadline.getShowButton().click(function(event) {
				scope.render();
				}, {});
			this.contentHeadline.getHideButton().click(function(event) {
				scope.hideContent();
			}, {});
		}
		if (config != null) {
			this.isDivTable = config.isDivTable;
			if (config.show){
				this.show();
			}
		}
			
	}
	
	show() {
		this.contentHeadline.getShowButton().triggerClick();
		return this;
	}
	
	hide() {
		this.contentHeadline.getHideButton().triggerClick();
		return this;
	}
	
	hideNewButton() {
		this.contentHeadline.getNewButton().hide();
		return this;
	}
	
	push(data) {
		return this.pushRow(data, true);
	}
	
	pushRow(data, rerender) {
		this.rowDatas.push(data);
		if(rerender){
			this.rerender();
		}
		return this;
	}
	
	pushAll(data) {
		this.rowDatas.pushAll(data);
		this.rerender();
		return this;
	}
	
	clearRows() {
		this.rowDatas.clear();
		this.rerender();
		return this;
	}
	
	hideContent() {
		this.showing = false;
		if (this.contentBox) {
			this.contentBox.remove();
		}
		return this;
	}
	
	render() {
		this.showing = true;
		this.contentBox = new ContentBox();
		this.contentBoxGroup.append(this.contentBox);
		if (this.isDivTable) {
			this.table = new DivContentList();
		} else {
			this.table = new ListTable();
		}
		this.contentBox.append(this.table);
		this.renderHead();
		this.renderRows();
		return this;
	}
	
	rerender() {
		if (this.table) {
			this.table.clearBody();
			this.renderRows();
		}
		return this;
	}
	
	sortBy(fieldPath) {
		this.rowDatas.sortBy(fieldPath);
		this.rerender();
		return this;
	}
	
	renderRows() {
		var scope = this;
		this.rowDatas.forEach(function(entry){
			let row;
			if (!scope.isDivTable) {
				row = scope.table.newRow();
			}
			scope.renderRow(entry, row);
		});
		return this;
	}
	
	removeRow(data) {
		this.rowDatas.remove(data);
		this.rerender();
		return this;
	}
	
	renderRow(entry, row) {
		throw new Error("please override renderRow(entry)");
	}
	
	renderHead() {
		throw new Error("please override renderHead()");
	}
	
	addSortHeaderByFDI(fiedDescriptionId, sortPath) {
		let label = new Label({
			fieldId: fiedDescriptionId,
			table: !this.isDivTable
		});
		this.addSortHeaderElement(label, sortPath);
		return this;
	}
	
	addSortHeaderTranslation(translationKey, sortPath) {
		let label = new Label({
			translationKey: translationKey,
			table: !this.isDivTable
		});
		this.addSortHeaderElement(label, sortPath);
		return this;
	}
	
	addSortHeaderText(text, sortPath) {
		let label = new Label({
			text: text,
			table: !this.isDivTable
		});
		this.addSortHeaderElement(label, sortPath);
		return this;
	}
	
	addSortHeaderElement(element, sortPath) {
		let scope = this;
		let cell = this.table.headerAppend(element);
		cell.click(function(){
			scope.sortBy(sortPath);
		});
		return this;
	}
}
export default SortableListBoxGroup;