import UI from "../../defaultUi.js";
import LabeledTableCell from "../../table/LabeledTableCell.js";
import LabeledTable from "../../table/LabeledTable.js";
import EditGoalProgressOverlay from "./EditGoalProgressOverlay.js";
import ContentBox from "../../ContentBox.js";
import Goal from "../../../objects/Goal.js";
import Button from "../../Button.js";

class GoalDetailBox  extends UI{
	constructor(config) {
		super(config);
		this.contentBox = new ContentBox();
		super.setBaseJQueryObject(this.contentBox);
		let entry = config.goal;
		let labeledTable = new LabeledTable();
		this.contentBox.append(labeledTable);
		labeledTable.append(
				new LabeledTableCell({
					fieldId: Goal.NAME_FIELD_ID,
					contentText: entry.name
				}));
		let progessCell = new LabeledTableCell({
			fieldId: Goal.PROGRESS_FIELD_ID,
			contentText: (Math.round(entry.progress * 100) + '%')
		})
		labeledTable.append(progessCell);
		if (config.progessEditable) {
			let editButton = Button.createEditButton();
			progessCell.getContentCell().append(editButton.getJQueryRepresentation());
			let scope = this;
			editButton.click(function(){
				new EditGoalProgressOverlay({
					goal: entry,
					eventCallback: function(event){
						scope.rerender();
					}
				});
			});
		}
		let startCell = new LabeledTableCell({
			fieldId: Goal.START_FIELD_ID
		});
		labeledTable.append(startCell);
	
		if (entry.start) {
			startCell.getContentCell().text(new Date(entry.start).toLocaleDateString());
		} else {
			startCell.getContentCell().text('--');
		}
		
		let endCell = new LabeledTableCell({
			fieldId: Goal.END_FIELD_ID
		});
		labeledTable.append(endCell);
	
		if (entry.end) {
			endCell.getContentCell().text(new Date(entry.end).toLocaleDateString());
		} else {
			endCell.getContentCell().text('--');
		}
		
		let textDiv = $('<div style="white-space: pre-wrap;padding: 10px;"></div>');
		this.contentBox.append(textDiv);
		textDiv.text(entry.description);
	}
}
export default GoalDetailBox;