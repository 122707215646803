import TableCell from "./TableCell.js";
import Link from "../content/Link.js";

class PhoneNumberTableCell extends TableCell {
	constructor(config) {
		if (config == null) {
			config = new Object();
		}
		super(config);
		this.setPhoneNumber(config.phoneNumber);
	}
	
	setPhoneNumber(phoneNumber) {
		if (phoneNumber == null) {
			this.getContentCell().text("--");
		}
		let scope = this;
		this.clear();
		let phoneLink = new Link({
			href: "tel:" + phoneNumber,
			text: phoneNumber
		});
		this.append(phoneLink);
	}
	
}
export default PhoneNumberTableCell;