import SortableListBoxGroup from "../../table/SortableListBoxGroup.js";
import ObjectTranslation from "../../../objects/ObjectTranslation.js";
import Consumption from "../../../objects/Consumption.js";
import Objects from "../../../objects/Objects.js";
import TableCell from "../../table/TableCell.js";
import EditConsumptionOverlay from "../consumption/EditConsumptionOverlay.js";
import ConsumptionDetailOverlay from "../consumption/ConsumptionDetailOverlay.js";
import Button from "../../Button.js";
import Translation from "../../../objects/Translation.js";
import NumberTableCell from "../../table/NumberTableCell.js";
import UploadConsumptionCSV from "../consumption/UploadConsumptionCSV.js";
import EVAEvent from "../../EVAEvent.js";

class ConsumptionList extends SortableListBoxGroup{
	constructor(config) {
		super(config);
		if (config) {
			if (config.meterPoint) {
				this.meterPoint = config.meterPoint;
			}
		}
		let scope = this;
		this.contentHeadline.setHeadlineById(Consumption.OBJECT_DESCRIPTION_ID);
		this.contentHeadline.getNewButton().click(function(event) {
			new EditConsumptionOverlay({
				meterPoint: scope.meterPoint,
				eventCallback: function (event) {
					scope.pushRow(event.data, true); 
				}
			});
		}, {});
		
		let uploadCsvButton = Button.createUploadCsvButton();
		this.contentHeadline.append(uploadCsvButton);
		uploadCsvButton.click(function(event) {
			new UploadConsumptionCSV({
				meterPoint:scope.meterPoint,
				eventCallback: function(event) {
					scope.clearRows();
					scope.load();
				}
			});
		});
		let downloadCsvButton = Button.createDownloadCsvButton();
		this.contentHeadline.append(downloadCsvButton);
		downloadCsvButton.click(function(event) {
			window.location.assign(Objects.rootURL + '/api/Verbrauch/consumption_for_meterpoint' + scope.meterPoint.id + '.csv');
		});
		
		if (this.meterPoint != null) {
			this.load();
		}
	}
	
	load() {
		let scope = this;
		Consumption.loadAll(this.meterPoint.id, function(consumptions){
			scope.pushAll(consumptions);
		});
	}
	
	renderHead() {
		this.addSortHeaderByFDI(Consumption.FROM_FDI, 'von');
		this.addSortHeaderByFDI(Consumption.TILL_FDI, 'bis');
		this.addSortHeaderByFDI(Consumption.AMOUNT_FDI, 'menge');
		this.addSortHeaderByFDI(Consumption.UNIT_FDI, 'einheitMediumId');
		let scope = this;
		this.meterPoint.isAdjusted(function(isAdjusted){
			if (isAdjusted) {
				Translation.load("Verbrauch pro Grad-Tag", function(translation){
					scope.meterPoint.getMedium(function(medium){
						let text = translation.text + " " + medium.getMainUnit() + "/K d"
						scope.addSortHeaderText(text, 'berechneterGradTagsVerbrauch');
						
					});
				});
			} else {
				Translation.load("Verbrauch pro Tag", function(translation){
					scope.meterPoint.getMedium(function(medium){
						let text = translation.text + " " + medium.getMainUnit() + "/d"
						scope.addSortHeaderText(text, 'berechneterVerbrauchProTag');
						
					});
				});
			}
		});
	}
	
	renderRow(entry) {
		let scope = this;
		let areaA = $('<a></a>');
		areaA.text(new Date(entry.von).toLocaleDateString());
		areaA.click(function() {
			new ConsumptionDetailOverlay({
				consumption: entry,
				eventCallback: function (event) {
					if (event.type == EVAEvent.TYPE_REMOVE) {
						scope.removeRow(event.data);
						return;
					}
					scope.rerender(); 
				}
			});
		});
		let vonTableCell = new TableCell({
			realTable:true,
			type: TableCell.TYPE_DATE,
			contentHtml: areaA
		});
		this.table.append(vonTableCell);
		this.table.addDate(entry.bis);
		this.table.addNumber(entry.menge);
		let tableCell = new TableCell({
			realTable:true
		});
		this.table.append(tableCell);
		entry.getUnit(function(mediumUnit){
			tableCell.text(mediumUnit.name);
		});
		let tableCellRelativ = new NumberTableCell({
			realTable:true
		});
		this.table.append(tableCellRelativ);
		entry.isAdjusted(function(isAdjusted){
			if (isAdjusted) {
				tableCellRelativ.setNumber(entry.berechneterGradTagsVerbrauch);
			} else {
				tableCellRelativ.setNumber(entry.berechneterVerbrauchProTag);
			}
		});
	}
}
export default ConsumptionList;