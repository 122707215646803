import UI from "../defaultUi.js";

class SelectListOption extends UI{
	constructor(config){
		super(config);
		this.selectOption = $('<option>');
		super.setBaseJQueryObject(this.selectOption);
		if (config) {
			if (config.value) {
				this.setValue(config.value);
			}
			if(config.text) {
				this.setText(config.text);
			}
			this.setSelected(config.selected);
		}
	}
	
	setSelected(selected){
		if (selected) {
			return this.selectOption.attr('selected', "selected");
		} else {
			return this.selectOption.removeAttr('selected');
		}
	}
	
	setValue(value) {
		return this.selectOption.attr('value', value);
	}
	
	setText(text) {
		return this.selectOption.text(text);
	}
	
	text(text) {
		return this.selectOption.text(text);
	}
	
}
export default SelectListOption;