import OverlayBox from "../OverlayBox.js";
import SortableListDiv from "./SortableListDiv.js";
import ObjectTranslation from "../../objects/ObjectTranslation.js";

class SortableListOverlay extends SortableListDiv{
	constructor(config) {
		super(config);
		this.overlayBox = new OverlayBox(config);
		this.overlayBox.append(this);
		this.render();
		if (config != null) {
			if (config.objectDescriptionId != null) {
				this.setHeadlineById(config.objectDescriptionId);
			}
		}
	}
	
	setHeadlineById(objectDescriptionId) {
		let scope = this;
		ObjectTranslation.getObjectNameTranslation(objectDescriptionId, function(objectNameTranslation){
				scope.overlayBox.getHeader().text(objectNameTranslation.namePlural);
			});
	}
}
export default SortableListOverlay;