import UI from "../defaultUi.js";
import DivContentListRow from "./DivContentListRow.js";

class DivContentList extends UI{
	
	constructor(config) {
		super();
		this.tableDiv = $('<div class="content_list"></div>');
		super.setBaseJQueryObject(this.tableDiv);
	}
	
	getTableDiv() {
		return this.tableDiv;
	}
	
	newRow(){
		let newRow = new DivContentListRow();
		this.append(newRow);
		return newRow;
	}
	
	clear() {
		this.clearBody();
	}
	
	clearBody() {
		this.tableDiv.html('');
	}
	
	append(toAppend) {
		if (toAppend instanceof DivContentListRow) {
			this.tableDiv.append(toAppend.getJQueryRepresentation());
			this.curentTableRow = toAppend;
		} else {
			this.curentTableRow.append(toAppend);
		}
	}
}
export default DivContentList;