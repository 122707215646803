import Objects from "./Objects.js";
import CachedLoadChain from "../misc/CachedLoadChain.js";
import Standort from "./Standort.js";

class FloorArea{
	
	constructor(obj) {
		obj && Object.assign(this, obj);
	}
	
	static load(id, callback){
		FloorArea.cachedLoadChain.load(id, function(data){
			callback(new FloorArea(data));
		});
	}
	
	static loadAll(standortId, callback){
		Objects.load(function(data){
			let arrayLength = data.length;
			let objects = [];
			for (let i = 0; i < arrayLength; i++) {
				let object = new FloorArea(data[i]);
				objects.push(object);
				FloorArea.cachedLoadChain.cache[object.id] = object;
			}
			callback(objects);
		}, FloorArea.API_ENDPOINT + "all?standortId=" + standortId);
	}
	
	upsert(callback) {
		let scope = this;
		Objects.post(this, function(floorArea){
			floorArea && Object.assign(scope, floorArea);
			callback(scope);
		}, FloorArea.API_ENDPOINT + "upsert");
	}
	
	remove(callback) {
		Objects.remove(callback, FloorArea.API_ENDPOINT + this.id);
	}
	
	getStandort(callBack) {
		if (this.standort != null) {
			if (!(this.standort instanceof Standort)) {
				this.standort = new Standort(this.standort);
			}
			callBack(this.standort);
			return;
		}
		if (this.standortId != null && this.standortId != 0) {
			let scope = this;
			Standort.load(this.standortId, function(standort){
				scope.standort = standort;
				callBack(standort);
			});
			return;
		}
		callBack(null);
	}
}
FloorArea.API_ENDPOINT = "/api/Flaeche/";
FloorArea.cachedLoadChain = new CachedLoadChain(FloorArea.API_ENDPOINT);
FloorArea.OBJECT_DESCRIPTION_ID = 21;
FloorArea.FIELD_NAME_ID = 41;
FloorArea.FIELD_STANDORT_ID = 40;
FloorArea.FIELD_AREA_M2_ID = 42;
FloorArea.FIELD_START_ID = 94;
FloorArea.FIELD_END_ID = 95;
export default FloorArea;