import LabeledTableCell from "./LabeledTableCell.js";
import User from "../../objects/User.js";
import PhoneNumberTableCell from "./PhoneNumberTableCell.js";

class LabeledPhoneNumberTableCell extends LabeledTableCell{
	constructor(config) {
		if (config == null) {
			config = new Object();
		}
		config.contentCell = new PhoneNumberTableCell(config);
		super(config);
	}
	
	setPhoneNumber(phoneNumber){
		this.getContentCell().setPhoneNumber(phoneNumber);
		return this;
	}
}
export default LabeledPhoneNumberTableCell;