import LabeledTableCell from "./LabeledTableCell.js";
class LabeledTableDateInputCell extends LabeledTableCell{
	constructor(config) {
		super(config);
		this.initLabeledTableDateInputCell(config);
	}
	
	initLabeledTableDateInputCell(config) {
		this.inputElement = $('<input type="date" class="date_default" />');
		super.getContentCell().append(this.inputElement);
	}
	
	getVal(){
		return this.inputElement.val();
	}
	
	setVal(val){
		this.inputElement.val(val);
	}
}
export default LabeledTableDateInputCell;