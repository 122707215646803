import Objects from "./Objects.js";

class StandortAddressBookEntry {

	
	static remove(standortId, abeId, callback) {
		Objects.remove(callback, StandortAddressBookEntry.ENDPOINT + "disconnectFromStandort/" + standortId + "/" + abeId);
	}
}
StandortAddressBookEntry.ADDRESS_BOOK_ENTRY_FIELD_DESCRIPTION_ID = 85;
StandortAddressBookEntry.ENDPOINT = "/api/AddressBookEntry/";

export default StandortAddressBookEntry;