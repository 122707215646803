import Objects from "./Objects.js";
import Role from "./Role.js";
import CachedLoadChain from "../misc/CachedLoadChain.js";
import Language from "./Language.js";

class User{
	
	constructor(obj) {
		obj && Object.assign(this, obj);
	}
	
	static load(id, callback){
		User.cachedLoadChain.load(id, function(data){
			callback(new User(data));
		});
	}
	
	static getCurrentUser(callback){
		if (User.currentUser != null) {
			callback(User.currentUser);
			return;
		}
		User.cachedLoadChain.load("currentUser", function(data){
			let user = new User(data)
			User.currentUser = user;
			callback(user);
		});
	}
	
	static loadAll(licenseId, callback){
		Objects.load(function(data){
			let arrayLength = data.length;
			let users = [];
			for (let i = 0; i < arrayLength; i++) {
				users.push(new User(data[i]));
			}
			callback(users);
		}, User.API_ENDPOINT + "all?licenseId=" + licenseId);
	}
	
	remove(callback) {
		Objects.remove(callback, User.API_ENDPOINT + this.id);
	}
	
	upsert(callback) {
		let scope = this;
		Objects.post(this, function(user){
			user && Object.assign(scope, user);
			callback(scope);
		}, User.API_ENDPOINT + "upsert");
	}
	
	hasRoleById(roleId, callBack) {
		if (this.roles == null) {
			let scope = this;
			Role.loadAllbyUser(this, function(roles){
				scope.roles = roles;
				let arrayLength = scope.roles.length;
				for (let i = 0; i < arrayLength; i++) {
					if (scope.roles[i].id == roleId) {
						callBack(true);
						return;
					}
				}
				callBack(false);
			});
			return;
		}
		let arrayLength = this.roles.length;
		for (let i = 0; i < arrayLength; i++) {
			if (this.roles[i].id == roleId) {
				callBack(true);
				return;
			}
		}
		callBack(false);
	}
	
	getLanguage(callBack) {
		if (this.language != null) {
			callBack(this.language);
			return;
		}
		let scope = this;
		Language.load(this.sprache_id, function(language){
			scope.language = language;
			callBack(language);
		});
	}
	
	getDisplayName() {
		let text = this.firstName + " " + this.lastName;
		if (! this.isActive()) {
			text += " (inactive)";
		}
		return text;
	}
	
	getDisplayUsername() {
		let text = this.username;
		if (! this.isActive()) {
			text += " (inactive)";
		}
		return text;
	}
	
	getNumberformat(callback, digits){
		if (digits == null) {
			digits = 2;
		}
		this.getLanguage(function(language){
			let formater = Intl.NumberFormat(language.ISO639_1, {minimumFractionDigits:digits, maximumFractionDigits:digits});
			callback(formater);
		});
	}
	
	getFoatParser(callback){
		if(User.foatParser){
			callback(User.foatParser);
			return;
		}
		this.getLanguage(function(language){
			try {
				loadGlobalize(language.ISO639_1);
				Globalize.locale(language.ISO639_1);
				User.foatParser = Globalize.numberParser();
				callback(User.foatParser);
			} catch (exception) {
				console.log(exception);
				setTimeout(function(){
					//do a retry without reload
					Globalize.locale(language.ISO639_1);
					User.foatParser = Globalize.numberParser();
					callback(User.foatParser);
				}, 1000);
			}
		});
	}
	
	isActive() {
		return this.deactivated == null;
	}
	
	activate(callback) {
		let scope = this;
		Objects.post(this, function(data){
			scope.deactivated = data.deactivated;
			if (callback) {
				callback(scope);
			}
		}, User.API_ENDPOINT + 'activation?activate=true', null);
	}
	
	deactivate(callback) {
		let scope = this;
		Objects.post(this, function(data){
			scope.deactivated = data.deactivated;
			if (callback) {
				callback(scope);
			}
		}, User.API_ENDPOINT + 'activation?activate=false', null);
		
	}
	
	resetPassword(callback) {
		let scope = this;
		Objects.post(this, function(data){
			if (callback) {
				callback(data);
			}
		}, User.API_ENDPOINT + 'resetPassord', null);
	}
}
User.currentUser;
User.API_ENDPOINT = "/api/User/";
User.cachedLoadChain = new CachedLoadChain(User.API_ENDPOINT);

User.DESCRIPTON_ID = 24;

User.USER_NAME_FIELD_DESCRIPTION_ID = 52;
User.LAST_LOGIN_FIELD_DESCRIPTION_ID = 53;
User.EMAIL_FIELD_DESCRIPTION_ID = 54;
User.LANGUAGE_FIELD_DESCRIPTION_ID = 55;
User.FIRST_NAME_FIELD_DESCRIPTION_ID = 56;
User.LAST_NAME_FIELD_DESCRIPTION_ID = 57;
User.NEWSLETTER_FDI = 93;
export default User;