import Objects from "./Objects.js";
import MediumUnit from "./MediumUnit.js";
import MeterPoint from "./MeterPoint.js";

class Consumption{
	constructor(obj) {
		obj && Object.assign(this, obj);
	}
	
	static upsert(consumption, callback){
		Objects.save(consumption, function(savedConsumption){
			callback(new Consumption(savedConsumption));
		}, Consumption.API_ENDPOINT + 'upsert');
	}
	
	static loadAll(meterPointId, callback){
		Objects.load(function(data){
			Consumption.handleLoadedList(data, callback);
		}, Consumption.API_ENDPOINT + "all/" + meterPointId);
	}
	static handleLoadedList(data, callback){
		let arrayLength = data.length;
		let objects = [];
		for (let i = 0; i < arrayLength; i++) {
			let object = new Consumption(data[i]);
			objects.push(object);
			//add to cache MeterPoint.cachedLoadChain.cache[meterpoint.id] = meterpoint;
		}
		callback(objects);
	}

	remove(callback){
		Objects.remove(callback, Consumption.API_ENDPOINT + this.id);
	}
	
	getUnit(callback){
		MediumUnit.load(this.einheitMediumId, callback);
	}
	
	getMeterpoint(callback) {
		if (this.messpunkt) {
			if (!(this.messpunkt instanceof MeterPoint)) {
				this.messpunkt = new MeterPoint(this.messpunkt);
			}
			callback(this.messpunkt);
			return;
		}
		MeterPoint.load(this.messpunktId,function(meterPoint){
			this.messpunkt = meterPoint;
			callback(meterPoint);
		});
	}
	
	isAdjusted(callback){
		if (this.isAdjustedCache != null) {
			callback(this.isAdjustedCache);
			return;
		}
		let scope = this;
		this.getMeterpoint(function(meterPoint){
			meterPoint.isAdjusted(function(isAdjusted){
				scope.isAdjustedCache = isAdjusted;
				callback(scope.isAdjustedCache);
			});
		});
	}
}
Consumption.API_ENDPOINT = '/api/Verbrauch/';
Consumption.OBJECT_DESCRIPTION_ID = 20;
Consumption.AMOUNT_FDI = 36;
Consumption.FROM_FDI = 37;
Consumption.TILL_FDI = 38;
Consumption.UNIT_FDI = 39;
Consumption.COMMENT_FDI = 86;
export default Consumption;