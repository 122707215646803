import Objects from "./Objects.js";
import CachedLoadChain from "../misc/CachedLoadChain.js";
import Medium from "./Medium.js";
import MediumUnit from "./MediumUnit.js";
import Link from "../ui/content/Link.js";

class Tariff{
	constructor(obj) {
		obj && Object.assign(this, obj);
	}
	
	static load(tariffId, callback){
		Tariff.cachedLoadChain.load(tariffId, function(data){
			callback(new Tariff(data));
		});
	}
	
	static loadAllByMedium(mediumId, callback) {
		Objects.load(function(data){
			let arrayLength = data.length;
			let tariffs = [];
			for (let i = 0; i < arrayLength; i++) {
				let tariff = new Tariff(data[i]);
				tariffs.push(tariff);
				Tariff.cachedLoadChain.cache[tariff.id] = tariff;
			}
			callback(tariffs);
		}, Tariff.API_ENDPOINT + "all?mediumId=" + mediumId);
	}
	
	static loadAll(params, callback) {
		let query = null;
		for (let key in params) {
			if (query == null) {
				query = '?' + key + '=' + params[key];
			} else {
				query += '&' + key + '=' + params[key];
			}
		}
		Objects.load(function(data){
			let arrayLength = data.length;
			let tariffs = [];
			for (let i = 0; i < arrayLength; i++) {
				let tariff = new Tariff(data[i]);
				tariffs.push(tariff);
				Tariff.cachedLoadChain.cache[tariff.id] = tariff;
			}
			callback(tariffs);
		}, Tariff.API_ENDPOINT + "all" + query);
	}
	
	getURL() {
		return Objects.rootURL + '/Frontend/Tariff/page.jsp?tariffID='+this.id
	}
	
	getLink(){
		let link = new Link({
			href: this.getURL(),
			text: this.name
		});
		return link;
	}
	
	getMedium(callback) {
		if (this.medium != null) {
			callback(this.medium);
			return;
		}
		if (this.mediumID != null) {
			let scope = this;
			Medium.load(this.mediumID, function(medium) {
				scope.medium = medium;
				callback(medium);
			});
		}
	}
	
	getUnit(callback) {
		if (this.unit != null) {
			callback(this.unit);
			return;
		}
		if (this.unitID != null) {
			let scope = this;
			MediumUnit.load(this.unitID, function(unit) {
				scope.unit = unit;
				callback(unit);
			});
		}
	}
}
Tariff.API_ENDPOINT = '/api/Tariff/';
Tariff.cachedLoadChain = new CachedLoadChain(Tariff.API_ENDPOINT);
Tariff.OBJECT_DESCRIPTION_ID = 34;
Tariff.MEDIUM_FDI = 90;
Tariff.UNIT_FDI = 91;
Tariff.NAME_FDI = 92;

export default Tariff;