import UI from "../../../defaultUi.js";
import Translation from "../../../../objects/Translation.js";

class YearlyPlotSpecific extends UI{
	constructor(config){
		super(config);
		this.isReady = false;
		this.plotId = config.typ + 'newplot';
		let plotDiv = $('<div id="' + this.plotId + '" class="jqplot-target" style="height: 400px; width: 1100px; position: relative;"></div>');
		super.setBaseJQueryObject(plotDiv);
		let scope = this;
		if (config.typ == YearlyPlotSpecific.GRAPH_TYP_ELECTRICITY) {
			Translation.load('Strom',function(translElec){
				Translation.load('Verbrauch',function(translUsage){
					Translation.load('Bemessungsgroesse',function(translAna){
						let plotTitel = translElec.text + ' (kWh/m&sup2;)';
						let labelCons = translUsage.text + ' (kWh/m&sup2;)';
						let lableAna = translAna.text + ' (kWh/m&sup2;)';
						let colors = [ "#ffff00", "#000000"];
						scope.jqpDefaultSetting = scope.getSetting(plotTitel, labelCons, lableAna, colors);
						var graphData = config.standort.getYearlyElectricityConsumptionSpecific(function(graphData){
							scope.graphData = graphData;
							if (scope.isReady) {
								scope.render();
							}
						});
					});
				});
			});
		} else if (config.typ == YearlyPlotSpecific.GRAPH_TYP_HEAT){
			Translation.load('Waerme',function(translElec){
				Translation.load('Verbrauch bereinigt',function(translUsage){
					Translation.load('Vorgabe bereinigt',function(translAna){
						let plotTitel = translElec.text + ' (kWh/m&sup2;)';
						let labelCons = translUsage.text + ' (kWh/m&sup2;)';
						let lableAna = translAna.text + ' (kWh/m&sup2;)';
						let colors = [ "#ff0000", "#000000"];
						scope.jqpDefaultSetting = scope.getSetting(plotTitel, labelCons, lableAna, colors);
						var graphData = config.standort.getYearlyHeatConsumptionSpecific(function(graphData){
							scope.graphData = graphData;
							if (scope.isReady) {
								scope.render();
							}
						});
					});
				});
			});
		} else if (config.typ == YearlyPlotSpecific.GRAPH_TYP_WATER){
			Translation.load('Wasser',function(translElec){
				Translation.load('Verbrauch',function(translUsage){
					Translation.load('Bemessungsgroesse',function(translAna){
						Translation.load('Person',function(translPerson){
							let unit = ' (l/' + translPerson.text + ')';
							let plotTitel = translElec.text + unit;
							let labelCons = translUsage.text + unit;
							let lableAna = translAna.text + unit;
							let colors = [ "#0000ff", "#000000"];
							scope.jqpDefaultSetting = scope.getSetting(plotTitel, labelCons, lableAna, colors);
							var graphData = config.standort.getYearlyWaterConsumptionSpecific(function(graphData){
								scope.graphData = graphData;
								if (scope.isReady) {
									scope.render();
								}
							});
						});
					});
				});
			});
		}
		this.isReady = true;
	}
	
	render() {
		if (this.isReady && this.graphData != null && $('#' + this.plotId).length > 0) {
			var plot = $.jqplot(this.plotId, this.graphData, this.jqpDefaultSetting);
		}
	}
	
	getSeriesLegend() {
		if(this.seriesLegend != null){
			return this.seriesLegend;
		}
		this.seriesLegend = [];
		let endYear = new Date().getFullYear();
		let startYear = endYear - 3;
		for (let year = startYear; year <= endYear; year++) {
			this.seriesLegend.push({label:year});
		}
		return this.seriesLegend;
	}
	
	getSetting(title, labelCons, lableAna, colors){
		let seriesLegend = [
	            {
	            	label:labelCons,
	            	renderer:$.jqplot.BarRenderer,
	            	rendererOptions: {
	            		fillToZero: true,
	            		highlightMouseDown: true
	            		}
	            },
    			{
	            	label:lableAna,
	            	rendererOptions: {
	            		fillToZero: true
	            		},
	            	yaxis:'yaxis'
	            }
	        ];
		let jqpDefaultSetting = new App.JqpDefaultSetting(title,seriesLegend);
		jqpDefaultSetting.seriesColors = colors;
		jqpDefaultSetting.seriesDefaults={};
		return jqpDefaultSetting;
	}
}
YearlyPlotSpecific.GRAPH_TYP_ELECTRICITY = 'electricity';
YearlyPlotSpecific.GRAPH_TYP_HEAT = 'heat';
YearlyPlotSpecific.GRAPH_TYP_WATER = 'water';
export default YearlyPlotSpecific;