import LabeledTableCell from "./LabeledTableCell.js";
class LabeledTableInputCell extends LabeledTableCell{
	constructor(config) {
		super(config);
		this.initLabeledTableInputCell(config);
	}
	
	initLabeledTableInputCell(config) {
		this.inputElement = $('<input/>');
		super.getContentCell().append(this.inputElement);
	}
	
	getVal(){
		return this.inputElement.val();
	}
	
	setVal(val){
		this.inputElement.val(val);
	}
	
}
export default LabeledTableInputCell;