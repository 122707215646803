import UI from "../../defaultUi.js";
import EVAEvent from "../../EVAEvent.js";
import OverlayBox from "../../OverlayBox.js";
import Button from "../../Button.js";
import UploadingOverlay from "../../content/UploadingOverlay.js";
import Observable from "../../../misc/Observable.js";
import File from "../../../objects/File.js";
import Upload from "../../../misc/Upload.js";
import InfoOverlay from "../../content/InfoOverlay.js";
import InputTable from "../../table/InputTable.js";

class UploadReadingsCSV extends Observable{
	constructor(config) {
		super(config);
		if (config) {
			this.meter = config.meter;
		}
		this.overlayBox = new OverlayBox({
			isCloseable: true
		});

		let footer = this.overlayBox.getFooter();
		let saveButton = Button.createSaveButton()
		footer.append(saveButton);
		let scope = this;
		saveButton.click(function(event){
			scope.save();
		});

		this.table = new InputTable(1);
		this.overlayBox.append(this.table);
		this.fileInput = this.table.addFileInput(File.FILE_FIELD_DESCRIPTION_ID, File.FILE_INPUT_ID);
	}
	
	save() {
		let scope = this;
		Upload.uploadByInput("/api/Ablesung/upload_readings_for_" + this.meter.id, this.fileInput, function(data){
			scope.overlayBox.remove();
			let infoOverlay = new InfoOverlay({
				data:data
				});
			scope.notify(data);
		});
	}
	
}
export default UploadReadingsCSV;