import Objects from "./Objects.js";
import User from "./User.js";
import LabeledTable from "../ui/table/LabeledTable.js";
import LabeledTableCell from "../ui/table/LabeledTableCell.js";
class Comment{
	static save(entry, callback){
		if (entry.id != null) {
			Objects.save(entry, callback, "/api/Comment/update");
		} else {
			Objects.save(entry, callback, "/api/Comment/insert");
		}
	}
	
	static load(id, callback){
		Objects.load(callback, "/api/Comment/"+id);
	}
	
	static loadAll(objectTypeId, objectId, callback){
		Objects.load(callback, "/api/Comment/" + objectTypeId + "/" + objectId);
	}
	
	static createCommentsDiv(objectTypeId, objectId, divTofill) {
		divTofill.html("");
		var groupBox = $('<div class="content_box_group"></div>');
		divTofill.append(groupBox);
		
		let commentUi = new CommentUi({
			objectTypeId: objectTypeId,
			objectId: objectId,
		});
		groupBox.append(commentUi.getJQueryRepresentation());
		var contentBox = $('<div id="commentsList"></div>');
		groupBox.append(contentBox);
	}
	
	static showCommentsList(event){
		var divTofill = $('#' + event.data.divId);
		divTofill.html('');
		Comment.loadAll(event.data.objectTypeId,event.data.objectId, function(commentList){
					var arrayLength = commentList.length;
					for (var i = 0; i < arrayLength; i++) {
						var comment = commentList[i];
						Comment.showComment(comment, divTofill);
					}
				}
			);
	}
	
	static showComment(comment, appendTo){
		var contentBox = $('<div class="content_list_box"></div>');
		appendTo.append(contentBox);
		var labeledTable = new LabeledTable(2);
		contentBox.append(labeledTable.getJQueryRepresentation());
		labeledTable.append(
				new LabeledTableCell({
					fieldId: Comment.CREATED_FIELD_ID,
					contentText: (new Date(comment.date).toLocaleString())
				}));

		var createdByCell = new LabeledTableCell({
			fieldId: Comment.USER_FIELD_ID
		})
		labeledTable.append(createdByCell);

		User.load(comment.userId, function(user){
			createdByCell.getContentCell().text(user.firstName + " " + user.lastName);
		});
		
		var textDiv = $('<div style="white-space: pre-wrap;padding: 10px;"></div>');
		contentBox.append(textDiv);
		textDiv.text(comment.text);
	}
	
	static newCommentOverlay(event){

		var mainContent = $('.main_content');
		if(mainContent.length){
			var overlayBox = $('<div id="new_comment_overlay" class="boxOverlay"></div>');
			mainContent.prepend(overlayBox);
			var boxOverlayContent = $('<div class="boxOverlay-content content_box_group"></div>');
			overlayBox.append(boxOverlayContent);
			var contentBox = $('<div class="content_box"></div>');
			boxOverlayContent.append(contentBox);
			var headline = $('<form class="content_headline"></form>');
			contentBox.append(headline);
			ObjectTranslation.getObjectNameTranslation(Comment.OBJECT_DESCRIPTION_ID, function(objectNameTranslation){
						headline.text(objectNameTranslation.name);
					}
				);
			var contentBox = $('<div class="content_box"></div>');
			boxOverlayContent.append(contentBox);
			var editForm = $('<form id="comment_edit_form" action="" accept-charset="utf-8"></form>');
			contentBox.append(editForm);
			var textArea = $('<textarea id="createCommentArea"></textarea>');
			editForm.append(textArea);

			var bottonsDiv = $('<div class="buttons"></div>');
			contentBox.append(bottonsDiv);
			Objects.addSaveButton(bottonsDiv, function(event){
				var entry = new Object();
				entry.text = event.data.textArea.val();
				entry.objectDescriptionId = event.data.objectTypeId;
				entry.objectId = event.data.objectId;
				Comment.save(entry, function(){
					var event = new Object();
					event.data = new Object();
					event.data.divId = "commentsList";
					event.data.objectTypeId = entry.objectDescriptionId;
					event.data.objectId = entry.objectId;
					Comment.showCommentsList(event);
				});
				event.data.div.remove();
			},{objectTypeId: event.data.objectTypeId, objectId: event.data.objectId, div: overlayBox, textArea: textArea});
			
			Objects.addCancelButton(bottonsDiv, function(event){
				event.data.div.remove();
			}, {div: overlayBox});
		}else{
			console.log('main_contet not found');
			return;
		}
	}
	
	openEditUi() {
		let config = new Object();
		config.commentToEdit = this.objectDescriptionId;
		config.objectTypeId = this.objectDescriptionId;
		config.objectId = this.objectId;
		let commentEdit = new CommentEdit(config);
	}
	
}

Comment.API_ENDPOINT = "/api/Comment/";
Comment.OBJECT_DESCRIPTION_ID = 26;
Comment.USER_FIELD_ID = 61;
Comment.CREATED_FIELD_ID = 62;
export default Comment;

import UI from "../ui/defaultUi.js";
import ListDivHeader from "../ui/content/ListDivHeader.js";
import ObjectTranslation from "./ObjectTranslation.js";
class CommentUi extends UI{

	constructor(config) {
		super();
		this.objectTypeId = config.objectTypeId;
		this.objectId = config.objectId;
		
		this.listDivHeader = new ListDivHeader;
		super.setBaseJQueryObject(this.listDivHeader.getJQueryRepresentation());
		var scope = this;
		ObjectTranslation.getObjectNameTranslation(Comment.OBJECT_DESCRIPTION_ID, function(objectNameTranslation){
				scope.listDivHeader.text(objectNameTranslation.namePlural);
			});
		this.listDivHeader.getShowButton().click(function(event) {
			Comment.showCommentsList(event);
			}, {
				objectTypeId: this.objectTypeId,
				objectId: this.objectId,
				divId: "commentsList"
			});
		this.listDivHeader.getNewButton().click(function(event) {
			Comment.newCommentOverlay(event);
		}, {
			objectTypeId: this.objectTypeId,
			objectId: this.objectId,
			divId: "commentsList"
		});
		this.listDivHeader.getHideButton().click(function(event) {
			$('#commentsList').html('');
		}, {});
	}
}

class CommentEdit extends UI {
	
	constructor(config) {
		super();
		this.objectTypeId = config.objectTypeId;
		this.objectId = config.objectId;
		this.commentToEdit = config.commentToEdit;
		this.createCommentEditUi();
	}
	
	createCommentEditUi() {
		this.overlayBox = new OverlayBox({isCloseable: true});
		this.contentBox = new ContentBox();
		overlayBox.append(contetBox);
		var headline = $('<form class="content_headline"></form>');
		contentBox.append(headline);
		ObjectTranslation.getObjectNameTranslation(Comment.OBJECT_DESCRIPTION_ID, function(objectNameTranslation){
					headline.text(objectNameTranslation.name);
				}
			);
		var contentBox = $('<div class="content_box"></div>');
		boxOverlayContent.append(contentBox);
		var editForm = $('<form id="comment_edit_form" action="" accept-charset="utf-8"></form>');
		contentBox.append(editForm);
		var textArea = $('<textarea id="createCommentArea"></textarea>');
		editForm.append(textArea);
		if (this.commentToEdit != null) {
			textArea.val(this.commentToEdit.text);
		}

		var bottonsDiv = $('<div class="buttons"></div>');
		contentBox.append(bottonsDiv);
		var scope = this;
		Objects.addSaveButton(bottonsDiv, function(event){
			var entry = new Object();
			entry.text = event.data.textArea.val();
			entry.objectDescriptionId = event.data.objectTypeId;
			entry.objectId = event.data.objectId;
			if (scope.commentToEdit != null) {
				entry.priviusVersion = scope.commentToEdit.Id;
			}
			Comment.save(entry, function(){
				var event = new Object();
				event.data = new Object();
				event.data.divId = "commentsList";
				event.data.objectTypeId = entry.objectDescriptionId;
				event.data.objectId = entry.objectId;
				Comment.showCommentsList(event);
			});
			event.data.div.remove();
		},{objectTypeId: event.data.objectTypeId, objectId: event.data.objectId, div: overlayBox, textArea: textArea});
		
		Objects.addCancelButton(bottonsDiv, function(event){
			event.data.div.remove();
		}, {div: overlayBox});
	}
}
