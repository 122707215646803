import Objects from "./Objects.js";

class Persons{
	
	constructor(config) {
		
	}
	
	static loadAll(standortId, callback){
		Objects.load(callback, Persons.API_ENDPOINT + "all?standortId=" + standortId);
	}
	
	static load(id, callback){
		Objects.load(callback, Persons.API_ENDPOINT + id);
	}
	
	static save(entry, callback){
		if (entry.id) {
			return Persons.update(entry, callback);
		}
		Objects.save(entry, callback, Persons.API_ENDPOINT + "insert");
	}
	
	static update(entry, callback){
		Objects.save(entry, callback, Persons.API_ENDPOINT + "update");
	};

	static remove(entryId, callback) {
		Objects.remove(callback, Persons.API_ENDPOINT + entryId);
	}
}
Persons.API_ENDPOINT = "/api/Personen/";
Persons.OBJECT_DESCRIPTION_ID = 22;
Persons.FIELD_DATE_ID = 43;
Persons.FIELD_COUNT_ID = 44;
export default Persons;