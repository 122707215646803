import Ui from "../../defaultUi.js";
import CalculateDegreeDaysButton from "./CalculateDegreeDaysButton.js";
import OverlayBox from "../../OverlayBox.js";
import WeatherStationDetailTable from "./WeatherStationDetailTable.js";

class WeatherStationOverlay extends Ui{
	constructor(config) {
		super(config);
		this.overlayBox = new OverlayBox({
			isCloseable: true
		});
		
		let weatherStationDetailTable = new WeatherStationDetailTable(config);
		this.overlayBox.append(weatherStationDetailTable);
		let calculateDegreeDaysButton = new CalculateDegreeDaysButton(config);
		this.overlayBox.getFooter().append(calculateDegreeDaysButton);
	}
}
export default WeatherStationOverlay;