import UI from "./defaultUi.js";
class ContentBox extends UI{
	constructor(){
		super();
		this.contentBoxDiv = $('<div class="content_box"></div>');
		super.setBaseJQueryObject(this.contentBoxDiv);
		
	}
	
	append(toAppend) {
		if (toAppend instanceof UI) {
			this.contentBoxDiv.append(toAppend.getJQueryRepresentation());
		} else{
			this.contentBoxDiv.append(toAppend);
		}
	}
	
	clear() {
		this.contentBoxDiv.html('');
	}

	
	text(text) {
		if (text === undefined) {
			return this.contentBoxDiv.text();
		}
		return this.contentBoxDiv.text(text);
	}
}
export default ContentBox;