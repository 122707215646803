import Observable from "../../../misc/Observable.js";
import OverlayBox from "../../OverlayBox.js";
import Button from "../../Button.js";
import Consumption from "../../../objects/Consumption.js";
import EVAEvent from "../../EVAEvent.js";
import ConsumptionDetailTable from "./ConsumptionDetailTable.js";
import EditConsumptionOverlay from "./EditConsumptionOverlay.js";
import Translation from "../../../objects/Translation.js";

class ConsumptionDetailOverlay extends Observable {
	constructor(config) {
		super(config);
		if (config) {
			this.consumption = config.consumption;
		}
		let scope = this;
		this.overlayBox = new OverlayBox({
			isCloseable: true
		});
		this.table = new ConsumptionDetailTable({
			consumption: this.consumption
		});
		this.overlayBox.append(this.table);
		
		let editButton = Button.createEditButton();
		this.overlayBox.getFooter().append(editButton);
		editButton.click(function(event){
			scope.consumption.getMeterpoint(function(meterPoint){
				new EditConsumptionOverlay({
					consumption: scope.consumption,
					meterPoint: meterPoint,
					eventCallback: function (event) {
						scope.overlayBox.clear(); 
						scope.table = new ConsumptionDetailTable({
							consumption: scope.consumption
						});
						scope.overlayBox.append(scope.table);
						scope.notify(event);
					}
				});
			});
		});

		Translation.load("Verbrauch wirklich loeschen?", function(translation){
			scope.confirmDeleteText = translation.text;
		});
		let deleteButton = Button.createDeleteButton();
		this.overlayBox.getFooter().append(deleteButton);
		deleteButton.click(function(event){
			let r = confirm(scope.confirmDeleteText);
			if (r == true) {
				scope.consumption.remove(function(data){
					scope.overlayBox.remove();
					let event = new EVAEvent({
						type: EVAEvent.TYPE_REMOVE,
						data: scope.consumption
					});
					scope.notify(event);
				});
			}
		});
	}
}
export default ConsumptionDetailOverlay;