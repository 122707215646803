import UI from "../defaultUi.js";
import OverlayBox from "../OverlayBox.js";
import Translation from "../../objects/Translation.js";
import Button from "../Button.js";
import Div from "../Div.js";

class ConfirmOverlay extends UI{
	constructor(config) {
		super(config);
		this.cancelCallback = config.cancelCallback;
		this.okCallback = config.okCallback;
		this.overlayBox = new OverlayBox({
			isCloseable: false
		});
		super.setBaseJQueryObject(this.overlayBox);
		let text = $('<div class="centered"></div>');
		Translation.load(config.text, function(translation){
			text.text(translation.text);
		});
		this.overlayBox.append(text);
		let footer = this.overlayBox.getFooter();
		let centerDiv = new Div();
		centerDiv.getJQueryRepresentation().addClass('centered');
		footer.append(centerDiv);
		let okButton = new Button({
			buttonText: "ok"
		});
		centerDiv.append(okButton);
		okButton.textTranslate("ok");
		okButton.iconClass("glyphicon-ok");
		okButton.click(function(event){
			scope.remove();
			if (scope.okCallback) {
				scope.okCallback();
			}
		});
		let cancelButton = new Button({
			buttonText: "cancel"
		});
		centerDiv.append(cancelButton);
		cancelButton.textTranslate("cancel");
		cancelButton.iconClass("glyphicon-remove");
		let scope = this;
		cancelButton.click(function(event){
			scope.remove();
			if (scope.cancelCallback) {
				scope.cancelCallback();
			}
		});
	}
}
export default ConfirmOverlay;