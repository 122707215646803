import UI from "../../defaultUi.js";
import LabeledTableCell from "../../table/LabeledTableCell.js";
import LabeledTable from "../../table/LabeledTable.js";
import User from "../../../objects/User.js";
import Comment from "../../../objects/Comment.js";
import ContentBox from "../../ContentBox.js";

class CommentDetailBox extends UI{
	constructor(config) {
		super(config);
		this.contentBox = new ContentBox();
		super.setBaseJQueryObject(this.contentBox);
		let entry = config.comment;
		let labeledTable = new LabeledTable(2);
		this.append(labeledTable);
		labeledTable.append(
				new LabeledTableCell({
					fieldId: Comment.CREATED_FIELD_ID,
					contentText: (new Date(entry.date).toLocaleString())
				}));

		let createdByCell = new LabeledTableCell({
			fieldId: Comment.USER_FIELD_ID
		})
		labeledTable.append(createdByCell);

		User.load(entry.userId, function(user){
			createdByCell.getContentCell().text(user.firstName + " " + user.lastName);
		});
		
		let textDiv = $('<div style="white-space: pre-wrap;padding: 10px;"></div>');
		this.append(textDiv);
		textDiv.text(entry.text);
		
	}
	
	append(toAppend) {
		if (toAppend instanceof UI) {
			this.contentBox.append(toAppend.getJQueryRepresentation());
		} else{
			this.contentBox.append(toAppend);
		}
	}
}
export default CommentDetailBox