import Objects from "./Objects.js";
import CachedLoadChain from "../misc/CachedLoadChain.js";
import MediumUnit from "./MediumUnit.js";
import Tariff from "./Tariff.js";

class Medium{
	constructor(obj) {
		obj && Object.assign(this, obj);
	}
	
	static load(id, callback){
		Medium.cachedLoadChain.load(id, function(data){
			callback(new Medium(data));
		});
	}
	
	static loadAll(callback){
		let scope = this;
		Objects.load(function(data){
			let arrayLength = data.length;
			let mediums = [];
			for (let i = 0; i < arrayLength; i++) {
				let medium = new Medium(data[i]);
				mediums.push(medium);
				Medium.cachedLoadChain.cache[medium.id] = medium;
			}
			callback(mediums);
		}, Medium.API_ENDPOINT + "all");
	}
	
	getMainUnit() {
		return this.standardEinheit;
	}
	
	getAllUnits(callback) {
		if (this.allUnits) {
			callback(this.allUnits);
			return;
		}
		let scope = this;
		MediumUnit.loadAll(this.id, function(loadedUnits){
			scope.allUnits = loadedUnits;
			callback(loadedUnits);
		});
	}
	
	getAllTariffs(callback){
		if (this.allTariffs){
			callback(this.allTariffs);
			return;
		}
		let scope = this;
		Tariff.loadAllByMedium(this.id, function(loadedTariffs){
			scope.allTariffs = loadedTariffs;
			callback(loadedTariffs);
		});
	}
}
Medium.API_ENDPOINT = "/api/Medium/";
Medium.cachedLoadChain = new CachedLoadChain(Medium.API_ENDPOINT);
export default Medium;