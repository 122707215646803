import UI from "../defaultUi.js";
import Div from "../Div.js";
import SortableList from "../../misc/SortableList.js";
import ListTable from "./ListTable.js";
import DivContentList from "./DivContentList.js";

class SortableListDiv extends UI{
	constructor(config) {
		super(config);
		this.div = new Div(config);
		super.setBaseJQueryObject(this.div);
		this.rowDatas = new SortableList();

		if (config != null) {
			this.isDivTable = config.isDivTable;
		}
	}
	
	pushRow(data, rerender) {
		this.rowDatas.push(data);
		if(rerender){
			this.rerender();
		}
	}
	
	pushAll(data) {
		this.rowDatas.pushAll(data);
		this.rerender();
	}
	
	clear() {
		this.div.clear();
		this.rowDatas = new SortableList();
	}
	
	render() {
		if (this.isDivTable) {
			this.table = new DivContentList();
		} else {
			this.table = new ListTable();
		}
		this.div.append(this.table);
		this.renderHead();
		this.renderRows();
	}
	
	rerender() {
		if (this.table) {
			this.table.clearBody();
			this.renderRows();
		}
	}
	
	sortBy(fieldPath) {
		this.rowDatas.sortBy(fieldPath);
		this.rerender();
	}
	
	renderRows() {
		var scope = this;
		this.rowDatas.forEach(function(entry){
			if (!scope.isDivTable) {
				scope.table.newRow();
			}
			scope.renderRow(entry);
		});
	}
	
	renderRow(entry) {
		throw new Error("please override renderRow(entry)");
	}
	
	renderHead() {
		throw new Error("please override renderHead()");
	}
}
export default SortableListDiv;