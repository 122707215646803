import OverlayBox from "../../OverlayBox.js";
import Button from "../../Button.js";
import Objects from "../../../objects/Objects.js";
import InputTable from "../../table/InputTable.js";
import SelectListInput from "../../inputs/SelectListInput.js";

class BuildingConsumptionList {
	constructor(config) {
		this.overlayBox = new OverlayBox({
			isCloseable: true
		});
		let year = new Date().getFullYear();
		let table = new InputTable(1);
		this.overlayBox.append(table);
		let selectYearCell = table.createTranslationLabeledCell("Jahr", "selectYear");
		this.selectYearInputElement = new SelectListInput({id: "selectYear"});
		selectYearCell.append(this.selectYearInputElement.getJQueryRepresentation());
		this.selectYearInputElement.addOption(year, year);
		this.selectYearInputElement.addOption(year-1, year-1);
		this.selectYearInputElement.addOption(year-2, year-2);
		this.selectYearInputElement.addOption(year-3, year-3);
		
		let downloadButton = Button.createDownloadButton();
		this.overlayBox.getFooter().append(downloadButton);
		let scope = this;
		downloadButton.click(function(event){
			window.location.assign(Objects.rootURL + '/api/Lizenz/yearConsumptions' + scope.selectYearInputElement.val() + '.csv');
		});
	}
}
export default BuildingConsumptionList;