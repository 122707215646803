import Objects from "./Objects.js";
import CachedLoadChain from "../misc/CachedLoadChain.js";

class SelectListEntryTranslation{
	constructor(obj) {
		obj && Object.assign(this, obj);
	}
	
	static load(sletId, callback){
		SelectListEntryTranslation.cachedLoadChain.load(sletId, function(data){
			callback(new SelectListEntryTranslation(data));
		});
	}
	
}
SelectListEntryTranslation.API_ENDPOINT = '/api/SelectListEntryTranslation/';
SelectListEntryTranslation.cachedLoadChain = new CachedLoadChain(SelectListEntryTranslation.API_ENDPOINT);

export default SelectListEntryTranslation;