import Objects from "./Objects.js";
import CachedLoadChain from "../misc/CachedLoadChain.js";

class MediumUnit{
	constructor(obj) {
		obj && Object.assign(this, obj);
	}
	
	static load(id, callback){
		MediumUnit.cachedLoadChain.load(id, function(data){
			callback(new MediumUnit(data));
		});
	}
	
	static loadAll(mediumId, callback){
		Objects.load(function(data){
			let arrayLength = data.length;
			let mediumUnits = [];
			for (let i = 0; i < arrayLength; i++) {
				let mediumUnit = new MediumUnit(data[i]);
				mediumUnits.push(mediumUnit);
				MediumUnit.cachedLoadChain.cache[mediumUnit.id] = mediumUnit;
			}
			callback(mediumUnits);
		}, MediumUnit.API_ENDPOINT + "all/" + mediumId);
	}
}
MediumUnit.API_ENDPOINT = "/api/EinheitMedium/";
MediumUnit.cachedLoadChain = new CachedLoadChain(MediumUnit.API_ENDPOINT);
export default MediumUnit;