import UI from "../defaultUi.js";
import User from "../../objects/User.js";
import TableCell from "./TableCell.js";

class NumberTableCell extends TableCell{
	constructor(config) {
		if (config == null) {
			config = new Object();
		}
		config.type = TableCell.TYPE_NUMBER;
		super(config);
		this.numberSpan = $('<span></span>');
		this.getContentCell().append(this.numberSpan);
		this.unitSpan = $('<span></span>');
		this.getContentCell().append(" ");
		this.getContentCell().append(this.unitSpan);
		this.digits = 2;
		if (config.digits) {
			this.digits = config.digits;
		}
		this.setNumber(config.number)
		this.setUnit(config.unit);
	}
	
	setUnit(unit){
		if (unit == null) {
			this.unitSpan.html('');
			return;
		}
		this.unitSpan.text(unit);
		return this;
	}
	
	setNumber(number){
		if (number == null || isNaN(number)) {
			this.numberSpan.text("--");
			return;
		}
		let scope = this;
		User.getCurrentUser(function(user){
			user.getNumberformat(function(formater){
				scope.numberSpan.text(formater.format(number));
			}, scope.digits);
		});
		return this;
	}
}
export default NumberTableCell;