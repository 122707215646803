import UI from "../../defaultUi.js";
import MeterDetailBox from './MeterDetailBox.js';
import ReadingList from '../reading/ReadingList.js';
import Meter from "../../../objects/Meter.js";

class ShowMeter extends UI {
	constructor(config) {
		super();
		this.showDiv = $('<div></div>');
		super.setBaseJQueryObject(this.showDiv);
		
		if (config != null) {
			if (config.meter != null) {
				this.setMeter(config.meter);
			}
			if (config.meterId != null) {
				let scope = this;
				Meter.load(config.meterId, function(meter){
					scope.setMeter(meter);
				});
			}
		}
	}
	
	setMeter(meter){
		this.meter = meter;
		this.render();
	}
	
	render(){
		let meterDetailBox = new MeterDetailBox({
			meter: this.meter
		});
		this.showDiv.append(meterDetailBox.getJQueryRepresentation());
		
		let readingList = new ReadingList({
			meter: this.meter,
			show: true
		});
		this.showDiv.append(readingList.getJQueryRepresentation());
	}
}
export default ShowMeter;