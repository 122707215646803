import Objects from "./Objects.js";
import CachedLoadChain from "../misc/CachedLoadChain.js";
import BalanceGroup from "./BalanceGroup.js";
import CorrectionValueCategory from "./CorrectionValueCategory.js";
import Link from "../ui/content/Link.js";
import MeterPoint from "./MeterPoint.js";
import Standort from "./Standort.js";

class CorrectionValue {

	constructor(obj) {
		obj && Object.assign(this, obj);
	}
	
	static loadAll(standortId, callback){
		Objects.load(function(data){
			CorrectionValue.handleListResult(callback, data);
		}, CorrectionValue.API_ENDPOINT + "all?standortId=" + standortId);
	}
	
	static loadAllByMeterPoint(meterPointId, callback) {
		Objects.load(function(data){
			CorrectionValue.handleListResult(callback, data);
		}, CorrectionValue.API_ENDPOINT + "all?meterPointId=" + meterPointId);
	}
	
	static handleListResult(callback, data) {
		let arrayLength = data.length;
		let correctionValues = [];
		for (let i = 0; i < arrayLength; i++) {
			let correctionValue = new CorrectionValue(data[i]);
			correctionValues.push(correctionValue);
			CorrectionValue.cachedLoadChain.cache[correctionValue.id] = correctionValue;
		}
		callback(correctionValues);
	}
	
	upsert(callback) {
		let scope = this;
		Objects.post(this, function(correctionValue){
			correctionValue && Object.assign(scope, correctionValue);
			callback(scope);
		}, CorrectionValue.API_ENDPOINT + "upsert");
	}
	
	getURL() {
		return Objects.rootURL + "/Frontend/Korrekturwert/page.jsp?korrekturwertId="+this.id;
	}
	
	getLink() {
		let dateObj = new Date(this.datum);
		let link = new Link({
			href: this.getURL(),
			text: dateObj.toLocaleDateString()
		});
		return link;
	}
	
	getBalanceGroup(callback) {
		if (this.balanceGroup != null) {
			callback(this.balanceGroup);
			return;
		}
		if (this.bilanzgruppe != null) {
			if (!(this.bilanzgruppe instanceof BalanceGroup)) {
				this.bilanzgruppe = new BalanceGroup(this.bilanzgruppe);
			}
			this.balanceGroup = this.bilanzgruppe;
			callback(this.balanceGroup);
			return;
		}
		if (this.bilanzgruppeId != null && this.bilanzgruppeId > 0) {
			let scope = this;
			BalanceGroup.load(this.bilanzgruppeId, function(balanceGroup){
				scope.balanceGroup = balanceGroup;
				callback(scope.balanceGroup);
			});
			return;
		}
		callback(null);
	}
	
	getCorrectionValueCategory(callback) {
		if (this.correctionValueCategory != null) {
			callback(this.correctionValueCategory);
			return;
		}
		if (this.korrekturwertKategorie != null) {
			if (!(this.korrekturwertKategorie instanceof CorrectionValueCategory)) {
				this.korrekturwertKategorie = new CorrectionValueCategory(this.korrekturwertKategorie);
			}
			this.correctionValueCategory = this.korrekturwertKategorie;
			callback(this.correctionValueCategory);
			return;
		}
		if (this.korrekturwertKategorieId != null && this.korrekturwertKategorieId > 0) {
			let scope = this;
			CorrectionValueCategory.load(this.korrekturwertKategorieId, function(correctionValueCategory){
				scope.correctionValueCategory = correctionValueCategory;
				callback(scope.correctionValueCategory);
			});
			return;
		}
		callback(null);
	}
	
	getMeterPoint(callback) {
		if (this.meterPoint) {
			callback(this.meterPoint);
			return;
		}
		if (this.messpunkt != null) {
			if (!(this.messpunkt instanceof MeterPoint)) {
				this.messpunkt = new MeterPoint(this.messpunkt);
			}
			this.meterPoint = this.messpunkt;
			callback(this.meterPoint);
			return;
		}
		if (this.messpunktId != null && this.messpunktId > 0) {
			let scope = this;
			MeterPoint.load(this.messpunktId, function(meterPoint){
				scope.meterPoint = meterPoint;
				callback(scope.meterPoint);
			});
		}
	}
	
	getStandort(callback) {
		if (this.standort) {
			if (!(this.standort instanceof Standort)) {
				this.standort = new Standort(this.standort);
			}
			callback(this.standort);
			return;
		}
		if (this.standortId != null && this.standortId > 0) {
			let scope = this;
			Standort.load(this.standortId, function(standort){
				scope.standort = standort;
				callback(scope.standort);
			});
		}
	}
	
}
CorrectionValue.API_ENDPOINT = "/api/Korrekturwert/";
CorrectionValue.cachedLoadChain = new CachedLoadChain(CorrectionValue.API_ENDPOINT);
CorrectionValue.OBJECT_DESCRIPTION_ID = 23;
CorrectionValue.FIELD_STANDORT_ID = 45;
CorrectionValue.FIELD_METER_POINT_ID = 46;
CorrectionValue.FIELD_BALANCE_GROUP_ID = 47;
CorrectionValue.FIELD_CATEGORY_ID = 48;
CorrectionValue.FIELD_DATE_ID = 49;
CorrectionValue.FIELD_VALUE_ID = 50;
CorrectionValue.FIELD_DESCRIPTION_ID = 51;

export default CorrectionValue;