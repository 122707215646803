import UI from "../defaultUi.js";
import TableCell from "./TableCell.js";
import Translation from "../../objects/Translation.js";

class BooleanTableCell extends TableCell{
	constructor(config) {
		if (config == null) {
			config = new Object();
		}
		super(config);
		this.setBoolean(config.boolean);
	}
	
	setBoolean(boolean) {
		if (boolean == null) {
			this.getContentCell().text("--");
		}
		let scope = this;
		if (boolean) {
			Translation.load("Ja", function(translation){
				scope.getContentCell().text(translation.text);
			});
		} else {
			Translation.load("Nein", function(translation){
				scope.getContentCell().text(translation.text);
			});
		}
	}
}
export default BooleanTableCell;