import Ui from "../../defaultUi.js";
import LabeledTable from "../../table/LabeledTable.js";
import Translation from "../../../objects/Translation.js";
import LabeledTableCell from "../../table/LabeledTableCell.js";

class WeatherStationDetailTable extends Ui{
	constructor(config) {
		super(config);
		this.labeledTable = new LabeledTable();
		super.setBaseJQueryObject(this.labeledTable);
		
		this.nameCell = new LabeledTableCell();
		var scope = this;
		Translation.load("weatherStation.Name", function(translation){
			scope.nameCell.getLabelCell().text(translation.text);
		});
		this.labeledTable.append(this.nameCell);
		this.ngtz18Cell = new LabeledTableCell();
		Translation.load("weatherStation.Ngtz18", function(translation){
			scope.ngtz18Cell.getLabelCell().text(translation.text);
		});
		this.labeledTable.append(this.ngtz18Cell);
		this.ngtz20Cell = new LabeledTableCell();
		Translation.load("weatherStation.Ngtz20", function(translation){
			scope.ngtz20Cell.getLabelCell().text(translation.text);
		});
		this.labeledTable.append(this.ngtz20Cell);
		this.ngtzFromCell = new LabeledTableCell();
		Translation.load("weatherStation.NgtzFrom", function(translation){
			scope.ngtzFromCell.getLabelCell().text(translation.text);
		});
		this.labeledTable.append(this.ngtzFromCell);
		this.ngtzTillCell = new LabeledTableCell();
		Translation.load("weatherStation.NgtzTill", function(translation){
			scope.ngtzTillCell.getLabelCell().text(translation.text);
		});
		this.labeledTable.append(this.ngtzTillCell);
		if (config != null) {
			if (config.weatherStation != null) {
				this.setWeatherStation(config.weatherStation);
			}
		}
		this.setWeatherStation(config.weatherStation);
	}
	
	setWeatherStation(weatherStation) {

		this.weatherStation = weatherStation;
		
		this.nameCell.getContentCell().text(weatherStation.name);
		this.ngtz18Cell.getContentCell().text(weatherStation.ngtz18);
		this.ngtz20Cell.getContentCell().text(weatherStation.ngtz20);

		if(this.weatherStation.ngtzFrom != null){
			let fromText = (new Date(this.weatherStation.ngtzFrom)).toLocaleDateString();
			this.ngtzFromCell.getContentCell().text(fromText);
		} else {
			this.ngtzFromCell.getContentCell().text("");
		}
		if(this.weatherStation.ngtzTill != null){
			let tillText = (new Date(this.weatherStation.ngtzTill)).toLocaleDateString();
			this.ngtzTillCell.getContentCell().text(tillText);
		} else {
			this.ngtzTillCell.getContentCell().text("");
		}
	}
	
}
export default WeatherStationDetailTable;