import OverlayBox from "../../OverlayBox.js";
import Button from "../../Button.js";
import AddressBookEntry from "../../../objects/AddressBookEntry.js";
import InputTable from "../../table/InputTable.js";
import Observable from "../../../misc/Observable.js";
import EVAEvent from "../../EVAEvent.js";

class EditAddressBookEntryOverlay extends Observable {
	constructor(config) {
		super(config);
		if (config) {
			this.addressBookEntry = config.addressBookEntry;
		}
		let scope = this;
		this.overlayBox = new OverlayBox({
			isCloseable: true
		});
		
		let table = new InputTable(1);
		this.overlayBox.append(table);
		
		this.firstNameInput = table.addInput(AddressBookEntry.FIRST_NAME_FIELD_DESCRIPTION_ID, AddressBookEntry.FIRST_NAME_INPUT_ID);
		this.lastNameInput = table.addInput(AddressBookEntry.LAST_NAME_FIELD_DESCRIPTION_ID, AddressBookEntry.LAST_NAME_INPUT_ID);
		this.formOfAddressInput = table.addInput(AddressBookEntry.FORM_OF_ADDRESS_FIELD_DESCRIPTION_ID, AddressBookEntry.FORM_OF_ADDRESS_INPUT_ID);
		this.jobInput = table.addInput(AddressBookEntry.JOB_FIELD_DESCRIPTION_ID, AddressBookEntry.JOB_FIELD_INPUT_ID);
		this.emailInput = table.addInput(AddressBookEntry.EMAIL_FIELD_DESCRIPTION_ID, AddressBookEntry.EMAIL_INPUT_ID);
		this.phoneNumberInput = table.addInput(AddressBookEntry.PHONE_NUMBER_FIELD_DESCRIPTION_ID, AddressBookEntry.PHONE_NUMBER_INPUT_ID);
		this.mobileNumberInput = table.addInput(AddressBookEntry.MOBILE_NUMBER_FIELD_DESCRIPTION_ID, AddressBookEntry.MOBILE_NUMBER_INPUT_ID);
		this.gdprApprovalInput = table.addDateInput(AddressBookEntry.GDPR_APPROVAL_FIELD_DESCRIPTION_ID, AddressBookEntry.GDPR_APPROVAL_INPUT_ID);
		this.additionalInformationInput = table.addTextArea(AddressBookEntry.ADDITIONAL_INFORMATION_FIELD_DESCRIPTION_ID, AddressBookEntry.ADDITIONAL_INFORMATION_INPUT_ID);
		
		if (this.addressBookEntry) {
			this.firstNameInput.val(this.addressBookEntry.firstName);
			this.lastNameInput.val(this.addressBookEntry.lastName);
			this.formOfAddressInput.val(this.addressBookEntry.formOfAddress);
			this.jobInput.val(this.addressBookEntry.job);
			this.phoneNumberInput.val(this.addressBookEntry.phoneNumber);
			this.mobileNumberInput.val(this.addressBookEntry.mobileNumber);
			this.gdprApprovalInput.val(this.addressBookEntry.gdprApproval);
			this.additionalInformationInput.val(this.addressBookEntry.additionalInformation);
			this.emailInput.val(this.addressBookEntry.email);
		}

		let saveButton = Button.createSaveButton()
		this.overlayBox.getFooter().append(saveButton);
		saveButton.click(function(event){
			if (scope.addressBookEntry == null) {
				scope.addressBookEntry = new Object();
			}
			scope.addressBookEntry.firstName = scope.firstNameInput.val();
			scope.addressBookEntry.lastName = scope.lastNameInput.val();
			scope.addressBookEntry.phoneNumber = scope.phoneNumberInput.val();
			scope.addressBookEntry.mobileNumber = scope.mobileNumberInput.val();
			scope.addressBookEntry.additionalInformation = scope.additionalInformationInput.val();
			scope.addressBookEntry.gdprApproval = scope.gdprApprovalInput.val();
			scope.addressBookEntry.job = scope.jobInput.val();
			scope.addressBookEntry.formOfAddress = scope.formOfAddressInput.val();
			scope.addressBookEntry.email = scope.emailInput.val();
			
			AddressBookEntry.save(scope.addressBookEntry, function(addressBookEntry){
				scope.overlayBox.remove();
				let event = new EVAEvent({
					type: EVAEvent.TYPE_SAVE,
					data: addressBookEntry
				});
				scope.notify(event);
			});
		});
	}
}
export default EditAddressBookEntryOverlay;