import DetailTable from "../../table/DetailTable.js";
import MeterPoint from "../../../objects/MeterPoint.js";
import Objects from "../../../objects/Objects.js";

class MeterPointDetailTable extends DetailTable{
	constructor(config){
		super(config);
		if (config != null) {
			if (config.meterPoint != null) {
				this.setMeterPoint(config.meterPoint);
			}
		}
	}
	
	setMeterPoint(meterPoint) {
		if (!(meterPoint instanceof MeterPoint)) {
			meterPoint = new MeterPoint(meterPoint);
		}
		let standortCell = this.addFieldCellBig(MeterPoint.STANDORT_FIELD_DESCRIPTION_ID);
		meterPoint.getStandort(function(standort){
			if (standort == null) {
				standortCell.text("--");
				return;
			}
			standortCell.getContentCell().append(standort.getLink());
//			let standortString = standort.name + ", " + standort.strasse + ", " + standort.plz + " " + standort.ort;
//			let link = $('<a></a>');
//			standortCell.getContentCell().append(link);
//			link.text(standortString);
//			link.click(function(event) {
//				window.location.href = Objects.rootURL + "/Frontend/Standort/page.jsp?standortID="+standort.id;
//			});
		});
		this.addText(MeterPoint.NAME_FIELD_DESCRIPTION_ID, meterPoint.name);
		this.addDate(MeterPoint.START_DATE_FIELD_DESCRIPTION_ID, meterPoint.beginn);
		let adjustingTypeCell = this.addFieldCell(MeterPoint.ADJUSTING_TYPE_FIELD_DESCRIPTION_ID);
		meterPoint.getAdjustingType(function(adjustingType){
			if (adjustingType == null || adjustingType.name == null) {
				adjustingTypeCell.text("--");
				return;
			}
			adjustingTypeCell.text(adjustingType.name);
		});
		this.addDate(MeterPoint.END_DATE_FIELD_DESCRIPTION_ID, meterPoint.ende);
		let mediumCell = this.addFieldCell(MeterPoint.MEDIUM_FIELD_DESCRIPTION_ID);
		let startValueCell = this.addNumber(MeterPoint.START_VALUE_FIELD_DESCRIPTION_ID, meterPoint.startwert, null);
		meterPoint.getMedium(function(medium){
			if (medium == null || medium.name == null) {
				mediumCell.text("--");
				return;
			}
			mediumCell.text(medium.name);
			startValueCell.setUnit(medium.getMainUnit());
		});
		let balanceGroupCell = this.addFieldCell(MeterPoint.BALANCE_GROUP_FIELD_DESCRIPTION_ID);
		meterPoint.getBalanceGroup(function(balanceGroup){
			if (balanceGroup == null) {
				balanceGroupCell.text("--");
				return;
			}
			balanceGroup.getTranslation(function(nameTranslation){
				balanceGroupCell.text(nameTranslation);
			});
		});
		this.addText(MeterPoint.CONTRACT_FIELD_DESCRIPTION_ID, meterPoint.vertrag);
		let superordinateMeterPointCell = this.addFieldCell(MeterPoint.SUPERORDINATED_METER_POINT_FIELD_DESCRIPTION_ID);
		meterPoint.getSuperordinateMeterPoint(function(superordinateMeterPoint){
			if (superordinateMeterPoint == null || superordinateMeterPoint.name == null) {
				superordinateMeterPointCell.text("--");
				return;
			}
			superordinateMeterPointCell.getContentCell().append(superordinateMeterPoint.getLink());
//			superordinateMeterPointCell.text(superordinateMeterPoint.name);
		});
		let tariffCell = this.addFieldCell(MeterPoint.TARIFF_FIELD_DESCRIPTION_ID);
		meterPoint.getTariff(function(tariff){
			if (tariff == null || tariff.name == null) {
				tariffCell.text("--");
				return;
			}
			tariffCell.text(tariff.name);
		});
		let pbgCell = this.addFieldCell(MeterPoint.PLANT_BALANCE_GROUP_FIELD_DESCRIPTION_ID);
		meterPoint.getPlantBalanceGroup(function(pbg){
			if (pbg == null) {
				pbgCell.text("--");
				return;
			}
			pbg.getUsersTranslation(function(pbgTrans){
				pbgCell.text(pbgTrans.translation);
			});
		});
		let sptCell = this.addFieldCell(MeterPoint.SUPPLY_TYPE_FIELD_DESCRIPTION_ID);
		meterPoint.getSupplyType(function(spt){
			if (spt == null) {
				sptCell.text("--");
				return;
			}
			spt.getUsersTranslation(function(sptTrans){
				sptCell.text(sptTrans.translation);
			});
		});
		this.addPercent(MeterPoint.ECONOMIC_HEAT_FIELD_DESCRIPTION_ID, meterPoint.economicHeat);
		this.addTextBig(MeterPoint.MEMO_FIELD_DESCRIPTION_ID, meterPoint.memo);
	}
}
export default MeterPointDetailTable;