import Objects from "./Objects.js";
import CachedLoadChain from "../misc/CachedLoadChain.js";
import AdjustingType from "./AdjustingType.js";
import Standort from "./Standort.js";
import Medium from "./Medium.js";
import Tariff from "./Tariff.js";
import SupplyType from "./SupplyType.js";
import SelectListEntry from "./SelectListEntry.js";
import BalanceGroup from "./BalanceGroup.js";
import Link from "../ui/content/Link.js";
import MediumUnit from "./MediumUnit.js";

class MeterPoint{
	constructor(obj) {
		obj && Object.assign(this, obj);
	}
	
	static load(meterPointId, callback){
		if (meterPointId == null) {
			callback(null);
			return;
		}
		MeterPoint.cachedLoadChain.load(meterPointId, function(data){
			callback(new MeterPoint(data));
		});
	}
	
	static loadAll(standortId, callback){
		Objects.load(function(data){
			MeterPoint.handleResultList(data, callback);
		}, MeterPoint.API_ENDPOINT + "all?standortId=" + standortId);
	}
	
	static loadAllBySuperPoint(superordinateMeterPointId, callback){
		Objects.load(function(data){
			MeterPoint.handleResultList(data, callback);
		}, MeterPoint.API_ENDPOINT + "all?superordinateMeterPointId=" + superordinateMeterPointId);
	}
	
	static handleResultList(data, callback){
		let arrayLength = data.length;
		let meterPoints = [];
		for (let i = 0; i < arrayLength; i++) {
			let meterpoint = new MeterPoint(data[i]);
			meterPoints.push(meterpoint);
			MeterPoint.cachedLoadChain.cache[meterpoint.id] = meterpoint;
		}
		callback(meterPoints);
	}
	
	remove(callback){
		Objects.remove(callback, MeterPoint.API_ENDPOINT + this.id);
	}
	
	getStandort(callBack) {
		if (this.standort != null) {
			if (!(this.standort instanceof Standort)) {
				this.standort = new Standort(this.standort);
			}
			callBack(this.standort);
			return;
		}
		if (this.standortID != null && this.standortID != 0) {
			let scope = this;
			Standort.load(this.standortID, function(standort){
				scope.standort = standort;
				callBack(standort);
			});
			return;
		}
		callBack(null);
	}
	
	getAdjustingType(callBack){
		if (this.bereinigungsart != null) {
			if (!(this.bereinigungsart instanceof AdjustingType)) {
				this.bereinigungsart = new AdjustingType(this.bereinigungsart);
			}
			callBack(this.bereinigungsart);
			return;
		}
		if (this.bereinigungsartID != null && this.bereinigungsartID != 0) {
			let scope = this;
			AdjustingType.load(this.bereinigungsartID, function(adjustingType){
				scope.bereinigungsart = adjustingType;
				callBack(adjustingType);
			});
			return;
		}
		callBack(null);
	}
	
	getMedium(callBack){
		if (this.medium != null) {
			callBack(this.medium);
			return;
		}
		if (this.mediumID != null && this.mediumID != 0) {
			let scope = this;
			Medium.load(this.mediumID, function(medium){
				scope.medium = medium;
				callBack(medium);
			});
			return;
		}
		callBack(null);
	}
	
	getBalanceGroup(callBack){
		if (this.bilanzgruppe != null) {
			if (!(this.bilanzgruppe instanceof BalanceGroup)) {
				this.bilanzgruppe = new BalanceGroup(this.bilanzgruppe);
			}
			callBack(this.bilanzgruppe);
			return;
		}
		if (this.bilanzgruppeID != null && this.bilanzgruppeID != 0) {
			let scope = this;
			BalanceGroup.load(this.bilanzgruppeID, function(balanceGroup){
				scope.bilanzgruppe = balanceGroup;
				callBack(scope.bilanzgruppe);
			});
			return;
		}
		callBack(null);
	}
	
	getSuperordinateMeterPoint(callBack){
		if (this.superordinateMeterPoint != null) {
			callBack(this.superordinateMeterPoint);
			return;
		}
		if (this.superordinateMeterPointID != null && this.superordinateMeterPointID != 0) {
			let scope = this;
			MeterPoint.load(this.superordinateMeterPointID, function(superordinateMeterPoint){
				scope.superordinateMeterPoint = superordinateMeterPoint;
				callBack(superordinateMeterPoint);
			});
			return;
		}
		callBack(null);
	}
	
	getTariff(callBack){
		if (this.tariff != null) {
			callBack(this.tariff);
			return;
		}
		if (this.tariffID != null && this.tariffID != 0) {
			let scope = this;
			Tariff.load(this.tariffID, function(tariff){
				scope.tariff = tariff;
				callBack(tariff);
			});
			return;
		}
		callBack(null);
	}
	
	getSupplyType(callBack){
		if (this.supplyType != null) {
			callBack(this.supplyType);
			return;
		}
		if (this.supplyTypeID != null && this.supplyTypeID != 0) {
			let scope = this;
			SelectListEntry.load(this.supplyTypeID, function(supplyType){
				scope.supplyType = supplyType;
				callBack(supplyType);
			});
			return;
		}
		callBack(null);
	}
	
	getPlantBalanceGroup(callBack){
		if (this.plantBalanceGroup != null) {
			callBack(this.plantBalanceGroup);
			return;
		}
		if (this.plantBalanceGroupID != null && this.plantBalanceGroupID != 0) {
			let scope = this;
			SelectListEntry.load(this.plantBalanceGroupID, function(plantBalanceGroup){
				scope.plantBalanceGroup = plantBalanceGroup;
				callBack(plantBalanceGroup);
			});
			return;
		}
		callBack(null);
	}
	
	isAdjusted(callback){
		if (this.isAdjustedCache != null) {
			callback(this.isAdjustedCache);
			return;
		}
		let scope = this;
		this.getAdjustingType(function(adjustingType){
			scope.isAdjustedCache = adjustingType.isAdjusting();
			callback(scope.isAdjustedCache);
		});
	}
	getMonthlyConsumption(callback) {
		Objects.load(function(data){
			let graphData = [];
			let arrayLength = data.length;
			for (let i = 0; i < arrayLength; i++) {
				let year = data[i];
				let yearGraph = [];
				graphData.push(yearGraph);
				let monthLength = year.months.length;
				for (let j = 0; j < monthLength; j++) {
					let month = year.months[j];
					let monthGraph = [];
					yearGraph.push(monthGraph);
					monthGraph[0] = month.month + 1;
					monthGraph[1] = month.data;
				}
			}
			callback(graphData);
		}, MeterPoint.API_ENDPOINT + 'monthlyConsumption?id='+this.id);
	}
	
	getYearlyConsumption(callback) {
		Objects.load(function(data){
			let graphData = [];
			let consumptionData = [];
			graphData.push(consumptionData);
			let arrayLength = data.consumption.length;
			for (let i = 0; i < arrayLength; i++) {
				let consumption = [];
				consumptionData.push(consumption);
				consumption[0] = data.consumption[i].lable;
				consumption[1] = data.consumption[i].value;
			}
			let consumptionSubMeterData = [];
			graphData.push(consumptionSubMeterData);
			arrayLength = data.consumptionSM.length;
			for (let i = 0; i < arrayLength; i++) {
				let consumption = [];
				consumptionSubMeterData.push(consumption);
				consumption[0] = data.consumptionSM[i].lable;
				consumption[1] = data.consumptionSM[i].value;
			}
			let analysisBaseData = [];
			graphData.push(analysisBaseData);
			arrayLength = data.analysisBase.length;
			for (let i = 0; i < arrayLength; i++) {
				let analysisBase = [];
				analysisBaseData.push(analysisBase);
				analysisBase[0] = data.analysisBase[i].lable;
				analysisBase[1] = data.analysisBase[i].value;
			}
			callback(graphData);
		}, MeterPoint.API_ENDPOINT + 'yearlyConsumption?id='+this.id);
	}
	
	getURL() {
		return Objects.rootURL + "/Frontend/Messpunkt/page.jsp?messpunktID="+this.id;
	}
	
	getLink() {
		let link = new Link({
			href: this.getURL(),
			text: this.name
		});
		return link;
	}
}
MeterPoint.API_ENDPOINT = '/api/Messpunkt/';

MeterPoint.cachedLoadChain = new CachedLoadChain(MeterPoint.API_ENDPOINT);

MeterPoint.DESCRIPTON_ID = 18;

MeterPoint.NAME_FIELD_DESCRIPTION_ID = 18;
MeterPoint.MEDIUM_FIELD_DESCRIPTION_ID = 20;
MeterPoint.STANDORT_FIELD_DESCRIPTION_ID = 17;
MeterPoint.ADJUSTING_TYPE_FIELD_DESCRIPTION_ID = 19;
MeterPoint.BALANCE_GROUP_FIELD_DESCRIPTION_ID = 21;
MeterPoint.SUPERORDINATED_METER_POINT_FIELD_DESCRIPTION_ID = 22;
MeterPoint.PLANT_BALANCE_GROUP_FIELD_DESCRIPTION_ID = 23;
MeterPoint.MEMO_FIELD_DESCRIPTION_ID = 24;
MeterPoint.START_DATE_FIELD_DESCRIPTION_ID = 25;
MeterPoint.END_DATE_FIELD_DESCRIPTION_ID = 26;
MeterPoint.START_VALUE_FIELD_DESCRIPTION_ID = 27;
MeterPoint.CONTRACT_FIELD_DESCRIPTION_ID = 28;
MeterPoint.TARIFF_FIELD_DESCRIPTION_ID = 29;
MeterPoint.SUPPLY_TYPE_FIELD_DESCRIPTION_ID = 30;
MeterPoint.ECONOMIC_HEAT_FIELD_DESCRIPTION_ID = 77;
export default MeterPoint;