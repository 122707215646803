import Objects from "./Objects.js";
import FloorArea from "./FloorArea.js";
import MeterPoint from "./MeterPoint.js";

class FloorAreaMeterPointJunction{
	constructor(obj) {
		obj && Object.assign(this, obj);
	}

	static handleLoadedList(data, callback){
		let arrayLength = data.length;
		let objects = [];
		for (let i = 0; i < arrayLength; i++) {
			let object = new FloorAreaMeterPointJunction(data[i]);
			//preload related objects
			object.getMeterPoint(function(){});
			object.getFloorArea(function(){});
			objects.push(object);
		}
		callback(objects);
	}
	
	static allByMeterPoint(meterPointId, callback){
		Objects.load(function(data){
			FloorAreaMeterPointJunction.handleLoadedList(data, callback);
		}, FloorAreaMeterPointJunction.API_ENDPOINT + "all?meterPointId=" + meterPointId);
	}
	
	static allByFloorArea(floorAreaId, callback){
		Objects.load(function(data){
			FloorAreaMeterPointJunction.handleLoadedList(data, callback);
		}, FloorAreaMeterPointJunction.API_ENDPOINT + "all?buildingId=" + floorAreaId);
	}
	
	getFloorArea(callback) {
		if (this.floorArea != null) {
			callback(this.floorArea);
			return;
		}
		if(this.flaeche != null) {
			this.floorArea = new FloorArea(this.flaeche);
			callback(this.floorArea);
			return;
		}
		if (this.flaecheId) {
			let scope = this;
			FloorArea.load(this.flaecheId, function(floorArea){
				scope.floorArea = floorArea;
				callback(floorArea);
			});
			return;
		}
		callback(null);
		return;
	}
	
	setFloorAreaId(id){
		this.flaeche = null;
		this.floorArea = null;
		this.flaecheId = id;
	}
	
	getFloorAreaId() {
		if (this.floorArea != null) {
			return floorArea.id;
		}
		if (this.flaeche != null) {
			return flaeche.id;
		}
		if (this.flaecheId) {
			return this.flaecheId;
		}
		return null;
	}
	
	getMeterPoint(callback) {
		if (this.meterPoint != null) {
			callback(this.meterPoint);
			return;
		}
		if (this.messpunkt) {
			this.meterPoint = new MeterPoint(this.messpunkt);
			callback(this.meterPoint);
			return;
		}
		if (this.messpunktId) {
			let scope = this;
			MeterPoint.load(this.messpunktId, function(meterPoint){
				scope.meterPoint = meterPoint;
				callback(meterPoint);
			});
			return;
		}
		callback(null);
		return;
	}
	
	setMeterPointId(id){
		this.meterPoint = null;
		this.messpunkt = null;
		this.messpunktId = id;
	}
	
	getMeterPointId(){
		if (this.meterPoint != null) {
			return meterPoint.id;
		}
		if (this.messpunkt != null) {
			return messpunkt.id;
		}
		if (this.messpunktId) {
			return this.messpunktId;
		}
		return null;
	}
	
	getUrl() {
		return Objects.rootURL + '/Frontend/FlaecheMesspunkt/show/page.jsp?flaecheId=' + this.flaecheId + '&messpunktId=' + this.messpunktId;
	}
}
FloorAreaMeterPointJunction.API_ENDPOINT = '/api/FlaecheMesspunkt/';
export default FloorAreaMeterPointJunction;