import UI from "../defaultUi.js";
import Label from "../content/Label.js";
import TableCell from "./TableCell.js";

class LabeledTableCell extends UI{
	constructor(config) {
		super(config);
		if (config) {
			this.realTable = config.realTable;
			if (config.colSpan) {
				this.colSpan = config.colSpan;
			}
			if (config.contentCell) {
				this.contentCell = config.contentCell;
			}
		}
		if (this.colSpan == null) {
			this.colSpan = 1;
		}
		if (this.realTable) {
			this.labelCell = $('<td></td>');
		} else {
			this.labelCell = $('<div class="showtable_cell"></div>');
		}
		if (this.contentCell == null) {
			this.contentCell = new TableCell(config);
		}
//		if (this.realTable) {
//			this.contentCell = $('<td COLSPAN="' + this.colSpan + '"></td>');
//		} else {
//			this.contentCell = $('<div class="showtable_cell"></div>');
//		}
		if(config != null) {
			if (config.labelText != null) {
				this.labelCell.text(config.labelText);
			} else if (config.fieldId){
				let label = new Label({
					fieldId: config.fieldId
				});
				this.labelCell.append(label.getJQueryRepresentation());
			}
//			if (config.contentText) {
//				this.contentCell.text(config.contentText);
//			}
//			if (config.type) {
//				if (config.type == LabeledTableCell.TYPE_DATE) {
//					this.contentCell.addClass('date');
//				}
//				if (config.type == LabeledTableCell.TYPE_NUMBER) {
//					this.contentCell.addClass('number');
//				}
//			}
		}
	}
	
	getContentCell() {
		return this.contentCell;
	}
	
	getLabelCell() {
		return this.labelCell;
	}
	
	text(text){
		return this.contentCell.text(text);
	}
	
	remove() {
		this.labelCell.remove();
		this.contentCell.remove();
	}
}
LabeledTableCell.TYPE_DATE = 'date';
LabeledTableCell.TYPE_NUMBER = 'number';
export default LabeledTableCell;