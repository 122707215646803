import Meter from "../../../objects/Meter.js";
import MeterPoint from "../../../objects/MeterPoint.js";
import Reading from "../../../objects/Reading.js";
import SortableListBoxGroup from "../../table/SortableListBoxGroup.js";
import Standort from "../../../objects/Standort.js";
import Objects from "../../../objects/Objects.js";

class MeterPointAndMeterList extends SortableListBoxGroup{
	constructor(config){
		super(config);
		this.contentHeadline.setHeadlineByTranslation("Messpunkte und Zaehler");
		let scope = this;

		if (config) {
			if (config.standortId) {
				Standort.load(config.standortId, function(standort){
					this.setStandort(standort);
				});
			}
			if (config.standort) {
				this.setStandort(config.standort);
			}
		}

		this.contentHeadline.getNewButton().click(function(event) {
			window.location.href = Objects.rootURL + "/Frontend/Messpunkt/create/page.jsp?standortID=" + scope.standort.id;
		}, {});
	}
	
	setStandort(standort){
		this.load(standort);
	}
	
	load(standort) {
		this.standort = standort;
		var scope = this;
		MeterPoint.loadAll(standort.id, function(meterpoints){
			for (let i = 0; i < meterpoints.length; i++) {
				let meterPoint = meterpoints[i];
				meterPoint.getMedium(function(medium){
					//just for caching
				});
				Meter.loadAllActive(meterPoint.id, function(meters){
					if(meters == null || meters.length == 0){
						let tableData = {
								meterPoint: meterPoint
							};
						scope.pushRow(tableData, true);
					} else{
						let tableDatas = [];
						for (let k = 0; k < meters.length; k++) {
							let meter = meters[k];
							meter.getNewestReading(function(reading){
								//just for caching
							});
							tableDatas[k] = {
									meterPoint: meterPoint,
									meter: meter
								};
						}
						scope.pushAll(tableDatas);
					}
				});
			}
		});
	}
	
	renderHead() {
		this.addSortHeaderByFDI(MeterPoint.NAME_FIELD_DESCRIPTION_ID, 'meterPoint.name');
		this.addSortHeaderByFDI(Meter.METER_NUMBER_FIELD_DESCRIPTION_ID, 'meter.nr');
		this.addSortHeaderByFDI(MeterPoint.MEDIUM_FIELD_DESCRIPTION_ID, 'meterPoint.medium.name');
		this.addSortHeaderTranslation('letztes Ablesedatum', 'meter.newestReading.datum');
		this.addSortHeaderTranslation('zealerstand', 'meter.newestReading.zaehlerStand');
	}

	renderRow(entry, row) {
		let scope = this;
		row.addCellDomObj(entry.meterPoint.getLink());
		if(entry.meter != null){
			row.addCellDomObj(entry.meter.getLink());
		} else {
			row.addCell("");
		}
		entry.meterPoint.getMedium(function(medium){
			row.addCell(medium.name);
		});
		if(entry.meter != null){
			entry.meter.getNewestReading(function(reading){
				if (reading != null) {
					row.addDate(reading.datum);
					row.addNumber(reading.zaehlerStand);
				} else {
					row.addDate(null);
					row.addNumber(null);
				}
			});
		} else {
			row.addDate(null);
			row.addNumber(null);
		}
	}
}
export default MeterPointAndMeterList;