import UI from "../defaultUi.js";

class DateInput extends UI{
	constructor(config){
		super(config);
		this.dateInput = $('<input type="date" class="date_default" />');
		super.setBaseJQueryObject(this.dateInput);
		if (config) {
			if (config.id) {
				this.dateInput.attr('id', config.id);
			}
			if (config.minDate) {
				this.setMin(config.minDate);
			}
			if (config.maxDate) {
				this.setMax(config.maxDate)
			}
		}
		if (!Modernizr.inputtypes.date) {
			this.dateInput.datepicker();
		}
	}
	
	setFieldError(){
		this.dateInput.addClass('RequiredFieldNotSet');
	}
	
	removeFieldError(){
		this.dateInput.removeClass('RequiredFieldNotSet');
	}
	
	
	uiInit() {
	}
	
	change(callback){
		this.dateInput.change(callback);
	}
	
	trigger(event, data) {
		this.dateInput.trigger(event, data);
	}
	
	dateToHtmlString(date){
		let day = ('0' + date.getDate()).slice(-2);
		let month = ('0' + (date.getMonth()+1)).slice(-2);
		return date.getFullYear()+'-'+month+'-'+day;
	}
	
	setMin(minDate) {
		if (!Modernizr.inputtypes.date) {
			return this.dateInput.datepicker("option", "minDate", minDate);
		}
		this.dateInput.attr('min', this.dateToHtmlString(minDate));
	}
	
	setMax(maxDate) {
		if (!Modernizr.inputtypes.date) {
			return this.dateInput.datepicker("option", "maxDate", maxDate);
		}
		this.dateInput.attr('max', this.dateToHtmlString(maxDate));
	}

	val(newVal) {
		if (newVal) {
			let dateToSet = new Date(newVal);
			if (!Modernizr.inputtypes.date) {
				return this.dateInput.datepicker('setDate', dateToSet);
			}
			return this.dateInput.val(this.dateToHtmlString(dateToSet));
		}
		if (!Modernizr.inputtypes.date) {
			return this.dateInput.datepicker('getDate');
		}
		let val = this.dateInput.val();
		if (val && val != null) {
			return new Date(val);
		}
		return val;
	}
}
export default DateInput;