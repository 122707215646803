import UI from "../ui/defaultUi.js";
import Translation  from "./Translation.js";
class Objects{
	static save(entry, callback, endpoint){
		endpoint = Objects.rootURL + endpoint;
		$.ajax({   
			type: "PUT",
			url: endpoint,
			data: JSON.stringify(entry),
			dataType: "json",
			contentType: "application/json; charset=utf-8",
			success: function(data) {
				if(callback){
					callback(data);
				}
			},
			error: function(jqXHR, textStatus, errorThrown ) {
				if(jqXHR.status != 401){
					UI.addToError(jqXHR.responseText);
				}
			}
		});
	}
	
	static post(entry, callback, endpoint, scope){
		endpoint = Objects.rootURL + endpoint;
		$.ajax({   
			type: "POST",
			url: endpoint,
			data: JSON.stringify(entry),
			dataType: "json",
			contentType: "application/json; charset=utf-8",
			success: function(data) {
				if(callback){
					if (scope) {
						callback.call(scope, data);
					} else {
						callback(data);
					}
				}
			},
			error: function(jqXHR, textStatus, errorThrown ) {
				if(jqXHR.status != 401){
					UI.addToError(jqXHR.responseText);
				}
				if (scope) {
					if (scope.errorCallBack) {
						scope.errorCallBack();
					}
				}
			}
		});
	}
	
	static load(callback, endpoint, async, errorHandler){
		if (async == null) {
			async = true;
		}
		endpoint = Objects.rootURL + endpoint;
		$.ajax({   
			type: "GET",
			url: endpoint,
			dataType: "json",
			async: async,
			contentType: "application/json; charset=utf-8",
			success: function(data) {
				callback(data);
			},
			error: function(jqXHR, textStatus, errorThrown ) {
				if(jqXHR.status != 401){
					UI.addToError(jqXHR.responseText);
				}
				if(errorHandler){
					errorHandler();
				}
			}
		});
	}
	
	static remove(callback, endpoint){
		endpoint = Objects.rootURL + endpoint;
		$.ajax({
			type: "DELETE",  
			url: endpoint,
			dataType: "json",
			contentType: "application/json; charset=utf-8",
			success: function(data) {
				callback(data);
			},
			error: function(jqXHR, textStatus, errorThrown ) {
				if(jqXHR.status != 401){
					UI.addToError(jqXHR.responseText);
				}
			}
		});
	}
	
	static addNewButton(toAddTo, onClick, onClickParam){
		var newButton = $('<button type="button" name="create" value="create" ><span class="glyphicon glyphicon-pencil" aria-hidden="true"></span></button>');
		toAddTo.append(newButton);
		Translation.load('create', function(translation){
			var translationSpan = $('<span></span>')
			newButton.append(translationSpan);
			translationSpan.text(' ' + translation.text);
		});
		newButton.click(onClickParam, onClick);
		return newButton;
	}
	
	static addShowButton(toAddTo, onClick, onClickParam){
		var showButton = $('<button name="show" type="button" value="show" ><span class="glyphicon glyphicon-eye-open" aria-hidden="true"></span></button>');
		toAddTo.append(showButton);
		Translation.load('show', function(translation){
			var translationSpan = $('<span></span>')
			showButton.append(translationSpan);
			translationSpan.text(' ' + translation.text);
		});
		showButton.click(onClickParam, onClick);
		return showButton;
	}
	
	static addSaveButton(toAddTo, onClick, onClickParam){
		var saveButton = $('<button type="button" value="save" ><span class="glyphicon glyphicon-floppy-disk" aria-hidden="true"></span></button>');
		toAddTo.append(saveButton);
		Translation.load('save', function(translation){
			var translationSpan = $('<span></span>')
			saveButton.append(translationSpan);
			translationSpan.text(' ' + translation.text);
		});
		saveButton.click(onClickParam, onClick);
		return saveButton;
	}
	
	static addEditButton(toAddTo, onClick, onClickParam){
		var editButton = $('<button type="button" value="edit" ><span class="glyphicon glyphicon-pencil" aria-hidden="true"></span></button>');
		toAddTo.append(editButton);
		Translation.load('edit', function(translation){
			var translationSpan = $('<span></span>')
			editButton.append(translationSpan);
			translationSpan.text(' ' + translation.text);
		});
		editButton.click(onClickParam, onClick);
		return editButton;
	}
	
	static addCancelButton(toAddTo, onClick, onClickParam){
		var cancelButton = $('<button type="button" value="cancel" ><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>');
		toAddTo.append(cancelButton);
		Translation.load('cancel', function(translation){
			var translationSpan = $('<span></span>')
			cancelButton.append(translationSpan);
			translationSpan.text(' ' + translation.text);
		});
		cancelButton.click(onClickParam, onClick);
		return cancelButton;
	}
	
	static createDateInput(toAddTo, id){
		var dateInput = $('<input type="date" class="date_default" />');
		if (id) {
			dateInput.attr('id', id);
		}
		toAddTo.append(dateInput);
		return dateInput;
	}
	
	static createTable(columnCount, toAddTo){
		var tableDiv = $('<div class="showtable"></div>');
		if (toAddTo) {
			toAddTo.append(tableDiv);
		}
		var columnWidth = 100 / columnCount;
		for (var i = 0; i < columnCount; i++) {
			var colGrouping = $('<div style="display: table-column-group;width: ' + columnWidth + '%;"><div style="display: table-column;width: 1%;"></div><div style="display: table-column;"></div></div>');
			tableDiv.append(colGrouping);
		}
		return tableDiv;
	}
}
Objects.rootURL = "";
export default Objects;