import UI from "../../defaultUi.js";
import ContentBoxGroup from "../../content/ContentBoxGroup.js";
import ShowDetailsHeader from "../../content/ShowDetailsHeader.js";
import ContentBox from "../../ContentBox.js";
import MeterDetailTable from "./MeterDetailTable.js";
import Meter from "../../../objects/Meter.js";
import Translation from "../../../objects/Translation.js";
import Objects from "../../../objects/Objects.js";
import EditMeterOverlay from "./EditMeterOverlay.js";

class MeterDetailBox extends UI{
	constructor(config) {
		super(config);
		
		this.contentBoxGroup = new ContentBoxGroup();
		super.setBaseJQueryObject(this.contentBoxGroup);
		this.header = new ShowDetailsHeader({
			descriptonId: Meter.OBJECT_DESCRIPTION_ID
		});
		this.contentBoxGroup.append(this.header);
		this.contentBox = new ContentBox();
		this.contentBoxGroup.append(this.contentBox);

		let scope = this;
		Translation.load("Zaehler wirklich loeschen?", function(translation){
			scope.confirmDeleteText = translation.text;
		});
		
		if (config != null) {
			if (config.meter != null) {
				this.setMeter(config.meter);
			}
			if (config.meterId != null) {
				let scope = this;
				Meter.load(config.meterId, function(meter){
					scope.setMeter(meter);
				});
			}
		}
	}
	
	setMeter(meter) {
		if (this.meter != null) {
			this.contentBox.clear();
			this.header.getEditButton().clearClick();
			this.header.getDeleteButton().clearClick();
		}
		this.meter = meter;
		this.meterDetailTable = new MeterDetailTable({
			meter: this.meter
		});
		this.contentBox.append(this.meterDetailTable);
		let scope = this;
		this.header.getEditButton().click(function(){
			let editMeterOverlay = new EditMeterOverlay({
				meter: scope.meter,
				eventCallback: function(event){
					scope.setMeter(event.data);
				}
			});
		});
		this.header.getDeleteButton().click(function(){
			let r = confirm(scope.confirmDeleteText);
			if (r == true) {
				scope.meter.remove(function(data){
					if(data.success === true){
						window.location.href = document.referrer;
					}else{
						cleanError();
						addToError(data.statusMessage);
					}
				});
			}
		});
	}
}
export default MeterDetailBox;