import UI from "../defaultUi.js";
import User from "../../objects/User.js";
import NumberTableCell from "./NumberTableCell.js";

class FileSizeTableCell extends NumberTableCell{
	constructor(config) {
		if (config == null) {
			config = new Object();
		}
		super(config);
		this.setFileSize(config.fileSize);
	}
	
	setFileSize(size) {
		if (size == null) {
			this.setNumber(null);
			this.setUnit(FileSizeTableCell.units[0]);
			return;
		}
		let exponent = 0;
		while (size > 10) {
			size /= 1000;
			exponent++;
		}
		this.setNumber(size);
		this.setUnit(FileSizeTableCell.units[exponent]);
	}
}
FileSizeTableCell.units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'YB'];
export default FileSizeTableCell;