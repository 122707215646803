import UI from "../../defaultUi.js";
import OverlayBox from "../../OverlayBox.js";
import Button from "../../Button.js";
import InputTable from "../../table/InputTable.js";
import File from "../../../objects/File.js";
import EVAEvent from "../../EVAEvent.js";
import UploadingOverlay from "../../content/UploadingOverlay.js";
import Observable from "../../../misc/Observable.js";
import InfoOverlay from "../../content/InfoOverlay.js";
import Upload from "../../../misc/Upload.js";

class FileUploadOverlay extends Observable{
	
	constructor(config) {
		super(config);
		this.objectTypeId = config.objectTypeId;
		this.objectId = config.objectId;
		this.priviusVersion = config.priviusVersion;
		this.overlayBox = new OverlayBox({
			isCloseable: true
		});
		
		let footer = this.overlayBox.getFooter();
		let saveButton = Button.createSaveButton()
		footer.append(saveButton);
		let scope = this;
		saveButton.click(function(event){
			scope.save();
		});
		
		this.table = new InputTable(1);
		this.overlayBox.append(this.table);
		this.fileInput = this.table.addFileInput(File.FILE_FIELD_DESCRIPTION_ID, File.FILE_INPUT_ID);
	}
	
	save() {
		let scope = this;
		let file = new File({
			objectTypeId :this.objectTypeId,
			objectId : this.objectId,
			priviusVersion : this.priviusVersion
		});
		Upload.uploadByInput(file.uploadUrl(), this.fileInput, function(data){
			scope.overlayBox.remove();
			if (data instanceof EVAEvent) {
				let infoOverlay = new InfoOverlay({data:data});
				return;
			}
			let event = new EVAEvent({
				type: EVAEvent.TYPE_SAVE,
				data: file
			});
			scope.notify(event);
		});
	}
}
export default FileUploadOverlay;