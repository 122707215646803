import UI from "../defaultUi.js";
import Objects from "../../objects/Objects.js";
import Label from "../content/Label.js";
import PercentageInput from "../inputs/PercentageInput.js";
import DateInput from "../inputs/DateInput.js";
import NumberInput from "../inputs/NumberInput.js";
import NumberUnitInput from "../inputs/NumberUnitInput.js";
import SelectListInput from "../inputs/SelectListInput.js";
import EmailInput from "../inputs/EmailInput.js";
import BooleanInput from "../inputs/BooleanInput.js";

class InputTable extends UI{
	constructor(columnCount){
		super();
		this.columnCount = columnCount;
		this.tableDiv = $('<div class="showtable"></div>');
		super.setBaseJQueryObject(this.tableDiv);
		var columnWidth = 100 / columnCount;
		for (var i = 0; i < columnCount; i++) {
			var colGrouping = $('<div style="display: table-column-group;width: ' + columnWidth + '%;"><div style="display: table-column;width: 1%;"></div><div style="display: table-column;"></div></div>');
			this.tableDiv.append(colGrouping);
		}
		this.curentColumn = columnCount;
	}
	
	addNewRow(){
		this.curentTableRowDiv = $('<div class="showtable_row"></div>');
		this.tableDiv.append(this.curentTableRowDiv);
		this.curentColumn = 0;
	}
	
	createLabeledCell(fieldDescriptionId, inputId){
		if (this.curentColumn + 1 > this.columnCount) {
			this.addNewRow();
		}
		this.curentColumn++;
		var labelCell = $('<div class="showtable_cell"></div>');
		this.curentTableRowDiv.append(labelCell);
		var label = new Label({
			fieldId: fieldDescriptionId,
			forField: inputId
		});
		labelCell.append(label.getJQueryRepresentation());
		
		var cell = $('<div class="showtable_cell"></div>');
		this.curentTableRowDiv.append(cell);
		return cell;
	}
	
	createTranslationLabeledCell(translationKey, inputId){
		if (this.curentColumn + 1 > this.columnCount) {
			this.addNewRow();
		}
		this.curentColumn++;
		var labelCell = $('<div class="showtable_cell"></div>');
		this.curentTableRowDiv.append(labelCell);
		var label = new Label({
			translationKey: translationKey,
			forField: inputId
		});
		labelCell.append(label.getJQueryRepresentation());
		
		var cell = $('<div class="showtable_cell"></div>');
		this.curentTableRowDiv.append(cell);
		return cell;
	}
	
	addInput(fieldDescriptionId, inputId){
		var inputCell = this.createLabeledCell(fieldDescriptionId, inputId);
		var inputElement = $('<input name="' + inputId + '" id="' + inputId + '" />');
		inputCell.append(inputElement);
		return inputElement;
	}
	
	addTextInput(fieldDescriptionId, inputId){
		return this.addInput(fieldDescriptionId, inputId);
	}
	
	addSelect(fieldDescriptionId, inputId){
		var inputCell = this.createLabeledCell(fieldDescriptionId, inputId);
		var inputElement = new SelectListInput({id: inputId});
		inputCell.append(inputElement.getJQueryRepresentation());
		return inputElement;
	}
	
	addTextArea(fieldDescriptionId, inputId){
		var inputCell = this.createLabeledCell(fieldDescriptionId, inputId);
		var inputElement = $('<textarea name="' + inputId + '" id="' + inputId + '"></textarea>');
		inputCell.append(inputElement);
		return inputElement;
	}
	
	addDateInput(fieldDescriptionId, inputId){
		var inputCell = this.createLabeledCell(fieldDescriptionId, inputId);
		var inputElement = new DateInput({id: inputId});
		inputCell.append(inputElement.getJQueryRepresentation());
		return inputElement;
	}
	
	addTextCell(fieldDescriptionId, value){
		var cell = this.createLabeledCell(fieldDescriptionId, null);
		cell.text(value);
		return cell;
	}
	
	addFileInput(fieldDescriptionId, inputId) {
		var inputCell = this.createLabeledCell(fieldDescriptionId, inputId);
		var inputElement = $('<input type="file" name="' + inputId + '" id="' + inputId + '" />');
		inputCell.append(inputElement);
		return inputElement;
	}
	
	addPercentageInput(fieldDescriptionId, inputId) {
		var inputCell = this.createLabeledCell(fieldDescriptionId, inputId);
		var inputElement = new PercentageInput({
			inputId: inputId
		});
		inputCell.append(inputElement.getJQueryRepresentation());
		return inputElement;
	}
	
	addNumberInput(fieldDescriptionId, inputId){
		var inputCell = this.createLabeledCell(fieldDescriptionId, inputId);
		var inputElement = new NumberInput({id: inputId});
		inputCell.append(inputElement.getJQueryRepresentation());
		return inputElement;
	}
	
	addNumberUnitInput(fieldDescriptionId, inputId, unit){
		var inputCell = this.createLabeledCell(fieldDescriptionId, inputId);
		var inputElement = new NumberUnitInput({id: inputId, unit:unit});
		inputCell.append(inputElement.getJQueryRepresentation());
		return inputElement;
	}
	
	addEmailInput(fieldDescriptionId, inputId) {
		var inputCell = this.createLabeledCell(fieldDescriptionId, inputId);
		var inputElement = new EmailInput({id: inputId});
		inputCell.append(inputElement.getJQueryRepresentation());
		return inputElement;
	}
	
	addBooleanInput(fieldDescriptionId, inputId) {
		var inputCell = this.createLabeledCell(fieldDescriptionId, inputId);
		var inputElement = new BooleanInput({id: inputId});
		inputCell.append(inputElement.getJQueryRepresentation());
		return inputElement;
	}
}
export default InputTable;