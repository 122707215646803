import UI from "../ui/defaultUi.js";
import Button from '../ui/Button.js';
import Objects from "./Objects.js";

class WeatherStation {
	
	constructor(wetherstationData){
		wetherstationData && Object.assign(this, wetherstationData);
		console.log("this is the weatherStation");
	}
	
	static load(id, callback){
		var weatherStationLoadcallback = callback;
		Objects.load(function(data){
			let weatherStation = new WeatherStation(data);
			weatherStationLoadcallback(weatherStation);
		}, WeatherStation.API_ENDPOINT + id);
	}
	
	static getDistance(weatherStationId, standortId, callback){
		Objects.post({
			weatherStationId: weatherStationId,
			standortId: standortId
		}, callback, WeatherStation.API_ENDPOINT + 'distance');
	}
	
	getDegreeDays(start, end, temperatur, callback) {
		let request = {};
		request.weatherStation = this.id;
		request.startDate = new Date(start);
		request.endDate = new Date(end);
		request.temperatur = parseFloat(temperatur);
		var scope = this;
		scope.currentCallback = callback
		let localCalback = function(result) {
			scope.currentCallback(result.degriDays);
		};
		Objects.post(request, localCalback, WeatherStation.API_ENDPOINT + "DegriDays", scope);
	}
	
	createCalculateDegreeDaysButton() {
		let calculateButton = new Button({
			buttonText: "Gradtage"
		});
		var buttonScope = this;
		calculateButton.click(function(event){
			let calculateDegreeDaysOverlay = new CalculateDegreeDaysOverlay({weatherStation: event.data.scope});
			calculateDegreeDaysOverlay.createCalculateDegreeDaysOverlay();
		},{
			scope: buttonScope
		});
		return calculateButton;
	}
}
WeatherStation.API_ENDPOINT = "/api/WetterStation/";

export default WeatherStation;

class CalculateDegreeDaysOverlay {
	
	constructor(config){
		this.wetherStation = config.weatherStation;
	}
	
	createCalculateDegreeDaysOverlay() {
		let overlayBox = new OverlayBox({isCloseable: true});
		UI.append(overlayBox);
		let contentBox = new ContentBox();
		overlayBox.append(contentBox);
		let table = new LabeledTable();
		contentBox.append(table);
		this.startInput = new LabeledTableDateInputCell({
			labelText: "Start"
		});
		table.append(this.startInput);
		this.endInput = new LabeledTableDateInputCell({
			labelText: "End"
		});
		table.append(this.endInput);
		this.temperaturInput = new LabeledTableInputCell({
			labelText: "Temperatur"
		});
		table.append(this.temperaturInput);
		this.resultCell = new LabeledTableCell({
			labelText: "Gradtage"
		});
		table.append(this.resultCell);
		
		this.footer = overlayBox.getFooter();
		
		this.calculateButton = new Button({
			buttonText: "calculate"
		});
		this.footer.append(this.calculateButton);
		var buttonScope = this;
		this.calculateButton.click(function(event){
			event.data.scope.runCalculation();
		},{
			scope: buttonScope
		});
	}
	
	runCalculation() {
		let start = this.startInput.getVal();
		let end = this.endInput.getVal();
		let temperatur = this.temperaturInput.getVal();
		var scope = this;
		this.wetherStation.getDegreeDays(start, end, temperatur, function(degriDays){
			scope.resultCell.getContentCell().text(degriDays);
		});
	}
	
}

class WeatherStationShowBox extends UI{
	constructor(config) {
		super();
		this.contentBox = new ContentBox();
		super.setBaseJQueryObject(this.contentBox);
		
		let table = new LabeledTable();
		contentBox.append(table);
		this.nameCell = new LabeledTableCell();
		var scope = this;
		Translation.loadTranslation("weatherStation.Name", function(translation){
			scope.nameCell.getLabelCell().text(translation);
		});
		table.append(this.nameCell);
		this.ngtz18Cell = new LabeledTableCell();
		Translation.loadTranslation("weatherStation.Ngtz18", function(translation){
			scope.ngtz18Cell.getLabelCell().text(translation);
		});
		table.append(this.ngtz18Cell);
		this.ngtz20Cell = new LabeledTableCell();
		Translation.loadTranslation("weatherStation.Ngtz20", function(translation){
			scope.ngtz20Cell.getLabelCell().text(translation);
		});
		table.append(this.ngtz20Cell);
		this.ngtzFromCell = new LabeledTableCell();
		Translation.loadTranslation("weatherStation.NgtzFrom", function(translation){
			scope.ngtzFromCell.getLabelCell().text(translation);
		});
		table.append(this.ngtzFromCell);
		this.ngtzTillCell = new LabeledTableCell();
		Translation.loadTranslation("weatherStation.NgtzTill", function(translation){
			scope.ngtzTillCell.getLabelCell().text(translation);
		});
		table.append(this.ngtzTillCell);
		if (config != null) {
			if (config.weatherStation != null) {
				setValues(config.weatherStation);
			}
		}
	}
	
	setValues(weatherStation) {
		this.nameCell.getContentCell().text(weatherStation.name);
		this.ngtz18Cell.getContentCell().text(weatherStation.ngtz18);
		this.ngtz20Cell.getContentCell().text(weatherStation.ngtz20);
		this.ngtzFromCell.getContentCell().text(weatherStation.ngtzFrom);
		this.ngtzTillCell.getContentCell().text(weatherStation.ngtzTill);
	}
}
