import UI from "../../defaultUi.js";
import SortableListBoxGroup from "../../table/SortableListBoxGroup.js";
import Label from "../../content/Label.js";
import FloorArea from "../../../objects/FloorArea.js";
import ObjectTranslation from "../../../objects/ObjectTranslation.js";
import Objects from "../../../objects/Objects.js";
import EditFloorArea from "../floorArea/EditFloorArea.js";
import Standort from "../../../objects/Standort.js";

class StandortFloorAreaList extends SortableListBoxGroup{
	
	constructor(config) {
		super(config);
		
		this.contentHeadline.setHeadlineById(FloorArea.OBJECT_DESCRIPTION_ID);
		let scope = this;
		this.contentHeadline.getNewButton().click(function(event) {
			new EditFloorArea({
				standort: scope.standort,
				eventCallback: function(event) {
					if (event.isSaved()) {
						scope.push(event.data);
					}
				}
			});
		}, {});

		if (config) {
			if (config.standort) {
				this.setStandort(config.standort);
			} else if (config.standortId) {
				Standort.load(config.standortId, function(standort){
					scope.setStandort(standort);
				});
			}
		}
	}
	
	setStandort(standort) {
		this.standort = standort;
		if (this.standort != null) {
			this.load();
		}
	}
	
	load() {
		let scope = this;
		FloorArea.loadAll(this.standort.id, function(floorAreas){
			scope.pushAll(floorAreas);
		});
	}

	renderHead() {
		this.addSortHeaderByFDI(FloorArea.FIELD_NAME_ID, 'bezeichnung');
		this.addSortHeaderByFDI(FloorArea.FIELD_AREA_M2_ID, 'bg_flaeche_m2');
	}
	
	renderRow(entry) {
		let areaA = $('<a href="' + Objects.rootURL + '/Frontend/Flaeche/page.jsp?flaecheID=' + entry.id + '"></a>');
		areaA.text(entry.bezeichnung);
		this.table.addCellDomObj(areaA);
		this.table.addNumber(entry.bg_flaeche_m2);
	}
}
export default StandortFloorAreaList;