import UI from "../../defaultUi.js";
import ContentBoxGroup from "../../content/ContentBoxGroup.js";
import ContentHeadline from "../../content/ContentHeadline.js";
import ContentBox from "../../ContentBox.js";
import Translation from "../../../objects/Translation.js";
import Button from "../../Button.js";
import Objects from "../../../objects/Objects.js";
import MonthlyPlot from "./analysis/MonthlyPlot.js";
import YearlyPlot from "./analysis/YearlyPlot.js";
import YearlyPlotSpecific from "./analysis/YearlyPlotSpecific.js";
import Standort from "../../../objects/Standort.js";

class StandortConsumptionAnalysis extends UI{
	constructor(config) {
		super(config);
		
		if (config) {
			if (config.standort) {
				this.standort = config.standort;
			} else if (config.standortId) {
				this.standort = new Object();
				this.standort.id = config.standortId;
			}
		}
		
		this.contentBoxGroup = new ContentBoxGroup();
		super.setBaseJQueryObject(this.contentBoxGroup);
		this.header = new ContentHeadline();
		this.contentBoxGroup.append(this.header);
		
		let scope = this;
		Translation.load("Auswertung", function(translation){
			scope.header.text(translation.text + ": ");
		});
		
		this.selectAnalysisIntervall = $('<select size="1"></select>');
		this.header.middleSpan.append(this.selectAnalysisIntervall);
		let optionMonthly = $('<option value="monthly"></option>');
		this.selectAnalysisIntervall.append(optionMonthly);
		Translation.load("monthly", function(translation){
			optionMonthly.text(translation.text);
		});
		let optionYearly = $('<option value="yearly"></option>');
		this.selectAnalysisIntervall.append(optionYearly);
		Translation.load("yearly", function(translation){
			optionYearly.text(translation.text);
		});
		let optionYearlySpecific = $('<option value="yearly_specific"></option>');
		this.selectAnalysisIntervall.append(optionYearlySpecific);
		Translation.load("yearly specific", function(translation){
			optionYearlySpecific.text(translation.text);
		});

		let downloadCsvButton = Button.createDownloadCsvButton();
		this.header.append(downloadCsvButton);
		downloadCsvButton.click(function(event) {
			let seltion = scope.selectAnalysisIntervall.val();
			if (seltion == "monthly") {
				window.location.assign(Objects.rootURL + scope.standort.getMonthlyConsumptionCSVURL());
			} else if (seltion == "yearly") {
				window.location.assign(Objects.rootURL + scope.standort.getYearlyConsumptionCSVURL());
			} else if (seltion == "yearly_specific") {
				window.location.assign(Objects.rootURL + scope.standort.getYearlyConsumptionSpecificCSVURL());
			}
		});

		this.header.getShowButton();
		this.header.getHideButton().hide();

		this.isDisplayed = false;
		
		this.selectAnalysisIntervall.change({
			target: this.selectAnalysisIntervall,
		}, function(event) {
			if(scope.isDisplayed){
				scope.renderGraphs();
			}
		});
		
		this.header.getShowButton().click(function(){
			scope.isDisplayed = true;
			scope.renderGraphs();
		});
		
		this.header.getHideButton().click(function(){
			scope.isDisplayed = false;
			scope.contentBoxLocal.remove();
			scope.contentBoxLocal = null;
		});
	}
	
	renderGraphs() {
		if (this.contentBoxLocal == null) {
			this.contentBoxLocal = new ContentBox();
			this.contentBoxGroup.append(this.contentBoxLocal);
		} else {
			this.contentBoxLocal.clear();
		}
		let seltion = this.selectAnalysisIntervall.val();
		if (seltion == "monthly") {
			if (this.emPlot == null) {
				this.emPlot = new MonthlyPlot({
					typ: MonthlyPlot.GRAPH_TYP_ELECTRICITY,
					standort: this.standort
				});
			}
			this.contentBoxLocal.append(this.emPlot);
			this.emPlot.render();
			if (this.hmPlot == null) {
				this.hmPlot =new MonthlyPlot({
					typ: MonthlyPlot.GRAPH_TYP_HEAT,
					standort: this.standort
				});
			}
			this.contentBoxLocal.append(this.hmPlot);
			this.hmPlot.render();
			if (this.wmPlot == null) {
				this.wmPlot = new MonthlyPlot({
					typ: MonthlyPlot.GRAPH_TYP_WATER,
					standort: this.standort
				});
			}
			this.contentBoxLocal.append(this.wmPlot);
			this.wmPlot.render();
		} else if (seltion == "yearly") {
			if (this.eyPlot == null) {
				this.eyPlot = new YearlyPlot({
					typ: YearlyPlot.GRAPH_TYP_ELECTRICITY,
					standort: this.standort
				});
			}
			this.contentBoxLocal.append(this.eyPlot);
			this.eyPlot.render();
			if (this.hyPlot == null) {
				this.hyPlot =new YearlyPlot({
					typ: YearlyPlot.GRAPH_TYP_HEAT,
					standort: this.standort
				});
			}
			this.contentBoxLocal.append(this.hyPlot);
			this.hyPlot.render();
			if (this.wyPlot == null) {
				this.wyPlot = new YearlyPlot({
					typ: YearlyPlot.GRAPH_TYP_WATER,
					standort: this.standort
				});
			}
			this.contentBoxLocal.append(this.wyPlot);
			this.wyPlot.render();
		} else if (seltion == "yearly_specific") {
			if (this.eysPlot == null) {
				this.eysPlot = new YearlyPlotSpecific({
					typ: YearlyPlotSpecific.GRAPH_TYP_ELECTRICITY,
					standort: this.standort
				});
			}
			this.contentBoxLocal.append(this.eysPlot);
			this.eysPlot.render();
			if (this.hysPlot == null) {
				this.hysPlot =new YearlyPlotSpecific({
					typ: YearlyPlotSpecific.GRAPH_TYP_HEAT,
					standort: this.standort
				});
			}
			this.contentBoxLocal.append(this.hysPlot);
			this.hysPlot.render();
			if (this.wysPlot == null) {
				this.wysPlot = new YearlyPlotSpecific({
					typ: YearlyPlotSpecific.GRAPH_TYP_WATER,
					standort: this.standort
				});
			}
			this.contentBoxLocal.append(this.wysPlot);
			this.wysPlot.render();
		}
	}
}
export default StandortConsumptionAnalysis;