import Objects from "./Objects.js";
import CachedLoadChain from "../misc/CachedLoadChain.js";
import MeterPoint from "./MeterPoint.js";
import MediumUnit from "./MediumUnit.js";
import Reading from "./Reading.js";
import Link from "../ui/content/Link.js";

class Meter{
	
	constructor(obj) {
		obj && Object.assign(this, obj);
	}
	
	static load(id, callback){
		Meter.cachedLoadChain.load(id, function(data){
			callback(new Meter(data));
		});
	}
	
	static loadAll(meterPointId, callback){
		Objects.load(function(data){
			Meter.handleListResult(callback, data);
		}, Meter.API_ENDPOINT + "all/" + meterPointId);
	}
	
	static loadAllActive(meterPointId, callback){
		Objects.load(function(data){
			Meter.handleListResult(callback, data);
		}, Meter.API_ENDPOINT + "allActive/" + meterPointId);
	}
	
	static handleListResult(callback, data) {
		let arrayLength = data.length;
		let meters = [];
		for (let i = 0; i < arrayLength; i++) {
			let meter = new Meter(data[i]);
			meters.push(meter);
			Meter.cachedLoadChain.cache[meter.id] = meter;
		}
		callback(meters);
	}

	remove(callback){
		Objects.remove(callback, Meter.API_ENDPOINT + this.id);
	}
	
	upsert(callback) {
		let scope = this;
		Objects.post(this, function(meter){
			meter && Object.assign(scope, meter);
			callback(scope);
		}, Meter.API_ENDPOINT + "upsert");
	}
	
	getMeterPoint(callBack) {
		if (this.meterPoint != null) {
			if (!(this.meterPoint instanceof MeterPoint)) {
				this.meterPoint = new MeterPoint(this.meterPoint);
			}
			callBack(this.meterPoint);
			return;
		}
		if (this.messpunktID == null || this.messpunktID < 1) {
			callBack(null);
			return;
		}
		let scope = this;
		MeterPoint.load(this.messpunktID, function(meterPoint) {
			scope.meterPoint = meterPoint;
			callBack(meterPoint);
		});
	}
	
	getStandort(callBack) {
		this.getMeterPoint(function(meterPoint){
			if (meterPoint == null) {
				callback(null);
				return;
			}
			meterPoint.getStandort(callBack);
		});
	}
	
	getMediumUnit(callBack) {
		if (this.mediumUnit != null && this.mediumUnit.id == this.einheitMediumID) {
			if (!(this.mediumUnit instanceof MediumUnit)) {
				this.mediumUnit = new MediumUnit(this.mediumUnit);
			}
			callBack(this.mediumUnit);
			return;
		}
		if (this.einheitMediumID == null || this.einheitMediumID < 1) {
			callBack(null);
			return;
		}
		let scope = this;
		MediumUnit.load(this.einheitMediumID, function(mediumUnit) {
			scope.mediumUnit = mediumUnit;
			callBack(mediumUnit);
		});
	}
	
	getNewestReading(callBack) {
		if (this.newestReading != null) {
			if (!(this.newestReading instanceof Reading)) {
				this.newestReading = new Reading(this.newestReading);
			}
			callBack(this.newestReading);
			return;
		}
		let scope = this;
		Reading.loadNewest(this.id, function(reading){
			scope.newestReading = reading;
			callBack(scope.newestReading);
		});
	}
	
	getURL() {
		return Objects.rootURL + "/Frontend/Zaehler/page.jsp?zaehlerID="+this.id;
	}
	
	getLink() {
		let link = new Link({
			href: this.getURL(),
			text: this.nr
		});
		return link;
	}
}
Meter.API_ENDPOINT = '/api/Zaehler/';
Meter.OBJECT_DESCRIPTION_ID = 19;
Meter.METER_POINT_FIELD_DESCRIPTION_ID = 31;
Meter.METER_NUMBER_FIELD_DESCRIPTION_ID = 32;
Meter.METER_FACTOR_FIELD_DESCRIPTION_ID = 33;
Meter.READ_FIELD_DESCRIPTION_ID = 34;
Meter.UNIT_FIELD_DESCRIPTION_ID = 35;
Meter.STANDORT_FIELD_DESCRIPTION_ID = MeterPoint.STANDORT_FIELD_DESCRIPTION_ID;

Meter.cachedLoadChain = new CachedLoadChain(Meter.API_ENDPOINT);

export default Meter;