import SortableListBoxGroup from "../../table/SortableListBoxGroup.js";
import Goal from "../../../objects/Goal.js";
import LabeledTableCell from "../../table/LabeledTableCell.js";
import LabeledTable from "../../table/LabeledTable.js";
import EditGoalOverlay from "./EditGoalOverlay.js";
import Objects from "../../../objects/Objects.js";
import EVAEvent from "../../EVAEvent.js";
import Button from "../../Button.js";
import EditGoalProgressOverlay from "./EditGoalProgressOverlay.js";
import GoalDetailBox from "./GoalDetailBox.js";
import HistoryList from "../history/HistoryList.js";

class GoalList extends SortableListBoxGroup{

	constructor(config) {
		super({
			isDivTable: true
		});
		this.objectTypeId = config.objectTypeId;
		this.objectId = config.objectId;
		
		this.contentHeadline.setHeadlineById(Goal.OBJECT_DESCRIPTION_ID);
		
		let scope = this;
		this.contentHeadline.getNewButton().click(function(event) {
			new EditGoalOverlay({
				eventCallback: function(event){
					if (event.type == EVAEvent.TYPE_SAVE) {
						scope.pushRow(event.data, true);
					}
				},
				objectTypeId: scope.objectTypeId,
				objectId: scope.objectId
			});
		}, {});
		
		this.downloadButton = Button.createDownloadCsvButton();
		this.contentHeadline.append(this.downloadButton);
		this.downloadButton.click(function(event) {
			window.location.assign(Goal.API_ENDPOINT + scope.objectTypeId + '/goals' + scope.objectId + '.csv');
		});


		this.load();
	}
	
	load() {
		let scope = this;
		Goal.loadAll(this.objectTypeId, this.objectId, function(goalList){
					scope.rowDatas.pushAll(goalList);
				}
			);
	}
	
	renderHead() {
		//no head;
	}
	
	renderRow(entry){
		let contentBox = this.table.newRow();
		let editButton = Button.createEditButton();
		contentBox.append(editButton);
		let scope = this;
		editButton.click(function(event){
			new EditGoalOverlay({
				eventCallback: function(event){
					if (event.type == EVAEvent.TYPE_SAVE) {
						scope.rerender();
					}
				},
				objectTypeId: scope.objectTypeId,
				objectId: scope.objectId,
				goal: entry
			});
		}, {});
		let historyButton = Button.createHistoryButton();
		contentBox.append(historyButton);
		historyButton.click(function(event){
			let historyList = new HistoryList({
				objectTypeId: Goal.OBJECT_DESCRIPTION_ID,
				objectId: entry.id,
				elementRenderer: function(goal) {
					return new GoalDetailBox({goal:goal})
				}
			});
		}, {});

		let goalDetailBox = new GoalDetailBox({
			goal: entry,
			progessEditable: true
		});
		contentBox.append(goalDetailBox);
	}
}
export default GoalList;