import LabeledTableCell from "./LabeledTableCell.js";
import User from "../../objects/User.js";
import NumberTableCell from "./NumberTableCell.js";

class LabeledNumberTableCell extends LabeledTableCell{
	constructor(config) {
		if (config == null) {
			config = new Object();
		}
		config.contentCell = new NumberTableCell(config);
		super(config);
	}
	
	setUnit(unit){
		this.getContentCell().setUnit(unit);
		return this;
	}
	
	setNumber(number){
		this.getContentCell().setNumber(number);
		return this;
	}
}
export default LabeledNumberTableCell;