import UI from "../../../defaultUi.js";
import Translation from "../../../../objects/Translation.js";

class YearlyPlot extends UI{
	constructor(config){
		super(config);
		this.isReady = false;
		this.plotId = config.typ + 'newplot';
		let plotDiv = $('<div id="' + this.plotId + '" class="jqplot-target" style="height: 400px; width: 1100px; position: relative;"></div>');
		super.setBaseJQueryObject(plotDiv);
		let scope = this;
		if (config.typ == YearlyPlot.GRAPH_TYP_ELECTRICITY) {
			Translation.load('Strom',function(translElec){
				Translation.load('Verbrauch',function(translUsage){
					Translation.load('Bemessungsgroesse',function(translAna){
						let plotTitel = translElec.text + ' (kWh)';
						let labelCons = translUsage.text + ' (kWh)';
						let lableAna = translAna.text + ' (kWh)';
						let colors = [ "#ffff00", "#000000"];
						scope.jqpDefaultSetting = scope.getSetting(plotTitel, labelCons, lableAna, colors);
						var graphData = config.standort.getYearlyElectricityConsumption(function(graphData){
							scope.graphData = graphData;
							if (scope.isReady) {
								scope.render();
							}
						});
					});
				});
			});
		} else if (config.typ == YearlyPlot.GRAPH_TYP_HEAT){
			Translation.load('Waerme',function(translElec){
				Translation.load('Verbrauch bereinigt',function(translUsage){
					Translation.load('Vorgabe bereinigt',function(translAna){
						let plotTitel = translElec.text + ' (kWh)';
						let labelCons = translUsage.text + ' (kWh)';
						let lableAna = translAna.text + ' (kWh)';
						let colors = [ "#ff0000", "#000000"];
						scope.jqpDefaultSetting = scope.getSetting(plotTitel, labelCons, lableAna, colors);
						var graphData = config.standort.getYearlyHeatConsumption(function(graphData){
							scope.graphData = graphData;
							if (scope.isReady) {
								scope.render();
							}
						});
					});
				});
			});
		} else if (config.typ == YearlyPlot.GRAPH_TYP_WATER){
			Translation.load('Wasser',function(translElec){
				Translation.load('Verbrauch',function(translUsage){
					Translation.load('Bemessungsgroesse',function(translAna){
						let plotTitel = translElec.text + ' (m&sup3;)';
						let labelCons = translUsage.text + ' (m&sup3;)';
						let lableAna = translAna.text + ' (m&sup3;)';
						let colors = [ "#0000ff", "#000000"];
						scope.jqpDefaultSetting = scope.getSetting(plotTitel, labelCons, lableAna, colors);
						var graphData = config.standort.getYearlyWaterConsumption(function(graphData){
							scope.graphData = graphData;
							if (scope.isReady) {
								scope.render();
							}
						});
					});
				});
			});
		}
		this.isReady = true;
	}
	
	render() {
		if (this.isReady && this.graphData != null && $('#' + this.plotId).length > 0) {
			var plot = $.jqplot(this.plotId, this.graphData, this.jqpDefaultSetting);
		}
	}
	
	getSetting(title, labelCons, lableAna, colors){
		let seriesLegend = [
	            {
	            	label:labelCons,
	            	renderer:$.jqplot.BarRenderer,
	            	rendererOptions: {
	            		fillToZero: true,
	            		highlightMouseDown: true
	            		}
	            },
    			{
	            	label:lableAna,
	            	rendererOptions: {
	            		fillToZero: true
	            		},
	            	yaxis:'yaxis'
	            }
	        ];
		let jqpDefaultSetting = new App.JqpDefaultSetting(title,seriesLegend);
		jqpDefaultSetting.seriesColors = colors;
		jqpDefaultSetting.seriesDefaults={};
		return jqpDefaultSetting;
	}
}
YearlyPlot.GRAPH_TYP_ELECTRICITY = 'electricity';
YearlyPlot.GRAPH_TYP_HEAT = 'heat';
YearlyPlot.GRAPH_TYP_WATER = 'water';
export default YearlyPlot;