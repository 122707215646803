import UI from "../defaultUi.js";
import User from "../../objects/User.js";

class NumberInput extends UI{
	constructor(config){
		super(config);
		this.digits = 2;
		if (config.digits) {
			this.digits = config.digits;
		}
		let step = 1 / Math.pow(10, this.digits);
		this.numberInput = $('<input type="number" class="number" step="' + step + '"/>');
		super.setBaseJQueryObject(this.numberInput);
		if (config) {
			if (config.id) {
				this.numberInput.attr('id', config.id);
			}
		}
		this.initNumberConversion();
	}
	
	initNumberConversion(){
		if (!Modernizr.inputtypes.number) {
			if(NumberInput.foatParser){
				return;
			}
			let scope = this;
			User.getCurrentUser(function(user){
				user.getFoatParser(function(foatParser){
					NumberInput.foatParser = foatParser;
				});
			});
		}
	}
	
	setFieldError(){
		this.numberInput.addClass('RequiredFieldNotSet');
	}
	
	removeFieldError(){
		this.numberInput.removeClass('RequiredFieldNotSet');
	}
	
	uiInit() {
	}

	val(newVal) {
		if (newVal) {
			if (!Modernizr.inputtypes.number) {
				let scope = this;
				User.getCurrentUser(function(user){
					user.getNumberformat(function(formater){
						scope.numberInput.val(formater.format(newVal));
					}, scope.digits);
				});
				return;
			}
			return this.numberInput.val(newVal);
		}
		if (!Modernizr.inputtypes.number) {
			return NumberInput.foatParser(this.numberInput.val());
		}
		return this.numberInput.val();
	}
}
export default NumberInput;