import UI from "../defaultUi.js";

class DivContentListRow extends UI{
	constructor(){
		super();
		this.rowDiv = $('<div class="content_list_box"></div>');
		super.setBaseJQueryObject(this.rowDiv);
	}

	append(toAppend) {
		if (toAppend instanceof UI) {
			this.rowDiv.append(toAppend.getJQueryRepresentation());
		} else {
			this.rowDiv.append(toAppend);
		}
	}
}
export default DivContentListRow;