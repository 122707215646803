import UI from "../defaultUi.js";
import TableCell from "./TableCell.js";

class DateTableCell extends TableCell{
	constructor(config) {
		if (config == null) {
			config = new Object();
		}
		config.type = TableCell.TYPE_DATE;
		super(config);

		this.setDate(config.date);
	}
	
	setDate(date) {
		if (date) {
			let dateObj = new Date(date);
			this.getContentCell().text(dateObj.toLocaleDateString() + ' ' + dateObj.toLocaleTimeString());
		} else {
			this.getContentCell().text("--");
		}
	}
}
export default DateTableCell;