import Translation from "../objects/Translation.js";

class DynamicText {
	constructor(config) {
		this.replacements = {};
		if (config) {
			if (config.text) {
				this.setText(config.text);
			}
			if (config.translationKey) {
				let scope = this;
				Translation.load(config.translationKey, function(translation){
					scope.setText(translation.text);
				});
			}
		}
	}
	
	setText(text) {
		this.text = text;
	}
	
	replaceAll(map) {
		for (let key in map) {
			this.replacements[key] = map[key];
		}
	}
	
	replace(varriable, value) {
		this.replacements[varriable] = value;
	}
	
	getText() {
		let resultText = this.text;
		if (resultText == null) {
			return null;
		}
		for (let key in this.replacements) {
			let reg = new RegExp(DynamicText.VAR_START + key + DynamicText.VAR_END, "g");
			resultText = resultText.replace(reg, this.replacements[key]);
		}
		return resultText;
	}
}
DynamicText.VAR_START = '<!"';
DynamicText.VAR_END = '"!>';
export default DynamicText;