import Objects from "./Objects.js";
import CachedLoadChain from "../misc/CachedLoadChain.js";

class CorrectionValueCategory {

	constructor(obj) {
		obj && Object.assign(this, obj);
	}
	
	static load(id, callback){
		CorrectionValueCategory.cachedLoadChain.load(id, function(data){
			callback(new CorrectionValueCategory(data));
		});
	}
	
	static loadAll(balaceGroupId, callback) {
		if (!balaceGroupId) {
			return [];
		}
		if (CorrectionValueCategory.byBalaceGroup[balaceGroupId] != null) {
			callback(CorrectionValueCategory.byBalaceGroup[balaceGroupId]);
			return;
		}
		Objects.load(function(data){
			let arrayLength = data.length;
			let correctionValueCategories = [];
			for (let i = 0; i < arrayLength; i++) {
				let correctionValueCategory = new CorrectionValueCategory(data[i]);
				correctionValueCategories.push(correctionValueCategory);
				CorrectionValueCategory.cachedLoadChain.cache[correctionValueCategory.id] = correctionValueCategory;
			}
			CorrectionValueCategory.byBalaceGroup[balaceGroupId] = correctionValueCategories;
			callback(correctionValueCategories);
		}, CorrectionValueCategory.API_ENDPOINT + 'all?balaceGroupId=' + balaceGroupId);
		
	}
	
}
CorrectionValueCategory.byBalaceGroup = {};
CorrectionValueCategory.API_ENDPOINT = "/api/CorrectionValueCategory/";
CorrectionValueCategory.cachedLoadChain = new CachedLoadChain(CorrectionValueCategory.API_ENDPOINT);
export default CorrectionValueCategory;