import UI from "../defaultUi.js";
import OverlayBox from "../OverlayBox.js";
import Translation from "../../objects/Translation.js";

class UploadingOverlay extends UI{
	constructor(config) {
		super(config);
		this.overlayBox = new OverlayBox({});
		super.setBaseJQueryObject(this.overlayBox);
		let spinner = $('<div class="waitSpinner"></div>');
		this.overlayBox.append(spinner);
		let text = $('<div class="centered">uploading</div>');
		Translation.load("uploading", function(translation){
			text.text(translation.text);
		});
		this.overlayBox.append(text);
	}
}
export default UploadingOverlay;