import DetailTable from "../../table/DetailTable.js";
import Persons from "../../../objects/Persons.js";

class PersonsDetailTable extends DetailTable{
	constructor(config) {
		super(config);
		if (config != null) {
			if (config.persons != null) {
				this.setPersons(config.persons);
			}
		}
	}
	
	setPersons(persons) {
		this.persons = persons;
		this.addDate(Persons.FIELD_DATE_ID, this.persons.datum);
		this.addTextBig(Persons.FIELD_COUNT_ID, this.persons.anzahl);
	}
	
}
export default PersonsDetailTable;