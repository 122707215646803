import DetailTable from "../../table/DetailTable.js";
import User from "../../../objects/User.js";
import Language from "../../../objects/Language.js";

class UserDetailTable extends DetailTable{
	constructor(config) {
		super(config);
		if (config != null) {
			if (config.user != null) {
				this.setUser(config.user);
			}
		}
	}
	
	setUser(user) {
		this.user = user;
		this.addText(User.USER_NAME_FIELD_DESCRIPTION_ID, user.username);
		this.addDateTime(User.LAST_LOGIN_FIELD_DESCRIPTION_ID, user.lastlogin);
		this.addEmailAddress(User.EMAIL_FIELD_DESCRIPTION_ID, user.email);
		let languageCell = this.addFieldCell(User.LANGUAGE_FIELD_DESCRIPTION_ID);
		Language.load(user.sprache_id, function(language){
			languageCell.text(language.name);
		})
		this.addText(User.FIRST_NAME_FIELD_DESCRIPTION_ID, user.firstName);
		this.addText(User.LAST_NAME_FIELD_DESCRIPTION_ID, user.lastName);
		this.addBoolean(User.NEWSLETTER_FDI, user.newsLetter);
	}
	
}
export default UserDetailTable