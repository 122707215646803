import DetailTable from "../../table/DetailTable.js";
import File from "../../../objects/File.js";

class FileDetailTable extends DetailTable {
	constructor(config){
		super(config);
		if (config != null) {
			if (config.file != null) {
				this.setFile(config.file);
			}
		}
	}
	
	setFile(file) {
		if (!(file instanceof File)) {
			file = new File(file);
		}
		this.addText(File.FILE_FIELD_DESCRIPTION_ID, file.name);
		this.addDate(File.UPLOADED_FDI, file.uploaded);
		this.addFileSize(File.SIZE_FDI, file.size);
		
	}
}
export default FileDetailTable;