import MeterPoint from "../../../objects/MeterPoint.js";
import SortableListBoxGroup from "../../table/SortableListBoxGroup.js";
import Objects from "../../../objects/Objects.js";
import FloorAreaMeterPointJunction from "../../../objects/FloorAreaMeterPointJunction.js";

class ListByFloorArea extends SortableListBoxGroup {

	constructor(config){
		super(config);
		let scope = this;
		if (config) {if (config.floorArea) {
				this.setFloorArea(config.floorArea);
			}
		}
	}
	
	setFloorArea(floorArea) {
		this.contentHeadline.setHeadlineById(MeterPoint.DESCRIPTON_ID);
		this.floorArea = floorArea;
		if (this.floorArea == null) {
			return;
		}
		let scope = this;
		FloorAreaMeterPointJunction.allByFloorArea(this.floorArea.id, function(floorAreaMeterPointJunctions) {
			scope.pushAll(floorAreaMeterPointJunctions);
		});

		this.contentHeadline.getNewButton().click(function(event) {
			window.location.href = Objects.rootURL + "/Frontend/FlaecheMesspunkt/create/page.jsp?flaecheId=" + scope.floorArea.id;
		}, {});
	}
	
	renderHead() {
		this.addSortHeaderByFDI(MeterPoint.NAME_FIELD_DESCRIPTION_ID, 'meterPoint.name');
		this.addSortHeaderByFDI(MeterPoint.MEDIUM_FIELD_DESCRIPTION_ID, 'meterPoint.medium.name');
		this.addSortHeaderByFDI(MeterPoint.ADJUSTING_TYPE_FIELD_DESCRIPTION_ID, 'meterPoint.bereinigungsart.name');
		this.addSortHeaderByFDI(MeterPoint.CONTRACT_FIELD_DESCRIPTION_ID, 'meterPoint.vertrag');
		this.addSortHeaderByFDI(MeterPoint.START_DATE_FIELD_DESCRIPTION_ID, 'meterPoint.beginn');
		this.addSortHeaderByFDI(MeterPoint.END_DATE_FIELD_DESCRIPTION_ID, 'meterPoint.ende');
	}

	renderRow(floorAreaMeterPointJunction, row) {
		let scope = this;
		floorAreaMeterPointJunction.getMeterPoint(function(meterPoint){
			let link = meterPoint.getLink();
			link.href(floorAreaMeterPointJunction.getUrl());
			row.addCellDomObj(link);
			meterPoint.getMedium(function(medium){
				row.addCell(medium.name);
			});
			meterPoint.getAdjustingType(function(adjustingType){
				row.addCell(adjustingType.name);
			});
			row.addText(meterPoint.vertrag);
			row.addDate(meterPoint.beginn);
			row.addDate(meterPoint.ende);
		});
	}
}
export default ListByFloorArea;