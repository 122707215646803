import UiElement from "../../UiElement.js";
import ContentBoxGroup from "../../content/ContentBoxGroup.js";
import ShowDetailsHeader from "../../content/ShowDetailsHeader.js";
import ContentBox from "../../ContentBox.js";
import FloorArea from "../../../objects/FloorArea.js";
import FloorAreaDetailTable from "./FloorAreaDetailTable.js";
import EditFloorArea from "./EditFloorArea.js";
import Translation from "../../../objects/Translation.js";

class FloorAreaDetailBox extends UiElement{
	constructor(config) {
		super(config);
		this.contentBoxGroup = new ContentBoxGroup();
		super.setBaseJQueryObject(this.contentBoxGroup);
		this.header = new ShowDetailsHeader({
			descriptonId: FloorArea.OBJECT_DESCRIPTION_ID
		});
		this.contentBoxGroup.append(this.header);
		this.contentBox = new ContentBox();
		this.contentBoxGroup.append(this.contentBox);
		let scope = this;
		this.header.getEditButton().click(function(){
			new EditFloorArea({
				floorArea: scope.floorArea,
				eventCallback: function(event) {
					if (event.isSaved()) {
						scope.setFloorArea(event.data);
					}
				}
			});
			});
		Translation.load("Flaeche wirklich loeschen?", function(translation){
			scope.confirmDeleteText = translation.text;
		});
		this.header.getDeleteButton().click(function(){
			let r = confirm(scope.confirmDeleteText);
			if (r == true) {
				scope.floorArea.remove(function(data){
					if(data.success === true){
						window.location.href = document.referrer;
					}else{
						cleanError();
						addToError(data.statusMessage);
					}
				});
			}
		});
		if (config != null) {
			if (config.floorArea != null) {
				this.setFloorArea(config.floorArea);
			}
		}
	}
	
	setFloorArea(floorArea) {
		if (this.floorAreaDetail) {
			this.floorAreaDetail.remove();
		}
		if (floorArea == null) {
			this.floorArea = null;
			return;
		}
		this.floorArea = floorArea;
		this.floorAreaDetail = new FloorAreaDetailTable({
			floorArea: floorArea
		});
		this.contentBox.append(this.floorAreaDetail);
	}
}
export default FloorAreaDetailBox;