import SortableListBoxGroup from "../../table/SortableListBoxGroup.js";
import AddressBookEntry from "../../../objects/AddressBookEntry.js";
import Translation from "../../../objects/Translation.js";
import Button from "../../Button.js";
import EditAddressBookEntryOverlay from "./EditAddressBookEntryOverlay.js";

class LicenseAddressBookEntriesList extends SortableListBoxGroup {
	constructor(config) {
		super(config);
		let scope = this;
		this.contentHeadline.setHeadlineById(AddressBookEntry.OBJECT_DESCRIPTION_ID);
		Translation.load("AddressBookEntry wirklich loeschen?", function(translation){
			scope.confirmDeleteText = translation.text;
		});
		this.contentHeadline.getNewButton().click(function(event) {
			new EditAddressBookEntryOverlay({
				eventCallback:function(event){
					scope.reload();
					}
			});
		});
		if (config) {
			if (config.license) {
				this.setLicense(config.license);
			}
		}
	}
	
	setLicense(license){
		this.license = license;
		this.reload();
	}
	
	reload() {
		this.rowDatas.clear();
		let scope = this;
		AddressBookEntry.loadAllByLicense(this.license, function (abEntries) {
			scope.pushAll(abEntries);
		});
	}
	
	renderHead() {
		this.table.headerAppendLable(AddressBookEntry.JOB_FIELD_DESCRIPTION_ID);
		this.table.headerAppendLable(AddressBookEntry.LAST_NAME_FIELD_DESCRIPTION_ID);
		this.table.headerAppendLable(AddressBookEntry.FIRST_NAME_FIELD_DESCRIPTION_ID);
		this.table.headerAppendLable(AddressBookEntry.PHONE_NUMBER_FIELD_DESCRIPTION_ID);
		this.table.headerAppendLable(AddressBookEntry.EMAIL_FIELD_DESCRIPTION_ID);
	}
	
	renderRow(entry, row) {
		row.addCell(entry.job);
		let lastNameA = $('<a/>');
		let scope = this;
		lastNameA.click(function(event) {
				event.preventDefault();
				new ShowAddressBookEntryOverlay({
					addressBookEntry: entry,
					eventCallback: function(event) {
						scope.rerender();
					}
				});
				return false; 
			});
		lastNameA.text(entry.lastName);
		row.addCellDomObj(lastNameA);
		row.addCell(entry.firstName);
		row.addPhoneNumberTableCell(entry.phoneNumber);
		row.addEmailAddress(entry.email);
		let deletButton = Button.createDeleteButton();
		deletButton.click(function(event) {
			let r = confirm(scope.confirmDeleteText);
			if (r == true) {
				AddressBookEntry.remove(entry.id, function(data){
					scope.reload();
				});
			}
			});
		row.addCellDomObj(deletButton);
	}
}
export default LicenseAddressBookEntriesList;