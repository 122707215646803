import UI from "../../defaultUi.js";
import OverlayBox from "../../OverlayBox.js";
import LabeledTable from "../../table/LabeledTable.js";
import LabeledTableDateInputCell from "../../table/LabeledTableDateInputCell.js";
import LabeledTableInputCell from "../../table/LabeledTableInputCell.js";
import LabeledTableCell from "../../table/LabeledTableCell.js"; 
import Button from "../../Button.js";
import ContentBox from "../../ContentBox.js";

class CalculateDegreeDaysOverlay extends UI{
	
	constructor(config){
		super(config);
		this.wetherStation = config.weatherStation;
		
		this.overlayBox = new OverlayBox({isCloseable: true});
		this.contentBox = new ContentBox();
		this.overlayBox.append(this.contentBox);
		let table = new LabeledTable();
		this.contentBox.append(table);
		this.startInput = new LabeledTableDateInputCell({
			labelText: "Start"
		});
		table.append(this.startInput);
		this.endInput = new LabeledTableDateInputCell({
			labelText: "End"
		});
		table.append(this.endInput);
		this.temperaturInput = new LabeledTableInputCell({
			labelText: "Temperatur"
		});
		table.append(this.temperaturInput);
		this.resultCell = new LabeledTableCell({
			labelText: "Gradtage"
		});
		table.append(this.resultCell);
		
		this.footer = this.overlayBox.getFooter();
		
		this.calculateButton = new Button({
			buttonText: "calculate"
		});
		this.footer.append(this.calculateButton);
		var buttonScope = this;
		this.calculateButton.click(function(event){
			event.data.scope.runCalculation();
		},{
			scope: buttonScope
		});
	}
	
	runCalculation() {
		let start = this.startInput.getVal();
		let end = this.endInput.getVal();
		let temperatur = this.temperaturInput.getVal();
		var scope = this;
		this.wetherStation.getDegreeDays(start, end, temperatur, function(degriDays){
			scope.resultCell.getContentCell().text(degriDays);
		});
	}
	
}
export default CalculateDegreeDaysOverlay;