import Objects from "./Objects.js";
import CachedLoadChain from "../misc/CachedLoadChain.js";
class AdjustingType {
	constructor(obj) {
		obj && Object.assign(this, obj);
	}
	
	static load(meterPointId, callback){
		AdjustingType.cachedLoadChain.load(meterPointId, function(data){
			callback(new AdjustingType(data));
		});
	}
	
	static loadAll(callback){
		let scope = this;
		Objects.load(function(data){
			let arrayLength = data.length;
			let adjustingTypes = [];
			for (let i = 0; i < arrayLength; i++) {
				let adjustingType = new AdjustingType(data[i]);
				adjustingTypes.push(adjustingType);
				AdjustingType.cachedLoadChain.cache[adjustingType.id] = adjustingType;
			}
			callback(adjustingTypes);
		}, AdjustingType.API_ENDPOINT + "all");
	}

	
	isAdjusting(){
		if (this.grenzTemperatur) {
			return this.grenzTemperatur > -99.0;
		}
		return false;
	}
}
AdjustingType.API_ENDPOINT = '/api/Bereinigungsart/';
AdjustingType.cachedLoadChain = new CachedLoadChain(AdjustingType.API_ENDPOINT);

export default AdjustingType;