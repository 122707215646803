import UI from "../../defaultUi.js";
import UserDetailBox from "./UserDetailBox.js";
import Div from "../../Div.js";
import User from "../../../objects/User.js";

class ShowUser extends UI {
	constructor(config) {
		super(config);
		this.contentDiv = new Div();
		super.setBaseJQueryObject(this.contentDiv);
		if (config != null) {
			if (config.user != null) {
				this.setUser(config.user);
			} else if (config.userId != null) {
				let scope = this;
				User.load(config.userId, function(user){
					scope.setUser(user);
				});
			}
		}
	}
	
	setUser(user) {
		this.contentDiv.clear();
		this.userDetail = new UserDetailBox({
			user: user
		});
		this.contentDiv.append(this.userDetail);
	}
}
export default ShowUser;