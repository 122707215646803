import Ui from "../../defaultUi.js";
import CalculateDegreeDaysOverlay from "./CalculateDegreeDaysOverlay.js";
import Button from "../../Button.js";

class CalculateDegreeDaysButton extends Ui{
	constructor(config) {
		super(config);
		this.weatherStation = config.weatherStation;
		this.calculateButton = new Button({
			buttonText: "Gradtage"
		});
		super.setBaseJQueryObject(this.calculateButton);
		
		let scope = this;
		this.calculateButton.click(function(event){
			let calculateDegreeDaysOverlay = new CalculateDegreeDaysOverlay({weatherStation: scope.weatherStation});
		},{});
	}
}
export default CalculateDegreeDaysButton;