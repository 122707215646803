import UI from "../../../defaultUi.js";
import Translation from "../../../../objects/Translation.js";
import JqpDefaultSetting from "../../../plot/DefaultSetting.js";
import BalanceGroup from "../../../../objects/BalanceGroup.js";

class YearlyPlot extends UI{
	constructor(config){
		super(config);
		this.isReady = false;
		this.plotId = config.typ + 'newplot';
		let plotDiv = $('<div id="' + this.plotId + '" class="jqplot-target" style="height: 400px; width: 1100px; position: relative;"></div>');
		super.setBaseJQueryObject(plotDiv);
		let scope = this;
		config.meterPoint.getBalanceGroup(function(balanceGroup){
			if (BalanceGroup.ELECTRICITY_ID == balanceGroup.id) {
				Translation.load('Strom',function(translElec){
					Translation.load('Verbrauch',function(translUsage){
						Translation.load('Verbrauch (UZ)',function(translUsageSM){
							Translation.load('Bemessungsgroesse',function(translAna){
								let plotTitel = translElec.text + ' (kWh)';
								let labelCons = translUsage.text + ' (kWh)';
								let labelConsSM = translUsageSM.text + ' (kWh)';
								let lableAna = translAna.text + ' (kWh)';
								let colors = [ "#ffff00", "#ffffaa", "#000000"];
								scope.jqpDefaultSetting = scope.getSetting(plotTitel, labelCons, labelConsSM, lableAna, colors);
							});
						});
					});
				});
			} else if (BalanceGroup.HEAT_ID == balanceGroup.id){
				Translation.load('Waerme',function(translElec){
					Translation.load('Verbrauch bereinigt',function(translUsage){
						Translation.load('Verbrauch (UZ) bereinigt',function(translUsageSM){
							Translation.load('Vorgabe bereinigt',function(translAna){
								let plotTitel = translElec.text + ' (kWh)';
								let labelCons = translUsage.text + ' (kWh)';
								let labelConsSM = translUsageSM.text + ' (kWh)';
								let lableAna = translAna.text + ' (kWh)';
								let colors = [ "#ff0000", "#ffbbbb", "#000000"];
								scope.jqpDefaultSetting = scope.getSetting(plotTitel, labelCons, labelConsSM, lableAna, colors);
							});
						});
					});
				});
			} else if (BalanceGroup.WATER_ID == balanceGroup.id){
				Translation.load('Wasser',function(translElec){
					Translation.load('Verbrauch',function(translUsage){
						Translation.load('Verbrauch (UZ)',function(translUsageSM){
							Translation.load('Bemessungsgroesse',function(translAna){
								let plotTitel = translElec.text + ' (m&sup3;)';
								let labelCons = translUsage.text + ' (m&sup3;)';
								let labelConsSM = translUsageSM.text + ' (m&sup3;)';
								let lableAna = translAna.text + ' (m&sup3;)';
								let colors = [ "#0000ff", "#bbbbff", "#000000"];
								scope.jqpDefaultSetting = scope.getSetting(plotTitel, labelCons, labelConsSM, lableAna, colors);
							});
						});
					});
				});
			}
		});
		config.meterPoint.getYearlyConsumption(function(graphData){
			scope.graphData = graphData;
			if (scope.isReady) {
				scope.render();
			}
		});
		this.isReady = true;
	}
	
	render() {
		if (this.isReady && this.graphData != null && $('#' + this.plotId).length > 0) {
			var plot = $.jqplot(this.plotId, this.graphData, this.jqpDefaultSetting);
			plot.replot();
		}
	}
	
	getSetting(title, labelCons, labelConsSM, lableAna, colors){
		let seriesLegend = [
	            {
	            	label:labelCons,
	            	renderer:$.jqplot.BarRenderer,
	            	rendererOptions: {
	            		highlightMouseDown: true
	            		}
	            },
	            {
	            	label:labelConsSM,
	            	renderer:$.jqplot.BarRenderer,
	            	rendererOptions: {
	            		highlightMouseDown: true
	            		}
	            },
    			{
	            	label:lableAna,
	            	disableStack: true,
	            	rendererOptions: {
	            		fillToZero: true
	            		},
	            	yaxis:'yaxis'
	            }
	        ];
		let jqpDefaultSetting = new App.JqpDefaultSetting(title,seriesLegend);
		jqpDefaultSetting.seriesColors = colors;
        jqpDefaultSetting.stackSeries = true;
		jqpDefaultSetting.seriesDefaults={};
		return jqpDefaultSetting;
	}
}
export default YearlyPlot;