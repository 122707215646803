import DetailTable from "../../table/DetailTable.js";
import Consumption from "../../../objects/Consumption.js";

class ConsumptionDetailTable extends DetailTable{
	constructor(config){
		super(config);
		if (config != null) {
			if (config.consumption != null) {
				this.setConsumption(config.consumption);
			}
		}
	}
	
	setConsumption(consumption) {
		if (!(consumption instanceof Consumption)) {
			consumption = new Consumption(consumption);
		}

		this.addDate(Consumption.FROM_FDI, consumption.von);
		this.addDate(Consumption.TILL_FDI, consumption.bis);
		let ammountValueCell = this.addNumber(Consumption.AMOUNT_FDI, consumption.menge, null);
		consumption.getUnit(function(mediumUnit){
			ammountValueCell.setUnit(mediumUnit.name);
		});
		this.addTextBig(Consumption.COMMENT_FDI, consumption.comment);
		
	}
}
export default ConsumptionDetailTable;