import Objects from "./Objects.js";
import Label from "../ui/content/Label.js";
import LabeledTable from "../ui/table/LabeledTable.js";
import LabeledTableCell from "../ui/table/LabeledTableCell.js";

class Goal{
	static save(entry, callback){
		Objects.save(entry, callback, Goal.API_ENDPOINT + "insert");
	}
	
	static update(entry, callback){
		Objects.save(entry, callback, Goal.API_ENDPOINT + "update");
	};
	
	static upsert(entry, callback){
		if (entry.id != null) {
			Goal.update(entry, callback);
		} else {
			Goal.save(entry, callback);
		}
	}
	
	static updateProgress(entry, callback){
		Objects.save(entry, callback, Goal.API_ENDPOINT + "updateProgress");
	};
	
	static load(id, callback){
		Objects.load(callback, Goal.API_ENDPOINT + id);
	}
	
	static loadAll(objectTypeId, objectId, callback){
		Objects.load(callback, Goal.API_ENDPOINT + objectTypeId + "/" + objectId);
	}
	
	static createGoalsDiv(objectTypeId, objectId, divTofill) {
		divTofill.html("");
		var groupBox = $('<div class="content_box_group"></div>');
		divTofill.append(groupBox);
		
		let goalUI = new GoalUI({
			objectTypeId: objectTypeId,
			objectId: objectId,
		});
		groupBox.append(goalUI.getJQueryRepresentation());
		
		var contentBox = $('<div id="goalsList"></div>');
		groupBox.append(contentBox);
	}
	
	static showGoalList(event){
		var divTofill = $('#' + event.data.divId);
		divTofill.html('');
		Goal.loadAll(event.data.objectTypeId,event.data.objectId, function(goalList){
					var arrayLength = goalList.length;
					for (var i = 0; i < arrayLength; i++) {
						var goal = goalList[i];
						Goal.showGoal(goal, divTofill);
					}
				}
			);
	}
	
	static showGoal(goal, appendTo){
		var contentBox = $('<div class="content_list_box"></div>');
		appendTo.append(contentBox);
		let labeledTable = new LabeledTable();
		contentBox.append(labeledTable.getJQueryRepresentation());

		labeledTable.append(
				new LabeledTableCell({
					fieldId: Goal.NAME_FIELD_ID,
					contentText: goal.name
				}));
		let progessCell = new LabeledTableCell({
			fieldId: Goal.PROGRESS_FIELD_ID,
			contentText: (Math.round(goal.progress * 100) + '%')
		})
		labeledTable.append(progessCell);
		
		Objects.addEditButton(progessCell.getContentCell(), Goal.updateProgressOverlay, {progress: goal.progress, goalId: goal.id, divId: "goalsList"});

		let startCell = new LabeledTableCell({
			fieldId: Goal.START_FIELD_ID
		});
		labeledTable.append(startCell);

		if (goal.start) {
			startCell.getContentCell().text(new Date(goal.start).toLocaleDateString());
		} else {
			startCell.getContentCell().text('--');
		}
		
		let endCell = new LabeledTableCell({
			fieldId: Goal.END_FIELD_ID
		});
		labeledTable.append(endCell);

		if (goal.end) {
			endCell.getContentCell().text(new Date(goal.end).toLocaleDateString());
		} else {
			endCell.getContentCell().text('--');
		}
		
		var textDiv = $('<div style="white-space: pre-wrap;padding: 10px;"></div>');
		contentBox.append(textDiv);
		textDiv.text(goal.description);
		
		var bottonsDiv = $('<div class="buttons"></div>');
		contentBox.append(bottonsDiv);
	}
	
	static newGoalOverlay(event){

		var mainContent = $('.main_content');
		if(mainContent.length){
			var overlayBox = $('<div id="new_goal_overlay" class="boxOverlay"></div>');
			mainContent.prepend(overlayBox);
			var boxOverlayContent = $('<div class="boxOverlay-content content_box_group"></div>');
			overlayBox.append(boxOverlayContent);
			var contentBox = $('<div class="content_box"></div>');
			boxOverlayContent.append(contentBox);
			var headline = $('<form class="content_headline"></form>');
			contentBox.append(headline);
			ObjectTranslation.getObjectNameTranslation(Goal.OBJECT_DESCRIPTION_ID, function(objectNameTranslation){
						headline.text(objectNameTranslation.name);
					}
				);
			var contentBox = $('<div class="content_box"></div>');
			boxOverlayContent.append(contentBox);
			var editForm = $('<form id="goal_edit_form" action="" accept-charset="utf-8"></form>');
			contentBox.append(editForm);
			
			var tableDiv = $('<div class="inputtable"></div>');
			contentBox.append(tableDiv);
			
			var tableRow = $('<div class="showtable_row"></div>');
			tableDiv.append(tableRow);
			
			var tableCell = $('<div class="showtable_cell"></div>');
			tableRow.append(tableCell);
			var fieldId = 'name_input';
			let label = new Label({
				fieldId: Goal.NAME_FIELD_ID,
				forField: fieldId
			});
			tableCell.append(label.getJQueryRepresentation());
			
			tableCell = $('<div class="showtable_cell"></div>');
			tableRow.append(tableCell);
			var nameInputField = $('<input id="'+ fieldId +'"/>');
			tableCell.append(nameInputField);

			var tableRow = $('<div class="showtable_row"></div>');
			tableDiv.append(tableRow);
			
			tableCell = $('<div class="showtable_cell"></div>');
			tableRow.append(tableCell);
			fieldId = 'progress_input';
			label = new Label({
				fieldId: Goal.PROGRESS_FIELD_ID,
				forField: fieldId
			});
			tableCell.append(label.getJQueryRepresentation());
			
			tableCell = $('<div class="showtable_cell"></div>');
			tableRow.append(tableCell);
			var inputGroup = $('<div class="inputgroup"></div>');
			tableCell.append(inputGroup);
			var progressInputField = $('<input type="number" min="0" max="100" id="'+ fieldId +'" class="number" />');
			inputGroup.append(progressInputField);
			var inputUnit = $('<span class="inputgroupaddon" id="' + fieldId + '_unit">%</span>');
			inputGroup.append(inputUnit);
			var progressInputRangeField = $('<input type="range" min="0" max="100" id="'+ fieldId +'_range" class="number" />');
			tableCell.append(progressInputRangeField);
			progressInputField.change(function(){
				progressInputRangeField.val(progressInputField.val());
			});
			progressInputField.on('input', function(){
				progressInputRangeField.val(progressInputField.val());
			});
			progressInputRangeField.change(function(){
				progressInputField.val(progressInputRangeField.val());
			});
			progressInputRangeField.on('input', function(){
				progressInputField.val(progressInputRangeField.val());
			});

			var tableRow = $('<div class="showtable_row"></div>');
			tableDiv.append(tableRow);
			tableCell = $('<div class="showtable_cell"></div>');
			tableRow.append(tableCell);
			fieldId = 'start_input';
			label = new Label({
				fieldId: Goal.START_FIELD_ID,
				forField: fieldId
			});
			tableCell.append(label.getJQueryRepresentation());
			tableCell = $('<div id="'+ fieldId +'" class="showtable_cell"></div>');
			tableRow.append(tableCell);
			var startInputField = Objects.createDateInput(tableCell, fieldId);

			var tableRow = $('<div class="showtable_row"></div>');
			tableDiv.append(tableRow);
			tableCell = $('<div class="showtable_cell"></div>');
			tableRow.append(tableCell);
			fieldId = 'end_input';
			label = new Label({
				fieldId: Goal.END_FIELD_ID,
				forField: fieldId
			});
			tableCell.append(label.getJQueryRepresentation());
			tableCell = $('<div id="'+ fieldId +'" class="showtable_cell"></div>');
			tableRow.append(tableCell);
			var endInputField = Objects.createDateInput(tableCell, fieldId);
			

			var tableRow = $('<div class="showtable_row"></div>');
			tableDiv.append(tableRow);
			tableCell = $('<div class="showtable_cell"></div>');
			tableRow.append(tableCell);
			fieldId = 'description_input';
			label = new Label({
				fieldId: Goal.DESCRIPTION_FIELD_ID,
				forField: fieldId
			});
			tableCell.append(label.getJQueryRepresentation());
			tableCell = $('<div id="'+ fieldId +'" class="showtable_cell"></div>');
			tableRow.append(tableCell);
			var descriptionInputField = $('<textarea id="' + fieldId + '"></textarea>');
			tableCell.append(descriptionInputField);
			
			var saveConfig = new Object();
			saveConfig.name = nameInputField;
			saveConfig.progress = progressInputField;
			saveConfig.start = startInputField;
			saveConfig.end = endInputField;
			saveConfig.description = descriptionInputField;
			saveConfig.objectTypeId = event.data.objectTypeId;
			saveConfig.objectId = event.data.objectId;
			saveConfig.div = overlayBox;
			
			var bottonsDiv = $('<div class="buttons"></div>');
			contentBox.append(bottonsDiv);
			Objects.addSaveButton(bottonsDiv, function(event){
				var entry = new Object();
				entry.objectDescriptionId = event.data.objectTypeId;
				entry.objectId = event.data.objectId;
				entry.name = event.data.name.val();
				entry.progress = event.data.progress.val()/100;
				if (event.data.start.val() && event.data.start.val() != null){ 
					entry.start = new Date(event.data.start.val());
				}
				if (event.data.end.val() && event.data.end.val() != null) {
					entry.end = new Date(event.data.end.val());
				}
				entry.description = event.data.description.val();
				Goal.save(entry, function(result){
					var event = new Object();
					event.data = new Object();
					event.data.divId = "goalsList";
					event.data.objectTypeId = entry.objectDescriptionId;
					event.data.objectId = entry.objectId;
					Goal.showGoalList(event);
				});
				event.data.div.remove();
			}, saveConfig);
			
			Objects.addCancelButton(bottonsDiv, function(event){
				event.data.div.remove();
			}, {div: overlayBox});
		}else{
			console.log('main_contet not found');
			return;
		}
	}

	static updateProgressOverlay(event){

		var mainContent = $('.main_content');
		if(mainContent.length){
			var overlayBox = $('<div id="nupdate_progress_overlay" class="boxOverlay"></div>');
			mainContent.prepend(overlayBox);
			var boxOverlayContent = $('<div class="boxOverlay-content content_box_group"></div>');
			overlayBox.append(boxOverlayContent);
			var contentBox = $('<div class="content_box"></div>');
			boxOverlayContent.append(contentBox);
			var headline = $('<form class="content_headline"></form>');
			contentBox.append(headline);
			ObjectTranslation.getObjectNameTranslation(Goal.OBJECT_DESCRIPTION_ID, function(objectNameTranslation){
						headline.text(objectNameTranslation.name);
					}
				);
			var contentBox = $('<div class="content_box"></div>');
			boxOverlayContent.append(contentBox);
			var editForm = $('<form id="goal_edit_form" action="" accept-charset="utf-8"></form>');
			contentBox.append(editForm);
			
			var tableDiv = $('<div class="inputtable"></div>');
			contentBox.append(tableDiv);
			
			

			var tableRow = $('<div class="showtable_row"></div>');
			tableDiv.append(tableRow);
			
			var tableCell = $('<div class="showtable_cell"></div>');
			tableRow.append(tableCell);
			var fieldId = 'progress_input';
			var label = new Label({
				fieldId: Goal.PROGRESS_FIELD_ID,
				forField: fieldId
			});
			tableCell.append(label.getJQueryRepresentation());
			tableCell = $('<div class="showtable_cell"></div>');
			tableRow.append(tableCell);
			var inputGroup = $('<div class="inputgroup"></div>');
			tableCell.append(inputGroup);
			var progressInputField = $('<input type="number" min="0" max="100" id="'+ fieldId +'" class="number" />');
			inputGroup.append(progressInputField);
			var inputUnit = $('<span class="inputgroupaddon" id="' + fieldId + '_unit">%</span>');
			inputGroup.append(inputUnit);
			var progressInputRangeField = $('<input type="range" min="0" max="100" step="0.1" id="'+ fieldId +'_range" class="number" />');
			tableCell.append(progressInputRangeField);
			progressInputField.change(function(){
				progressInputRangeField.val(progressInputField.val());
			});
			progressInputField.on('input', function(){
				progressInputRangeField.val(progressInputField.val());
			});
			progressInputRangeField.change(function(){
				progressInputField.val(progressInputRangeField.val());
			});
			progressInputRangeField.on('input', function(){
				progressInputField.val(progressInputRangeField.val());
			});
			progressInputField.val(event.data.progress * 100);
			progressInputRangeField.val(event.data.progress * 100);
			
			var saveConfig = new Object();
			saveConfig.progress = progressInputField;
			saveConfig.id = event.data.goalId;
			saveConfig.div = overlayBox;
			
			var bottonsDiv = $('<div class="buttons"></div>');
			contentBox.append(bottonsDiv);
			Objects.addSaveButton(bottonsDiv, function(event){
				var entry = new Object();
				entry.id = event.data.id;
				entry.progress = event.data.progress.val()/100;
				Goal.updateProgress(entry, function(result){
					var event = new Object();
					event.data = new Object();
					event.data.divId = "goalsList";
					event.data.objectTypeId = result.objectDescriptionId;
					event.data.objectId = result.objectId;
					Goal.showGoalList(event);
				});
				event.data.div.remove();
			}, saveConfig);
			
			Objects.addCancelButton(bottonsDiv, function(event){
				event.data.div.remove();
			}, {div: overlayBox});
		}else{
			console.log('main_contet not found');
			return;
		}
	}
}
Goal.OBJECT_DESCRIPTION_ID = 27;
Goal.NAME_FIELD_ID = 63;
Goal.PROGRESS_FIELD_ID = 64;
Goal.START_FIELD_ID = 65;
Goal.END_FIELD_ID = 66;
Goal.DESCRIPTION_FIELD_ID = 67;

Goal.API_ENDPOINT = '/api/Goal/';

export default Goal;

import UI from "../ui/defaultUi.js";
import ListDivHeader from "../ui/content/ListDivHeader.js";
import ObjectTranslation from "./ObjectTranslation.js";

class GoalUI extends UI {

	constructor(config) {
		super();
		this.objectTypeId = config.objectTypeId;
		this.objectId = config.objectId;
		this.listDivHeader = new ListDivHeader;
		super.setBaseJQueryObject(this.listDivHeader.getJQueryRepresentation());
		var scope = this;
		ObjectTranslation.getObjectNameTranslation(Goal.OBJECT_DESCRIPTION_ID, function(objectNameTranslation){
				scope.listDivHeader.text(objectNameTranslation.namePlural);
			});
		this.listDivHeader.getShowButton().click(function(event) {
				Goal.showGoalList(event);
			}, {
				objectTypeId: this.objectTypeId,
				objectId: this.objectId,
				divId: "goalsList"
			});
		this.listDivHeader.getNewButton().click(function(event) {
			Goal.newGoalOverlay(event);
		}, {
			objectTypeId: this.objectTypeId,
			objectId: this.objectId,
			divId: "goalsList"
		});
		this.listDivHeader.getHideButton().click(function(event) {
			$('#goalsList').html('');
		}, {});
	}
}