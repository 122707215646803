import ContentHeadline from "./ContentHeadline.js";
import Button from "../Button.js";
import ObjectTranslation from "../../objects/ObjectTranslation.js";

class ShowDetailsHeader extends ContentHeadline {

	constructor(config) {
		super(config);
		this.getEditButton();
		this.getDeleteButton();
		
		if (config != null) {
			if(config.descriptonId != null){
				let scope = this;
				ObjectTranslation.getObjectNameTranslation(config.descriptonId, function(translation){
					scope.text(translation.name + ":");
				});
			}
		}
	}
	
	getEditButton() {
		if (this.editButton != null) {
			return this.editButton;
		}
		this.editButton = Button.createEditButton();
		this.append(this.editButton);
		return this.showButton;
	}
	
	getDeleteButton() {
		if (this.deleteButton != null) {
			return this.deleteButton;
		}
		this.deleteButton = Button.createDeleteButton();
		this.append(this.deleteButton);
		return this.deleteButton;
	}
}
export default ShowDetailsHeader;