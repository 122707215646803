import UI from "../../../defaultUi.js";
import Translation from "../../../../objects/Translation.js";

class MonthlyPlot extends UI{
	constructor(config){
		super(config);
		this.isReady = false;
		this.plotId = config.typ + 'newplot';
		let plotDiv = $('<div id="' + this.plotId + '" class="jqplot-target" style="height: 400px; width: 1100px; position: relative;"></div>');
		super.setBaseJQueryObject(plotDiv);
		let scope = this;
		if (config.typ == MonthlyPlot.GRAPH_TYP_ELECTRICITY) {
			Translation.load('Strom',function(translElec){
				Translation.load('Verbrauch',function(translUsage){
					let plotTitel = translElec.text + ' ' + translUsage.text + ' (kWh)';
					let seriesLegend = scope.getSeriesLegend();
					scope.jqpDefaultSetting = new App.JqpDefaultSetting(plotTitel,seriesLegend);
					var graphData = config.standort.getMonthlyElectricityConsumption(function(graphData){
						scope.graphData = graphData;
						if (scope.isReady) {
							scope.render();
						}
					});
				});
			});
		} else if (config.typ == MonthlyPlot.GRAPH_TYP_HEAT){
			Translation.load('Waerme',function(translElec){
				Translation.load('Verbrauch',function(translUsage){
					let plotTitel = translElec.text + ' ' + translUsage.text + ' (kWh)';
					let seriesLegend = scope.getSeriesLegend();
					scope.jqpDefaultSetting = new App.JqpDefaultSetting(plotTitel,seriesLegend);
					var graphData = config.standort.getMonthlyHeatConsumption(function(graphData){
						scope.graphData = graphData;
						if (scope.isReady) {
							scope.render();
						}
					});
				});
			});
		} else if (config.typ == MonthlyPlot.GRAPH_TYP_WATER){
			Translation.load('Wasser',function(translElec){
				Translation.load('Verbrauch',function(translUsage){
					let plotTitel = translElec.text + ' ' + translUsage.text + ' (m&sup3;)';
					let seriesLegend = scope.getSeriesLegend();
					scope.jqpDefaultSetting = new App.JqpDefaultSetting(plotTitel,seriesLegend);
					var graphData = config.standort.getMonthlyWaterConsumption(function(graphData){
						scope.graphData = graphData;
						if (scope.isReady) {
							scope.render();
						}
					});
				});
			});
		}
		this.isReady = true;
	}
	
	render() {
		if (this.isReady && this.graphData != null && $('#' + this.plotId).length > 0) {
			var plot = $.jqplot(this.plotId, this.graphData, this.jqpDefaultSetting);
			this.downloadLink();
		}
	}
	
	downloadLink() {
		let rows = [["date", "data"]];
		let endYear = new Date().getFullYear();
		let startYear = endYear - 3;
		for (var i = 0; i < this.graphData.length; i++) {
			let year = startYear + i;
			let yearData = this.graphData[i];
			for (var j = 0; j < yearData.length; j++) {
				let monthData = yearData[j];
				let month = monthData[0];
				let data = monthData[1];
				let dateStr = year + '-' + month;
				rows.push([dateStr, data]);
			}
		}
		let downloadButtonId = this.plotId + 'download'
		let divAndLink = $('<div class="jqplot-title" style="position: absolute; top: 0px; left: 0px; width: 1100px; text-align: left;">' + '<a id="' + downloadButtonId + '" download="graph_data.csv">download<a/></div>');
		let plotDiv = $('#' + this.plotId);
		plotDiv.append(divAndLink);
		let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");
		var encodedUri = encodeURI(csvContent);
		
		let linkjq = $('#' + downloadButtonId);
		linkjq.attr("href", encodedUri);
	}

	getSeriesLegend() {
		if(this.seriesLegend != null){
			return this.seriesLegend;
		}
		this.seriesLegend = [];
		let endYear = new Date().getFullYear();
		let startYear = endYear - 3;
		for (let year = startYear; year <= endYear; year++) {
			this.seriesLegend.push({label:year});
		}
		return this.seriesLegend;
	}
}
MonthlyPlot.GRAPH_TYP_ELECTRICITY = 'electricity';
MonthlyPlot.GRAPH_TYP_HEAT = 'heat';
MonthlyPlot.GRAPH_TYP_WATER = 'water';
export default MonthlyPlot;