import OverlayBox from "../../OverlayBox.js";
import Button from "../../Button.js";
import Persons from "../../../objects/Persons.js";
import InputTable from "../../table/InputTable.js";
import Observable from "../../../misc/Observable.js";
import EVAEvent from "../../EVAEvent.js";
import BalanceGroup from "../../../objects/BalanceGroup.js"
import NumberInput from "../../inputs/NumberInput.js";
import CorrectionValue from "../../../objects/CorrectionValue.js";

class EditPersonsOverlay extends Observable {
	constructor(config) {
		super(config);
		if (config) {
			this.persons = config.persons;
			this.privius_persons = config.privius_persons;
			this.standortId = config.standortId;
		}
		let scope = this;
		this.overlayBox = new OverlayBox({
			isCloseable: true
		});
		
		let table = new InputTable(1);
		this.overlayBox.append(table);
		
		this.datumInput = table.addDateInput(Persons.FIELD_DATE_ID, "p_datum");
		this.anzahlInput = table.addNumberInput(Persons.FIELD_COUNT_ID, "p_count");
		
		if(scope.persons == null) {
			let inputCell = table.createTranslationLabeledCell("correction per person", "p_corection")
			this.correctionPerPersonInput = new NumberInput({id: "p_corection"});
			inputCell.append(this.correctionPerPersonInput.getJQueryRepresentation());
			this.correctionPerPersonInput.val(2)
		}
		
		if (this.persons) {
			this.datumInput.val(this.persons.datum);
			this.anzahlInput.val(this.persons.anzahl);
		}

		let saveButton = Button.createSaveButton()
		this.overlayBox.getFooter().append(saveButton);
		saveButton.click(function(event){
			if (scope.persons == null) {
				scope.persons = new Object();
				scope.persons.standortId = scope.standortId;
			}
			scope.persons.datum = scope.datumInput.val();
			scope.persons.anzahl = scope.anzahlInput.val();
			
			Persons.save(scope.persons, function(persons){
				scope.createCorrection();
				scope.overlayBox.remove();
				let event = new EVAEvent({
					type: EVAEvent.TYPE_SAVE,
					data: persons
				});
				scope.notify(event);
			});
		});
	}
	
	createCorrection() {
		if(!this.correctionPerPersonInput) {
			return;
		}
		if (this.privius_persons) {
			if(!(new Date(this.privius_persons.datum) < this.persons.datum)) {
				return;
			}
		}
		let correctionAmountPP = this.correctionPerPersonInput.val();
		if(correctionAmountPP <= 0) {
			return;
		}
		let correctionValue = new CorrectionValue();
		correctionValue.standortId = this.standortId;
		let personCout  = this.persons.anzahl;
		if (this.privius_persons) {
			personCout = personCout - this.privius_persons.anzahl;
		}
		correctionValue.wert = correctionAmountPP * personCout;
		correctionValue.beschreibung = "Automatisch erzeugt: " + personCout + " Personen je " + correctionAmountPP  + " m³";
		correctionValue.datum = this.persons.datum;
		correctionValue.bilanzgruppeId = BalanceGroup.WATER_ID;
			
		correctionValue.upsert(function(correctionValue){
			//TODO update correction values
		});
	}
}
export default EditPersonsOverlay;