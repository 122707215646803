import UI from "../defaultUi.js";
import SelectListOption from "./SelectListOption.js";

class SelectListInput extends UI{
	constructor(config){
		super(config);
		this.size = 1;
		if (config.size) {
			this.size = config.size;
		}
		this.selectInput = $('<select size="' + this.size + '">');
		super.setBaseJQueryObject(this.selectInput);
		if (config) {
			if (config.id) {
				this.selectInput.attr('id', config.id);
			}
		}
	}
	
	setFieldError(){
		this.selectInput.addClass('RequiredFieldNotSet');
	}
	
	removeFieldError(){
		this.selectInput.removeClass('RequiredFieldNotSet');
	}
	
	uiInit() {
	}
	
	append(toAppend) {
		if (toAppend instanceof SelectListOption) {
			return this.selectInput.append(toAppend.getJQueryRepresentation());
		}
		if (toAppend instanceof UI) {
			return this.selectInput.append(toAppend.getJQueryRepresentation());
		}
		return this.selectInput.append(toAppend);
	}
	
	addOption(value, text) {
		let option = new SelectListOption({
			value:value,
			text:text
		});
		this.selectInput.append(option.getJQueryRepresentation());
		return option;
	}

	val(newVal) {
		if (newVal) {
			return this.selectInput.val(newVal).change();
		}
		let value = this.selectInput.val();
		if (value == '--') {
			return null;
		}
		return value;
	}
	
	change(callback) {
		this.selectInput.change(callback);
	}
	
	addEmpty() {
		this.addOption('--', '--');
	}
	
	clear() {
		this.selectInput.html('');
	}
	
	disabled(willDisabled) {
		if (willDisabled) {
			this.selectInput.prop('disabled', 'disabled');
		} else {
			this.selectInput.prop('disabled', false);
			this.selectInput.removeProp('disabled');
		}
	}
}
export default SelectListInput;