import UI from "../defaultUi.js";
class InputGroup extends UI {
	constructor() {
		super();
		this.inputgroup = $("<div class='inputgroup'></div>");
		super.setBaseJQueryObject(this.inputgroup);
	}
	
	append(toAppend) {
		if (toAppend instanceof UI) {
			this.inputgroup.append(toAppend.getJQueryRepresentation());
		} else {
			this.inputgroup.append(toAppend);
		}
	}
}
export default InputGroup;
