import UI from "../../defaultUi.js";
import MeterPoint from "../../../objects/MeterPoint.js";
import ContentBoxGroup from "../../content/ContentBoxGroup.js";
import ShowDetailsHeader from "../../content/ShowDetailsHeader.js";
import ContentBox from "../../ContentBox.js";
import ObjectTranslation from "../../../objects/ObjectTranslation.js";
import MeterPointDetailTable from "./MeterPointDetailTable.js";
import Translation from "../../../objects/Translation.js";
import Objects from "../../../objects/Objects.js";

class MeterPointDetailGroupBox extends UI{
	constructor(config){
		super(config);
		this.contentBoxGroup = new ContentBoxGroup();
		super.setBaseJQueryObject(this.contentBoxGroup);
		this.header = new ShowDetailsHeader();
		this.contentBoxGroup.append(this.header);
		this.contentBox = new ContentBox();
		this.contentBoxGroup.append(this.contentBox);

		let scope = this;
		ObjectTranslation.getObjectNameTranslation(MeterPoint.DESCRIPTON_ID, function(translation){
			scope.header.text(translation.name + ":");
		});
		
		if (config != null) {
			if (config.meterPoint != null) {
				this.setMeterPoint(config.meterPoint);
			}
		}
	}
	
	setMeterPoint(meterPoint) {
		if (!(meterPoint instanceof MeterPoint)) {
			meterPoint = new MeterPoint(meterPoint);
		}
		this.meterPoint = meterPoint;
		let scope = this;
		this.header.getEditButton().click(function(){
			window.location.href = Objects.rootURL + "/Frontend/Messpunkt/edit/page.jsp?messpunktToEdit=" + scope.meterPoint.id + "&standortId="  + scope.meterPoint.standortID;
		});
		Translation.load("Messpunkt wirklich loeschen?", function(translation){
			scope.confirmDeleteText = translation.text;
		});

		this.header.getDeleteButton().click(function(){
			let r = confirm(scope.confirmDeleteText);
			if (r == true) {
				scope.meterPoint.remove(function(data) {
					if(data.success === true){
						window.location.href = Objects.rootURL + "/Frontend/Standort/page.jsp?standortID=" + scope.meterPoint.standortID;
					}else{
						cleanError();
						addToError(data.statusMessage);
					}
				});
			}
		});
		let meterPointDetailTable = new MeterPointDetailTable({
			meterPoint: this.meterPoint
		});
		this.contentBox.append(meterPointDetailTable);
	}
}
export default MeterPointDetailGroupBox;