import UiElement from "../../UiElement.js";
import License from "../../../objects/License.js";
import LicenseAddressBookEntriesList from "../addressBookEntry/LicenseAddressBookEntriesList.js";
import TariffList from "../tariff/TariffList.js";
import UserList from "../user/UserList.js";
import Button from "../../Button.js";
import ContentBoxGroup from "../../content/ContentBoxGroup.js";
import ContentHeadline from "../../content/ContentHeadline.js";
import BuildingConsumptionList from "./BuildingConsumptionList.js"

class ShowLicense extends UiElement{
	constructor(config) {
		super(config);
		this.setBaseJQueryObject($('<div></div>'));
		if (config != null) {
			if (config.license != null) {
				this.setLicense(config.license);
			} else if (config.licenseId != null) {
				let scope = this;
				License.load(config.licenseId, function(license){
					scope.setLicense(license);
				});
			}
		}
	}
	
	setLicense(license) {
		this.license = license;
		if (license == null) {
			this.clear();
			return;
		}

		let contentBoxGroup = new ContentBoxGroup();
		this.append(contentBoxGroup);
		let header = new ContentHeadline();
		contentBoxGroup.append(header);
		let csvButton = new Button({
			iconCssClass : Button.DOWNLOAD_ICON
		});
		csvButton.textTranslate('download bilanzen');
		header.append(csvButton);
		let scope = this;
		csvButton.click(function(event) {
			new BuildingConsumptionList({
				license:scope.license
				});
		});
		
		let userList = new UserList({
			licensId:this.license.id,
			show: true
			});
		this.append(userList);
		let tariffList = new TariffList({
			license: this.license,
			show: true
		});
		this.append(tariffList);
		let licenseAddressBookEntriesList = new LicenseAddressBookEntriesList({
			license: this.license
		});
		this.append(licenseAddressBookEntriesList);
	}
}
export default ShowLicense;