import UI from "../defaultUi.js";

class Link extends UI{
	constructor(config) {
		super(config);
		this.link = $('<a></a>');
		super.setBaseJQueryObject(this.link);
		if (config) {
			if (config.text) {
				this.text(config.text);
			}
			if (config.href) {
				this.href(config.href);
			}
			if (config.clickEvent) {
				this.click(config.clickEvent);
			}
		}
	}
	
	text(text) {
		return this.link.text(text);
	}
	
	href(href) {
		return this.link.attr("href", href);
	}
	
	click(clickEvent) {
		return this.link.click(clickEvent);
	}
}
export default Link;