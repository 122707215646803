import UI from "../defaultUi.js";

class EmailInput extends UI{
	constructor(config){
		super(config);
		this.emailInput = $('<input type="email" class="email" />');
		super.setBaseJQueryObject(this.emailInput);
		if (config) {
			if (config.id) {
				this.emailInput.attr('id', config.id);
			}
		}
	}
	
	setFieldError(){
		this.emailInput.addClass('RequiredFieldNotSet');
	}
	
	removeFieldError(){
		this.emailInput.removeClass('RequiredFieldNotSet');
	}
	
	uiInit() {
	}

	val(newVal) {
		if (newVal) {
			return this.emailInput.val(newVal);
		}
		return this.emailInput.val();
	}
}
export default EmailInput;