import UI from "../defaultUi.js";
import OverlayBox from "../OverlayBox.js";
import Translation from "../../objects/Translation.js";
import EVAEvent from "../EVAEvent.js";

class InfoOverlay extends UI{
	constructor(config) {
		super(config);
		this.overlayBox = new OverlayBox({
			isCloseable: true
		});
		super.setBaseJQueryObject(this.overlayBox);
		this.text = $('<div class="centered"></div>');
		this.overlayBox.append(this.text);
		if (config != null) {
			if (config.text != null) {
				this.setTanslationText(config.text);
			}
			if (config.data != null) {
				this.setData(config.data);
			}
		}
	}
	
	setTanslationText(text){
		let scope = this;
		Translation.load(text, function(translation){
			scope.text.text(translation.text);
		});
	}
	
	setData(data) {
		if (data instanceof EVAEvent) {
			this.text.text(data.data);
			return;
		}
		let preFormText = $("<pre></pre>");
		this.text.html(preFormText);
		preFormText.text(data);
	}
}
export default InfoOverlay;