import Observable from "../../../misc/Observable.js";
import OverlayBox from "../../OverlayBox.js";
import Button from "../../Button.js";
import InputTable from "../../table/InputTable.js";
import File from "../../../objects/File.js";
import Upload from "../../../misc/Upload.js";
import InfoOverlay from "../../content/InfoOverlay.js";

class UploadConsumptionCSV extends Observable{
	constructor(config) {
		super(config);
		if (config) {
			if(config.meterPoint != null) {
				this.meterPoint = config.meterPoint;
			}
		}
		this.overlayBox = new OverlayBox({
			isCloseable: true
		});

		let footer = this.overlayBox.getFooter();
		let saveButton = Button.createSaveButton()
		footer.append(saveButton);
		let scope = this;
		saveButton.click(function(event){
			scope.save();
		});

		this.table = new InputTable(1);
		this.overlayBox.append(this.table);
		this.fileInput = this.table.addFileInput(File.FILE_FIELD_DESCRIPTION_ID, File.FILE_INPUT_ID);
	}
	
	save() {
		let scope = this;
		if (this.meterPoint != null) {
			Upload.uploadByInput("/api/Verbrauch/upload_consumption_for_" + this.meterPoint.id, this.fileInput, function(data){
				scope.overlayBox.remove();
				let infoOverlay = new InfoOverlay({data:data});
				scope.notify(data);
			});
			return;
		}
	}
	
}
export default UploadConsumptionCSV;