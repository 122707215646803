import SortableListBoxGroup from "../../table/SortableListBoxGroup.js";
import Tariff from "../../../objects/Tariff.js";
import Objects from "../../../objects/Objects.js";

class TariffList extends SortableListBoxGroup {
	constructor(config) {
		super(config);

		let scope = this;
		this.contentHeadline.setHeadlineById(Tariff.OBJECT_DESCRIPTION_ID);
		this.contentHeadline.getNewButton().click(function(event) {
			window.location.href = Objects.rootURL + "/Frontend/Tariff/create/page.jsp?licenseID=" + scope.license.id;
		}, {});
		if (config) {
			if (config.license) {
				this.setLicense(config.license);
			}
		}
		
	}
	
	setLicense(license){
		this.license = license;
		this.rowDatas.clear();
		let scope = this;
		Tariff.loadAll({
			licenseId: this.license.id},
			function (tariffs) {
			scope.pushAll(tariffs);
		});
	}
	
	renderHead() {
		this.addSortHeaderByFDI(Tariff.NAME_FDI, 'name');
		this.addSortHeaderByFDI(Tariff.MEDIUM_FDI, 'medium.name');
		this.addSortHeaderByFDI(Tariff.UNIT_FDI, 'unit.name');
	}
	
	renderRow(entry, row) {
		row.append(entry.getLink());
		entry.getMedium(function(medium){
			row.addText(medium.name);
		});
		entry.getUnit(function(unit){
			row.addText(unit.name);
		});
	}
}
export default TariffList;