import UI from "../../defaultUi.js";
import SortableListBoxGroup from "../../table/SortableListBoxGroup.js";
import Label from "../../content/Label.js";
import Persons from "../../../objects/Persons.js";
import ObjectTranslation from "../../../objects/ObjectTranslation.js";
import Translation from "../../../objects/Translation.js";
import Objects from "../../../objects/Objects.js";
import ShowPersonsOverlay from "../persons/ShowPersonsOverlay.js"
import EditPersonsOverlay from "../persons/EditPersonsOverlay.js"
import Button from "../../Button.js";

class StandortPersonsList extends SortableListBoxGroup{
	
	constructor(config) {
		super(config);
		if (config) {
			if (config.standortId) {
				this.standortId = config.standortId;
			}
			if (config.standort) {
				this.standortId = config.standort.id;
			}
		}
		
		let scope = this;
		ObjectTranslation.getObjectNameTranslation(Persons.OBJECT_DESCRIPTION_ID, function(objectNameTranslation){
				scope.contentHeadline.text(objectNameTranslation.namePlural);
			});
		Translation.load("Personen wirklich loeschen?", function(translation){
			scope.confirmDeleteText = translation.text;
		});
		
		this.contentHeadline.getNewButton().click(function(event) {
			new EditPersonsOverlay({
				standortId: scope.standortId,
				privius_persons: scope.latestPersons(),
				eventCallback:function(event){
					scope.reload();
					}
			});
		});
		if (this.standortId != null) {
			this.load();
		}
	}
	
	latestPersons(){
		let latest;
		this.rowDatas.forEach(function(entry) {
			if (!latest) {
				latest = entry;
				return;
			}
			if (latest.datum < entry.datum) {
				latest = entry;
			}
		});
		return latest;
	}
	
	reload() {
		this.load();
	}
	
	load() {
		this.rowDatas.clear();
		let scope = this;
		Persons.loadAll(this.standortId, function(personsList){
			scope.pushAll(personsList);
		});
	}

	renderHead() {
		let scope = this;
		let label = new Label({
			fieldId: Persons.FIELD_DATE_ID,
			table: true
		});
		let cell = this.table.headerAppend(label);
		cell.click(function(){
			scope.sortBy('datum');
		});
		label = new Label({
			fieldId: Persons.FIELD_COUNT_ID,
			table: true
		});
		cell = this.table.headerAppend(label);
		cell.click(function(){
			scope.sortBy('anzahl');
		});
		
		label = new Label({
			translationKey: "delete",
			table: true
		});
		cell = this.table.headerAppend(label);
	}
	
	renderRow(entry,row) {
		let datumA = $('<a/>');
		let scope = this;
		datumA.click(function(event) {
				event.preventDefault();
				new ShowPersonsOverlay({
					persons: entry,
					eventCallback: function(event) {
						scope.rerender();
					}
				});
				return false; 
			});
		datumA.text((new Date(entry.datum)).toLocaleDateString());
		row.addCellDomObj(datumA, 'date');
		row.addNumber(entry.anzahl);
		let deletButton = Button.createDeleteButton();
		deletButton.click(function(event) {
			let r = confirm(scope.confirmDeleteText);
			if (r == true) {
				Persons.remove(entry.id, function(data){
					scope.reload();
				});
			}
			});
		row.addCellDomObj(deletButton);
	}
}
export default StandortPersonsList;