import UI from "../defaultUi.js";
import HelpOverlay from "../HelpOverlay.js";
import FieldTranslation from "../../objects/FieldTranslation.js";
class TableHeaderLabel extends UI{
	constructor(config){
		super();
		this.label = $('<label style="text-align: center;"></label>');
		super.setBaseJQueryObject(this.label);
		var scope = this;
		FieldTranslation.load(config.fieldId, function(translation){
			scope.label.text(translation.name + ' ');
			var span = $('<span class="helpTextButton glyphicon glyphicon-info-sign" aria-hidden="true"></span>');
			scope.label.append(span);
			span.click(function(){
				let lelpOverlay = new HelpOverlay({
					fieldId: config.fieldId
				});
				return false;
			});
		});
	}
}
export default TableHeaderLabel;