import Objects from "../objects/Objects.js";
import RequestChain from "./RequestChain.js";

class CachedLoadChain {
	
	constructor(endpoint){
		this.endpoint = endpoint;
		this.cache = {};
		this.requestChain = new RequestChain();
	}
	
	load(id, callback) {
		if (id == null) {
			callback(null);
			return;
		}
		var cacheed = this.cache[id];
		if (cacheed) {
			callback(cacheed);
			return;
		}
		this.requestChain.push(new LoadRequest(this.endpoint, id, callback, this));
	}
}

export default CachedLoadChain;

class LoadRequest {
	
	constructor(endpoint, id, callback, chain){
		this.id = id;
		this.endpoint = endpoint;
		this.callback = callback;
		this.chain = chain;
	}
	
	run() {
		var cacheed = this.chain.cache[this.id];
		if (cacheed) {
			this.callback(cacheed);
			this.chain.requestChain.next();
			return;
		}
		var scope = this;
		Objects.load(function(result){
			scope.chain.cache[result.id] = result;
			scope.callback(result);
			scope.chain.requestChain.next();
		}, this.endpoint + this.id, false, function() {
			//error happend
			//scope.chain.cache[scope.id] = null; do not store lets retry 
			scope.callback(null);
			scope.chain.requestChain.next();
		});
	}
}