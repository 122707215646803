import Objects from "./Objects.js";
class FieldTranslation{

	static load(id, callback){
		var field = FieldTranslation.chash[id];
		if (field) {
			callback(field);
			return;
		}
		Objects.load(function (data) {
			if (data == null) {
				console.log("no data for " + id);
				callback(null);
				return;
			}
			FieldTranslation.chash[data.objectFieldId] = data;
			callback(data);
		},"/api/ObjectFieldNameTranslation/"+id);
	}
}
FieldTranslation.chash = new Object();
export default FieldTranslation;