import UI from "../defaultUi.js";
import InputGroup from "./InputGroup.js"
import InputGroupAddon from "./InputGroupAddon.js"

class PercentageInput extends UI {
	constructor() {
		super();
		this.inputgroup = new InputGroup();
		super.setBaseJQueryObject(this.inputgroup);
		this.inputElement = $('<input type="number" min="0" max="100" class="number" />');
		this.inputgroup.append(this.inputElement);
		this.percentSign = new InputGroupAddon({text:'%'});
		this.inputgroup.append(this.percentSign);
	}
	
	setFieldError(){
		this.inputElement.setFieldError();
	}
	
	removeFieldError(){
		this.inputElement.setFieldError();
	}
	
	val(val) {
		if (val) {
			return this.inputElement.val(val * 100);
		} else {
			return this.inputElement.val() / 100;
		}
	}
	
	
	/*
	 * 
	  var inputGroup = $('<div class="inputgroup"></div>');
		tableCell.append(inputGroup);
		var progressInputField = $('<input type="number" min="0" max="100" id="'+ fieldId +'" class="number" />');
		inputGroup.append(progressInputField);
		var inputUnit = $('<span class="inputgroupaddon" id="' + fieldId + '_unit">%</span>');
		inputGroup.append(inputUnit);
		var progressInputRangeField = $('<input type="range" min="0" max="100" id="'+ fieldId +'_range" class="number" />');
		tableCell.append(progressInputRangeField);
		progressInputField.change(function(){
			progressInputRangeField.val(progressInputField.val());
		});
		progressInputField.on('input', function(){
			progressInputRangeField.val(progressInputField.val());
		});
		progressInputRangeField.change(function(){
			progressInputField.val(progressInputRangeField.val());
		});
		progressInputRangeField.on('input', function(){
			progressInputField.val(progressInputRangeField.val());
		});
	 */
}
export default PercentageInput;