import UI from "../../defaultUi.js";
import Standort from "../../../objects/Standort.js";
import StandortShowBoxGroup from "./StandortShowBoxGroup.js"
import MeterPointAndMeterList from "../meterPoint/MeterPointAndMeterList.js";
import StandortFloorAreaList from "./StandortFloorAreaList.js";
import StandortPersonsList from "./StandortPersonsList.js";
import StandortConsumptionAnalysis from "./StandortConsumptionAnalysis.js";
import StandortUserList from "../StandortUserList.js";
import CommentList from "../comment/CommentList.js";
import GoalList from "../goal/GoalList.js";
import StandortAddressBookEntryList from "./StandortAddressBookEntryList.js";
import FileList from "../file/FileList.js";
import Env from "../../../misc/Env.js";
import File from "../../../objects/File.js";
import StandortList from "../StandortList.js";
import Objects from "../../../objects/Objects.js";
import CorrectionValueList from "../correctionValue/CorrectionValueList.js"

class ShowStandort extends UI {
	constructor(config) {
		super();
		this.showDiv = $('<div></div>');
		super.setBaseJQueryObject(this.showDiv);

		let scope = this;
		window.onpopstate = function(event) {
			if (history.state && history.state.page == "ShowStandort") {
				if (history.state.standort != null) {
					scope.setStandort(history.state.standort);
				} else {
					scope.setStandort(null);
				}
			}
		}
		if (config) {
			if (config.standort) {
				this.setStandort(standort);
			} else if(config.standortId) {
				Standort.load(config.standortId, function(standort){
					scope.setStandort(standort);
				});
			} else {
				this.setStandort(null);
			}
		}
	}
	
	setStandort(standort) {
		this.standort = standort;
		this.render();
	}

	render() {
		this.showDiv.html('');
		let scope = this;
		if (this.standort == null) {
			history.replaceState({
				page: "ShowStandort",
				standort: null
				}, null, Objects.rootURL + '/Frontend/Standort/page.jsp?standortID=null');
			
			let standortList = new StandortList({
				show: true,
				action: function(standort) {
					history.pushState({
						page: "ShowStandort",
						standort: standort
						}, standort.name, Objects.rootURL + '/Frontend/Standort/page.jsp?standortID=' + standort.id);
					scope.setStandort(standort);
				}
				});
			this.showDiv.append(standortList.getJQueryRepresentation());
			return;
		}
		
		let standortShowBoxGroup = new StandortShowBoxGroup({
			standort: this.standort
		});
		this.showDiv.append(standortShowBoxGroup.getJQueryRepresentation());

		let meterPointAndMeterList = new MeterPointAndMeterList({
			standort: this.standort,
			show: true
		});
		this.showDiv.append(meterPointAndMeterList.getJQueryRepresentation());
		
		let floorAreaList = new StandortFloorAreaList({
			standort: this.standort
		});
		this.showDiv.append(floorAreaList.getJQueryRepresentation());

		let personsList = new StandortPersonsList({
			standortId: this.standort.id
		});
		this.showDiv.append(personsList.getJQueryRepresentation());
		
		let correctionValueList = new CorrectionValueList({
			standort: this.standort
		});
		this.showDiv.append(correctionValueList.getJQueryRepresentation());
		
		let standortConsumptionAnalysis = new StandortConsumptionAnalysis({
			standort: this.standort
		});
		this.showDiv.append(standortConsumptionAnalysis.getJQueryRepresentation());
		
		let standortUserList = new StandortUserList({
			standort: this.standort.id
		});
		this.showDiv.append(standortUserList.getJQueryRepresentation());
		
		let commentList = new CommentList({
			objectTypeId: Standort.DESCRIPTON_ID,
			objectId: this.standort.id
		});
		this.showDiv.append(commentList.getJQueryRepresentation());
		
		let goalList = new GoalList({
			objectTypeId: Standort.DESCRIPTON_ID,
			objectId: this.standort.id
		});
		this.showDiv.append(goalList.getJQueryRepresentation());

		let standortAddressBookEntryList = new StandortAddressBookEntryList({
			standortId: this.standort.id
		});
		this.showDiv.append(standortAddressBookEntryList.getJQueryRepresentation());
		
		Env.user.hasRoleById(File.READ_ROLE_ID, function(hasReadRole){
			if (!hasReadRole) {
				return;
			}
			let fileList = new FileList({
				objectTypeId: Standort.DESCRIPTON_ID,
				objectId: scope.standort.id
			});
			scope.showDiv.append(fileList.getJQueryRepresentation());
		});
	}
	
}
export default ShowStandort;