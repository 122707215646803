import Objects from "./Objects.js";
import ListTable from "../ui/table/ListTable.js";
import TableHeaderLabel from "../ui/table/TableHeaderLabel.js";
import LabeledTable from "../ui/table/LabeledTable.js";
import LabeledTableCell from "../ui/table/LabeledTableCell.js";
import OverlayCloseCross from "../ui/OverlayCloseCross.js";
import StandortAddressBookEntryList from "../ui/objects/standort/StandortAddressBookEntryList.js";

class AddressBookEntry{
	static save(entry, callback){
		Objects.save(entry, callback, AddressBookEntry.ENDPOINT + "insert");
	}
	
	static update(entry, callback){
		Objects.save(entry, callback, AddressBookEntry.ENDPOINT + "update");
	};
	
	static load(id, callback){
		Objects.load(callback, AddressBookEntry.ENDPOINT + id);
	}
	
	static loadAll(callback){
		Objects.load(callback, AddressBookEntry.ENDPOINT + "all");
	}
	
	static loadAllByLicense(license, callback){
		Objects.load(callback, AddressBookEntry.ENDPOINT + "all?licenseId=" + license.id);
	}
	
	static allByStandort(standort, callback){
		Objects.post(standort, callback, AddressBookEntry.ENDPOINT + "allByStandort");
	}
	
	static connectToStandort(addressBookEntryStandort, callback){
		Objects.post(addressBookEntryStandort, callback, AddressBookEntry.ENDPOINT + "connectToStandort");
	}

	static remove(entryId, callback) {
		Objects.remove(callback, AddressBookEntry.ENDPOINT + entryId);
	}
}

AddressBookEntry.OBJECT_DESCRIPTION_ID = 28;
AddressBookEntry.ADDRESS_BOOK_ENTRY_DIV_ID = "address_book_entries_list_div";
AddressBookEntry.NEW_ADDRESS_BOOK_ENTRY_OVERLAY_ID = "new_addressBookEntry_overlay";
AddressBookEntry.ADDRESS_BOOK_ENTRY_OVERLAY_ID = "addressBookEntry_overlay";
AddressBookEntry.EDIT_FORM_ID = "addressBookEntriy_edit_form";
AddressBookEntry.FIRST_NAME_FIELD_DESCRIPTION_ID = 68;
AddressBookEntry.FIRST_NAME_INPUT_ID = "abe_first_name";
AddressBookEntry.LAST_NAME_FIELD_DESCRIPTION_ID = 69;
AddressBookEntry.LAST_NAME_INPUT_ID = "abe_last_name";
AddressBookEntry.PHONE_NUMBER_FIELD_DESCRIPTION_ID = 70;
AddressBookEntry.PHONE_NUMBER_INPUT_ID = "abe_phone_number";
AddressBookEntry.MOBILE_NUMBER_FIELD_DESCRIPTION_ID = 71;
AddressBookEntry.MOBILE_NUMBER_INPUT_ID = "abe_mobile_number";
AddressBookEntry.ADDITIONAL_INFORMATION_FIELD_DESCRIPTION_ID = 72;
AddressBookEntry.ADDITIONAL_INFORMATION_INPUT_ID = "abe_additional_information";
AddressBookEntry.GDPR_APPROVAL_FIELD_DESCRIPTION_ID = 73;
AddressBookEntry.GDPR_APPROVAL_INPUT_ID = "abe_gdpr_approval";
AddressBookEntry.JOB_FIELD_DESCRIPTION_ID = 74;
AddressBookEntry.JOB_FIELD_INPUT_ID = "abe_job_field";
AddressBookEntry.FORM_OF_ADDRESS_FIELD_DESCRIPTION_ID = 75;
AddressBookEntry.FORM_OF_ADDRESS_INPUT_ID = "abe_form_of_address";
AddressBookEntry.ADDRESS_BOOK_ENTRY_SELECT_ID = "address_book_entry_select";
AddressBookEntry.EMAIL_FIELD_DESCRIPTION_ID = 80;
AddressBookEntry.EMAIL_INPUT_ID = "abe_email";
AddressBookEntry.ENDPOINT = "/api/AddressBookEntry/";

export default AddressBookEntry;