import LabeledTableCell from "./LabeledTableCell.js";
import User from "../../objects/User.js";
import BooleanTableCell from "./BooleanTableCell.js";

class LabeledBooleanTableCell extends LabeledTableCell{
	constructor(config) {
		if (config == null) {
			config = new Object();
		}
		config.contentCell = new BooleanTableCell(config);
		super(config);
	}

	setBoolean(boolean) {
		this.getContentCell().setBoolean(boolean);
	}
}
export default LabeledBooleanTableCell;