import ObjectTranslation from "../../../objects/ObjectTranslation.js";
import OverlayBox from "../../OverlayBox.js";
import Button from "../../Button.js";
import AddressBookEntry from "../../../objects/AddressBookEntry.js";
import InputTable from "../../table/InputTable.js";
import StandortAddressBookEntry from "../../../objects/StandortAddressBookEntry.js";
import EditAddressBookEntryOverlay from "../addressBookEntry/EditAddressBookEntryOverlay.js";
import Observable from "../../../misc/Observable.js";
import EVAEvent from "../../EVAEvent.js";

class NewStandortAddressBookEntryOverlay extends Observable {
	constructor(config) {
		super(config);
		if (config) {
			this.standort = config.standort;
			if (this.standort == null) {
				if (config.standortId) {
					this.standort = new Object();
					this.standort.id = config.standortId;
				}
			}
		}
		
		this.overlayBox = new OverlayBox({
			isCloseable: true
		});
		let scope = this;
		ObjectTranslation.getObjectNameTranslation(AddressBookEntry.OBJECT_DESCRIPTION_ID, function(objectNameTranslation){
				scope.overlayBox.getHeader().text(objectNameTranslation.name);
				}
			);
		var table = new InputTable(1);
		this.overlayBox.append(table);
		this.selectAbe = table.addSelect(StandortAddressBookEntry.ADDRESS_BOOK_ENTRY_FIELD_DESCRIPTION_ID ,AddressBookEntry.ADDRESS_BOOK_ENTRY_SELECT_ID);
		this.createSelectOptions();
		
		let saveButton = Button.createSaveButton();
		this.overlayBox.getFooter().append(saveButton);
		saveButton.click(function(event){
			let addressBookEntryStandort = new Object();
			addressBookEntryStandort.addressBookEntry = new Object();
			addressBookEntryStandort.addressBookEntry.id = scope.selectAbe.val();
			addressBookEntryStandort.standort = scope.standort;
			
			AddressBookEntry.connectToStandort(addressBookEntryStandort, function(savedAddressBookEntryStandort){
				scope.overlayBox.remove();
				let event = new EVAEvent({
					type: EVAEvent.TYPE_SAVE,
					data: savedAddressBookEntryStandort
				});
				scope.notify(event);
			});
		});
		
		let newButton = Button.createNewButton();
		this.overlayBox.getFooter().append(newButton);
		newButton.click( function () {
			let editAddressBookEntryOverlay = new EditAddressBookEntryOverlay({
				eventCallback:function(event){
					scope.addSelectOption(event.data);
					}
			});
		});
	}
	
	createSelectOptions() {
		let option = $('<option value="" >--</option>');
		this.selectAbe.append(option);
		let scope = this;
		AddressBookEntry.loadAll(function(addressBookEntries){
			let arrayLength = addressBookEntries.length;
			for (let i = 0; i < arrayLength; i++) {
				scope.addSelectOption(addressBookEntries[i]);
			}
		});
	}
	
	addSelectOption(addressBookEntry) {
		let option = $('<option value="' + addressBookEntry.id + '" ></option>');
		this.selectAbe.append(option);
		option.text(addressBookEntry.lastName + ', ' + addressBookEntry.firstName);
	}
	
}
export default NewStandortAddressBookEntryOverlay;