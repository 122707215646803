import UI from "../defaultUi.js";
import LabeledTableCell from "./LabeledTableCell.js";
class LabeledTable extends UI{
	
	constructor(config){
		super();
		this.initLabeledTable(config);
	}
	
	initLabeledTable(config) {
		if (config) {
			if (config.columnCount) {
				this.columnCount = config.columnCount;
			}
			this.realTable = config.realTable;
		}
		if (!this.columnCount) {
//			console.log('no columnCount set to default: ' + LabeledTable.DEFAULT_COLUMN_COUNT)
			this.columnCount = LabeledTable.DEFAULT_COLUMN_COUNT;
		}
		if (this.realTable) {
			this.tableDiv = $('<table class="showtable"></table>');
		} else {
			this.tableDiv = $('<div class="showtable"></div>');
		}
		super.setBaseJQueryObject(this.tableDiv);
		if(this.realTable){
			for (let i = 0; i < this.columnCount; i++) {
				let colGroup = $('<colgroup width="' + 100/this.columnCount +'%">');
				this.tableDiv.append(colGroup);
				let col = $('<col  width="1%" style="white-space: nowrap">');
				colGroup.append(col);
				col = $('<col>');
				colGroup.append(col);
			}
			this.tableBody = $('<tbody></tbody>');
			this.tableDiv.append(this.tableBody);
		} else {
			let columnWidth = 100 / this.columnCount;
			for (let i = 0; i < this.columnCount; i++) {
				let colGrouping = $('<div style="display: table-column-group;width: ' + columnWidth + '%;"><div style="display: table-column;width: 1%;"></div><div style="display: table-column;"></div></div>');
				this.tableDiv.append(colGrouping);
				this.tableBody = this.tableDiv;
			}
		}
		this.curentColumn = this.columnCount; // set to max so a new row will be created;
	}

	appendNewRow(){
		if(this.realTable){
			this.curentTableRowDiv = $('<tr></tr>');
		} else {
			this.curentTableRowDiv = $('<div class="showtable_row"></div>');
		}
		this.tableBody.append(this.curentTableRowDiv);
		this.curentColumn = 0;
	}
	
	append(toAppend){
		if (toAppend instanceof LabeledTableCell) {
			let colSpan = ((toAppend.colSpan + 1) / 2);
			this.appendNewRowIfNeeded(colSpan);
			this.curentColumn += colSpan;
			this.curentTableRowDiv.append(toAppend.getLabelCell());
			this.curentTableRowDiv.append(toAppend.getContentCell().getJQueryRepresentation());
		} else {
			console.log('can not append this object ' + toAppend);
		}
	}
	
	appendNewRowIfNeeded(colSpan) {
		if (this.curentColumn + colSpan > this.columnCount) {
			this.appendNewRow();
		}
	}
	
	clear(){
		
	}
}
LabeledTable.DEFAULT_COLUMN_COUNT = 2;
export default LabeledTable;