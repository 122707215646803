import MeterPoint from "../../../objects/MeterPoint.js";
import SortableListBoxGroup from "../../table/SortableListBoxGroup.js";
import Objects from "../../../objects/Objects.js";

class MeterPointList extends SortableListBoxGroup {

	constructor(config){
		super(config);
		let scope = this;
		if (config) {
			if (config.superordinateMeterPoint) {
				this.setSuperordinateMeterPoint(config.superordinateMeterPoint);
			} else if (config.superordinateMeterPointId) {
				MeterPoint.load(config.superordinateMeterPointId, function(superordinateMeterPoint){
					scope.setSuperordinateMeterPoint(superordinateMeterPoint);
				});
			}
		}
	}
	
	setSuperordinateMeterPoint(superordinateMeterPoint) {
		this.contentHeadline.setHeadlineByTranslation("messpunkt.subordinateMeterPointList");
		this.superordinateMeterPoint = superordinateMeterPoint;
		if (superordinateMeterPoint == null) {
			return;
		}
		this.hideNewButton();
		let scope = this;
		MeterPoint.loadAllBySuperPoint(this.superordinateMeterPoint.id, function(meterPoints){
			scope.pushAll(meterPoints);
		});
	}
	
	renderHead() {
		this.addSortHeaderByFDI(MeterPoint.NAME_FIELD_DESCRIPTION_ID, 'name');
		this.addSortHeaderByFDI(MeterPoint.MEDIUM_FIELD_DESCRIPTION_ID, 'medium.name');
		this.addSortHeaderByFDI(MeterPoint.ADJUSTING_TYPE_FIELD_DESCRIPTION_ID, 'bereinigungsart.name');
		this.addSortHeaderByFDI(MeterPoint.CONTRACT_FIELD_DESCRIPTION_ID, 'vertrag');
		this.addSortHeaderByFDI(MeterPoint.START_DATE_FIELD_DESCRIPTION_ID, 'beginn');
		this.addSortHeaderByFDI(MeterPoint.END_DATE_FIELD_DESCRIPTION_ID, 'ende');
	}

	renderRow(meterPoint, row) {
		let scope = this;
		row.addCellDomObj(meterPoint.getLink());
		meterPoint.getMedium(function(medium){
			row.addCell(medium.name);
		});
		meterPoint.getAdjustingType(function(adjustingType){
			row.addCell(adjustingType.name);
		});
		row.addText(meterPoint.vertrag);
		row.addDate(meterPoint.beginn);
		row.addDate(meterPoint.ende);
	}
}
export default MeterPointList;