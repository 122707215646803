import UI from "../../defaultUi.js";
import MeterPoint from "../../../objects/MeterPoint.js";
import MeterPointDetailGroupBox from "./MeterPointDetailGroupBox.js";
import Div from "../../Div.js";
import MeterList from "./MeterList.js";
import ConsumptionList from "./ConsumptionList.js";
import MeterPointFloorAreaList from "./MeterPointFloorAreaList.js";
import MeterPointConsumptionAnalysis from "./MeterPointConsumptionAnalysis.js";
import CorrectionValueList from "../correctionValue/CorrectionValueList.js";
import MeterPointList from "./MeterPointList.js";
import CommentList from "../comment/CommentList.js";

class ShowMeterPoint extends UI {
	constructor(config) {
		super();
		this.showDiv = new Div();
		super.setBaseJQueryObject(this.showDiv);
		let scope = this;
		if (config) {
			if (config.meterPoint) {
				this.setMeterPoint(standort);
			} else if(config.meterPointId) {
				MeterPoint.load(config.meterPointId, function(meterPoint){
					scope.setMeterPoint(meterPoint);
				});
			} else {
				this.setMeterPoint(null);
			}
		}
	}
	
	setMeterPoint(meterPoint) {
		this.meterPoint = meterPoint;
		this.showDiv.clear();
		let meterPointDetailGroupBox = new MeterPointDetailGroupBox({
			meterPoint: meterPoint
		});
		this.showDiv.append(meterPointDetailGroupBox);
		let meterList = new MeterList({
			meterPoint: meterPoint
		});
		this.showDiv.append(meterList);
		meterList.show();
		let consumptionList = new ConsumptionList({
			meterPoint: meterPoint
		});
		this.showDiv.append(consumptionList);
		let meterPointFloorAreaList = new MeterPointFloorAreaList({
			meterPoint: meterPoint
		});
		this.showDiv.append(meterPointFloorAreaList);
		let meterPointConsumptionAnalysis = new MeterPointConsumptionAnalysis({
			meterPoint: meterPoint
		});
		this.showDiv.append(meterPointConsumptionAnalysis);
		let correctionValueList = new CorrectionValueList({
			meterPoint: meterPoint
		});
		this.showDiv.append(correctionValueList);
		let meterPointList = new MeterPointList({
			superordinateMeterPoint: meterPoint
		});
		this.showDiv.append(meterPointList);
		meterPointList.show();
		let commentList = new CommentList({
			objectTypeId: MeterPoint.DESCRIPTON_ID,
			objectId: meterPoint.id
		});
		this.showDiv.append(commentList);
	}
}
export default ShowMeterPoint;