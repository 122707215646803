import regeneratorRuntime from "regenerator-runtime";

class RequestChain {
	
	constructor(){
		this.chain = [];
		this.running = false;
	}
		
	async push(request){
		this.chain.push(request);
		if (!this.running) {
			this.running = true;
			this.next();
		}
	}
	
	next() {
		if (this.chain.length < 1) {
			this.running = false;
			return;
		}
		let nextRequest = this.chain.pop();
		try {
			nextRequest.run();
		} catch(error) {
			  console.error(error);
			  this.next();
		}
		
	}
}

export default RequestChain;
