import UI from "./defaultUi.js";
class BoxFooter extends UI{
	constructor() {
		super();
		this.footerDiv = $('<div class="content_box"></div>');
		super.setBaseJQueryObject(this.footerDiv);
	}
	
	append(toAppend) {
		if (toAppend instanceof UI) {
			this.footerDiv.append(toAppend.getJQueryRepresentation());
		} else{
			this.footerDiv.append(jqObject);
		}
	}
}
export default BoxFooter;