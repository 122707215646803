import UI from "../../defaultUi.js";
import SortableListBoxGroup from "../../table/SortableListBoxGroup.js";
import AddressBookEntry from "../../../objects/AddressBookEntry.js";
import NewStandortAddressBookEntryOverlay from "./NewStandortAddressBookEntryOverlay.js";
import StandortAddressBookEntry from "../../../objects/StandortAddressBookEntry.js";
import ShowAddressBookEntryOverlay from "../addressBookEntry/ShowAddressBookEntryOverlay.js";
import Translation from "../../../objects/Translation.js";
import Button from "../../Button.js";

class StandortAddressBookEntryList extends SortableListBoxGroup{
	constructor(config) {
		super();
		this.standort = config.standort;
		if (this.standort == null) {
			if (config.standortId) {
				this.standort = new Object();
				this.standort.id = config.standortId;
			}
		}
		let scope = this;
		this.contentHeadline.setHeadlineById(AddressBookEntry.OBJECT_DESCRIPTION_ID);
		this.contentHeadline.getNewButton().click(function(event) {
			let newStandortAddressBookEntryOverlay = new NewStandortAddressBookEntryOverlay({
				standort: scope.standort,
				eventCallback: function(event) {
//					scope.pushRow(event.data, true);
					scope.load();
				}
			});
		});
		Translation.load("AddressBookEntry wirklich loeschen?", function(translation){
			scope.confirmDeleteText = translation.text;
		});
		this.load();
	}
	
	load() {
		this.rowDatas.clear();
		let scope = this;
		AddressBookEntry.allByStandort(this.standort, function(addressBookEntryList){
			scope.pushAll(addressBookEntryList);
		});
	}

	renderHead() {
		let cell = this.table.headerAppendLable(AddressBookEntry.JOB_FIELD_DESCRIPTION_ID);
		cell = this.table.headerAppendLable(AddressBookEntry.LAST_NAME_FIELD_DESCRIPTION_ID);
		cell = this.table.headerAppendLable(AddressBookEntry.FIRST_NAME_FIELD_DESCRIPTION_ID);
		cell = this.table.headerAppendLable(AddressBookEntry.PHONE_NUMBER_FIELD_DESCRIPTION_ID);
		cell = this.table.headerAppendLable(AddressBookEntry.EMAIL_FIELD_DESCRIPTION_ID);
	}
	
	renderRow(entry, row) {
		row.addCell(entry.job);
		let lastNameA = $('<a/>');
		let scope = this;
		lastNameA.click(function(event) {
				event.preventDefault();
				new ShowAddressBookEntryOverlay({
					addressBookEntry: entry,
					eventCallback: function(event) {
						scope.rerender();
					}
				});
				return false; 
			});
		lastNameA.text(entry.lastName);
		row.addCellDomObj(lastNameA);
		row.addCell(entry.firstName);
		row.addPhoneNumberTableCell(entry.phoneNumber);
		row.addEmailAddress(entry.email);
		let deletButton = Button.createDeleteButton();
		deletButton.click(function(event) {
			let r = confirm(scope.confirmDeleteText);
			if (r == true) {
				StandortAddressBookEntry.remove(scope.standort.id, entry.id, function(data){
					scope.load();
				});
			}
			});
		row.addCellDomObj(deletButton);
	}
}
export default StandortAddressBookEntryList;