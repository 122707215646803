import Objects from "./Objects.js";
import CachedLoadChain from "../misc/CachedLoadChain.js";
import Translation from "./Translation.js";

class BalanceGroup {
	
	constructor(obj) {
		obj && Object.assign(this, obj);
	}
	
	static load(id, callback){
		BalanceGroup.cachedLoadChain.load(id, function(balanceGroup){
			balanceGroup = new BalanceGroup(balanceGroup);
			callback(balanceGroup);
		});
	}
	
	static loadAll(callback){
		Objects.load(function(data){
			let arrayLength = data.length;
			let balanceGroups = [];
			for (let i = 0; i < arrayLength; i++) {
				let balanceGroup = new BalanceGroup(data[i]);
				balanceGroups.push(balanceGroup);
				BalanceGroup.cachedLoadChain.cache[balanceGroup.id] = balanceGroup;
			}
			callback(balanceGroups);
		}, BalanceGroup.API_ENDPOINT + "all");
	}
	
	getTranslation(callback) {
		if (this.nameTranslation) {
			callback(this.nameTranslation); 
		}
		let scope = this;
		Translation.load(this.name, function(translation){
			scope.nameTranslation = translation.text;
			callback(translation.text);
		});
	}
	
	getUnit() {
		if (this.id == BalanceGroup.WATER_ID) {
			return 'm³';
		}
		return 'kWh';
	}
}
BalanceGroup.API_ENDPOINT = "/api/BalanceGroup/";
BalanceGroup.cachedLoadChain = new CachedLoadChain(BalanceGroup.API_ENDPOINT);

BalanceGroup.ELECTRICITY_ID = 1;
BalanceGroup.WATER_ID = 2;
BalanceGroup.HEAT_ID = 3;
export default BalanceGroup;