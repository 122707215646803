import Objects from "./Objects.js";

class History {
	static loadAll(objectTypeId, objectId, callback){
		Objects.load(callback, "/api/History/" + objectTypeId + "/" + objectId);
	}
}
History.OBJECT_DESCRIPTION_ID = 32;
History.CREATED_BY_FIELD_ID = 84;
History.CHANGED_FIELD_ID = 83;
History.OBJECT_DESCRIPTION_FIELD_ID = 81;
History.OBJECT_FIELD_ID = 82;
export default History;