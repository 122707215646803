import Ui from "../defaultUi.js";
import LabeledTable from "./LabeledTable.js";
import LabeledTableCell from "./LabeledTableCell.js";
import LabeledNumberTableCell from "./LabeledNumberTableCell.js";
import LabeledDateTableCell from "./LabeledDateTableCell.js";
import LabeledDateTimeTableCell from "./LabeledDateTimeTableCell.js";
import LabeledFileSizeTableCell from "./LabeledFileSizeTableCell.js";
import LabeledBooleanTableCell from "./LabeledBooleanTableCell.js";
import LabeledEmailAddressTableCell from "./LabeledEmailAddressTableCell.js";
import LabeledPhoneNumberTableCell from "./LabeledPhoneNumberTableCell.js";

class DetailTable extends Ui{
	constructor(config) {
		super(config);
		this.labeledTable = new LabeledTable({
			realTable:true
		});
		super.setBaseJQueryObject(this.labeledTable);
	}
	
	addText(fieldId, text){
		let cell = new LabeledTableCell({
			fieldId: fieldId,
			contentText: text,
			realTable:true
		});
		this.labeledTable.append(cell);
		return cell;
	}
	
	addTextBig(fieldId, text){
		let cell = new LabeledTableCell({
			fieldId: fieldId,
			contentText: text,
			realTable:true,
			colSpan: 3
		});
		this.labeledTable.append(cell);
		return cell;
	}
	
	addFieldCell(fieldId){
		let cell = new LabeledTableCell({
			fieldId: fieldId,
			realTable:true
		});
		this.labeledTable.append(cell);
		return cell;
	}
	
	addFieldCellBig(fieldId){
		let cell = new LabeledTableCell({
			fieldId: fieldId,
			realTable:true,
			colSpan: 3
		});
		this.labeledTable.append(cell);
		return cell;
	}
	
	addDate(fieldId, date){
		let cell = new LabeledDateTableCell({
			fieldId: fieldId,
			date: date,
			realTable:true
		});
		this.labeledTable.append(cell);
		return cell;
	}
	
	addDateTime(fieldId, date){
		let cell = new LabeledDateTimeTableCell({
			fieldId: fieldId,
			date: date,
			realTable:true
		});
		this.labeledTable.append(cell);
		return cell;
	}
	
	addNumber(fieldId, number, unit){
		let cell = new LabeledNumberTableCell({
			fieldId: fieldId,
			number: number,
			unit: unit,
			realTable:true
		});
		this.labeledTable.append(cell);
		return cell;
	}
	
	addPercent(fieldId, percent){
		if (percent != null) {
			percent *= 100;
		}
		return this.addNumber(fieldId, percent, '%');
	}
	
	addFileSize(fieldId, fileSize){
		let cell = new LabeledFileSizeTableCell({
			fieldId: fieldId,
			fileSize: fileSize,
			realTable:true
		});
		this.labeledTable.append(cell);
		return cell;
	}
	
	addBoolean(fieldId, boolean) {
		let cell = new LabeledBooleanTableCell({
			fieldId: fieldId,
			boolean: boolean,
			realTable:true
		});
		this.labeledTable.append(cell);
		return cell;
	}
	
	addEmailAddress(fieldId, emailAddress) {
		let cell = new LabeledEmailAddressTableCell({
			fieldId: fieldId,
			emailAddress: emailAddress,
			realTable:true
		});
		this.labeledTable.append(cell);
		return cell;
	}
	
	addPhoneNumber(fieldId, phoneNumber) {
		let cell = new LabeledPhoneNumberTableCell({
			fieldId: fieldId,
			phoneNumber: phoneNumber,
			realTable:true
		});
		this.labeledTable.append(cell);
		return cell;
	}
	
	clear(){
		this.labeledTable.clear();
	}
}
export default DetailTable