import UiElement from "./UiElement.js";
import BoxOverlayContent from "./content/overlayBox/BoxOverlayContent.js"
import OverlayCloseCross from "./OverlayCloseCross.js";
import ContentHeadline from "./content/ContentHeadline.js";
import ContentBox from "./ContentBox.js";
import BoxFooter from "./BoxFooter.js";
import Button from "./Button.js";

class OverlayBox extends UiElement{
	
	constructor(config) {
		super(config);

		this.overlayBox = $('<div class="boxOverlay"></div>');
		super.setBaseJQueryObject(this.overlayBox);
		this.boxOverlayContent = new BoxOverlayContent();
		//$('<div class="boxOverlay-content content_box_group"></div>');
		this.getHeader();
		this.contentContentBox = new ContentBox();
		this.boxOverlayContent.append(this.contentContentBox);
		this.overlayBox.append(this.boxOverlayContent.getJQueryRepresentation());
		if (config != null) {
			if (config.isCloseable != null && config.isCloseable) {
				let footer = this.getFooter();
				
				let closeButton = new Button({
					buttonText: "close"
				});
				footer.append(closeButton);
				let scope = this;
				closeButton.click(function(event){
					scope.remove();
				});
				this.overlayBox.click(function(event){
					if($(event.target).is(scope.overlayBox)){
						scope.remove();
					}
				});
				let closeCross = new OverlayCloseCross();
				this.getHeader().append(closeCross);
				closeCross.click(function(event){
					scope.remove();
				});
				
			}
			if (config.autoAdd == null || config.autoAdd) {
				this.appendToUi();
			}
		}
	}
	
	append(toAppend) {
		this.contentContentBox.append(toAppend);
	}
	
	clear() {
		this.contentContentBox.clear();
	}
	
	text(text) {
		return this.contentContentBox.text(text);
	}
	
	getHeader() {
		if (this.header == null) {
			this.header = new ContentHeadline();
			this.boxOverlayContent.append(this.header);
		}
		return this.header;
	}
	
	getFooter() {
		if (this.footer == null) {
			this.footer = new BoxFooter();
			this.boxOverlayContent.append(this.footer);
		}
		return this.footer;
	}
	
}
export default OverlayBox;