import UI from "../defaultUi.js";
class InputGroupAddon extends UI{
	constructor(config){
		super();
		this.inputgroupaddon = $('<span class="inputgroupaddon"></span>');
		super.setBaseJQueryObject(this.inputgroupaddon);
		if (config != null) {
			if(config.text != null){
				this.text(config.text);
			}
		}
	}
	
	text(text){
		return this.inputgroupaddon.text(text);
	}
}
export default InputGroupAddon;