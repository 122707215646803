import Objects from "./Objects.js";
import UI from "../ui/defaultUi.js";
import Translation from "./Translation.js";
import TableHeaderLabel from "../ui/table/TableHeaderLabel.js";
import FileUploadOverlay from "../ui/objects/file/FileUploadOverlay.js";
import EVAEvent from "../ui/EVAEvent.js";

class File{
	
	constructor(fileData){
		fileData && Object.assign(this, fileData);
	}
	
	getPriviusVersions() {
		let priviusVersionsList = [];
		if (this.previousVersion == null) {
			return priviusVersionsList;
		}
		if (!(this.previousVersion instanceof File)) {
			this.previousVersion = new File(this.previousVersion);
		}
		priviusVersionsList.push(this.previousVersion);
		let prePreviousVersions = this.previousVersion.getPriviusVersions();
		let arrayLength = prePreviousVersions.length;
		for (let i = 0; i < arrayLength; i++) {
			priviusVersionsList.push(prePreviousVersions[i]);
		}
		return priviusVersionsList;
	}
	
	uploadUrl() {
		if (this.priviusVersion) {
			return File.ENDPOINT + "uploadNewVersion?objectTypeId=" + this.objectTypeId + "&objectId=" + this.objectId + "&priviusVersion=" + this.priviusVersion;
		} else {
			return File.ENDPOINT + "upload/" + this.objectTypeId + "/" + this.objectId;
		}
	}
	
	
	static remove(fileId, callback) {
		Objects.remove(callback, File.ENDPOINT + fileId);
	}
	
	/*
	static update(entry, callback){
		Objects.save(entry, callback, "/api/File/update");
	};*/
	
	static load(id, callback){
		Objects.load(callback, File.ENDPOINT + id);
	}
	
	static loadAll(objectTypeId, objectId, callback){
		Objects.load(callback, File.ENDPOINT + "all/" + objectTypeId + "/" + objectId);
	}
	
	static createFilesDiv(objectTypeId, objectId, divTofill) {
		divTofill.html("");
		var groupBox = $('<div class="content_box_group"></div>');
		divTofill.append(groupBox);
		
		let fileUI = new FileUI({
			objectTypeId: objectTypeId,
			objectId: objectId,
		});
		groupBox.append(fileUI.getJQueryRepresentation());
		let contentBox = $('<div id="filesList"></div>');
		groupBox.append(contentBox);
	}
	
	static showFilesList(event){
		var divTofill = $('#' + event.data.divId);
		divTofill.html('');
		File.loadAll(event.data.objectTypeId,event.data.objectId, function(filesList){
					var listTable = new ListTable(2);
					divTofill.append(listTable.getTableDiv());
					listTable.newRow();
					listTable.addCellDomObj(new TableHeaderLabel({fieldId: File.FILE_FIELD_DESCRIPTION_ID}));
					var arrayLength = filesList.length;
					for (var i = 0; i < arrayLength; i++) {
						var file = filesList[i];
						File.createListRow(file, listTable);
					}
				}
			);
	}
	
	static createListRow(file, listTable) {
		listTable.newRow();
		var fileNameA = $('<a/>');
		fileNameA.click({fileId: file.id}, function(event) {event.preventDefault();
				File.showFile(event);
				return false; 
			});
		fileNameA.text(file.name);
		listTable.addCellDomObj(fileNameA);
		var downloadButton = $('<button type="button" value="download" ><span class="glyphicon glyphicon-save-file" aria-hidden="true"></span></button>');
		Translation.load('download', function(translation){
			var translationSpan = $('<span></span>')
			downloadButton.append(translationSpan);
			translationSpan.text(' ' + translation.text);
		});
		downloadButton.click({file: this}, function(event) {
				window.location.assign(Objects.rootURL + File.ENDPOINT + 'download/' + file.id + '/' + file.name);
			});
		listTable.addCellDomObj(downloadButton);
	}
	
	static showFile(event){
		
	}
	
	static newFileOverlay(event){
		let fileUploadOverlay = new FileUploadOverlay({
			objectTypeId: event.data.objectTypeId,
			objectId: event.data.objectId
		});
	}
}
File.ENDPOINT = "/api/File/";
File.OBJECT_DESCRIPTION_ID = 29;
File.FILE_FIELD_DESCRIPTION_ID = 76;
File.UPLOADED_FDI = 87;
File.SIZE_FDI = 88;
File.FILE_INPUT_ID = 'fileInput';
File.READ_ROLE_ID = 16;
File.WRITE_ROLE_ID = 15;

File.NEW_FILE_OVERLAY_ID = 'uploadFileOl';
File.EDIT_FORM_ID = 'editFileForm';

export default File;

import ListDivHeader from "../ui/content/ListDivHeader.js";
import ObjectTranslation from "./ObjectTranslation.js";
import ListTable from "../ui/table/ListTable.js";
import InputTable from "../ui/table/InputTable.js"; 

class FileUI extends UI {

	constructor(config) {
		super();
		this.objectTypeId = config.objectTypeId;
		this.objectId = config.objectId;

		this.listDivHeader = new ListDivHeader;
		super.setBaseJQueryObject(this.listDivHeader.getJQueryRepresentation());
		var scope = this;
		ObjectTranslation.getObjectNameTranslation(File.OBJECT_DESCRIPTION_ID, function(objectNameTranslation){
				scope.listDivHeader.text(objectNameTranslation.namePlural);
			});
		this.listDivHeader.getShowButton().click(function(event) {
			File.showFilesList(event);
			}, {
				objectTypeId: this.objectTypeId,
				objectId: this.objectId,
				divId: "filesList"
			});
		this.listDivHeader.getNewButton().click(function(event) {
			File.newFileOverlay(event);
		}, {
			objectTypeId: this.objectTypeId,
			objectId: this.objectId,
			divId: "filesList"
		});
		this.listDivHeader.getHideButton().click(function(event) {
			$('#filesList').html('');
		}, {});
	}
}