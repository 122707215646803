import DetailTable from "../../table/DetailTable.js";
import Meter from "../../../objects/Meter.js";

class MeterDetailTable extends DetailTable{
	constructor(config) {
		super(config);
		if (config != null) {
			if (config.meter != null) {
				this.setMeter(config.meter);
			}
		}
	}
	
	setMeter(meter) {
		this.meter = meter;
		let standortCell = this.addFieldCellBig(Meter.STANDORT_FIELD_DESCRIPTION_ID);
		meter.getStandort(function(standort){
			if (standort == null) {
				standortCell.text("--");
				return;
			}
			standortCell.getContentCell().append(standort.getLink());
		});
		let meterPointCell = this.addFieldCell(Meter.METER_POINT_FIELD_DESCRIPTION_ID);
		meter.getMeterPoint(function(meterPoint){
			if (meterPoint == null) {
				meterPointCell.text("--");
				return;
			}
			meterPointCell.getContentCell().append(meterPoint.getLink());
		});
		this.addBoolean(Meter.READ_FIELD_DESCRIPTION_ID, meter.ablesen);
		this.addText(Meter.METER_NUMBER_FIELD_DESCRIPTION_ID, meter.nr);
		let mediumUnitCell = this.addFieldCell(Meter.UNIT_FIELD_DESCRIPTION_ID);
		meter.getMediumUnit(function(mediumUnit){
			if (mediumUnit == null) {
				mediumUnitCell.text("--");
				return;
			}
			mediumUnitCell.getContentCell().append(mediumUnit.name);
		});
		this.addNumber(Meter.METER_FACTOR_FIELD_DESCRIPTION_ID ,meter.faktor);
	}
	
}
export default MeterDetailTable