import SortableListBoxGroup from "../../table/SortableListBoxGroup.js";
import User from "../../../objects/User.js";
import Objects from "../../../objects/Objects.js";
import EditUserOverlay from "./EditUserOverlay.js";
import EVAEvent from "../../EVAEvent.js";

class UserList extends SortableListBoxGroup {
	constructor(config) {
		super(config);
		this.licensId = config.licensId;

		this.contentHeadline.setHeadlineById(User.DESCRIPTON_ID);
		
		let scope = this;
		this.contentHeadline.getNewButton().click(function(event) {
			new EditUserOverlay({
				eventCallback: function(event){
					if (event.type == EVAEvent.TYPE_SAVE) {
						scope.pushRow(event.data, true);
					}
				},
				license: scope.licensId
			});
		}, {});
//		this.sortBy("name");
		
		if (config.users) {
			this.setUsers(config.users);
		} else {
			this.load();
		}
	}
	
	load() {
		let scope = this;
		User.loadAll(this.licensId, function(users){
			scope.setUsers(users);
		});
	}
	
	setUsers(filesList) {
		this.rowDatas.clear();
		this.pushAll(filesList);
	}

	renderHead() {
		let scope = this;
		let cell = this.table.headerAppendLable(User.USER_NAME_FIELD_DESCRIPTION_ID);
		cell.click(function(){
			scope.sortBy('username');
		});
		cell = this.table.headerAppendLable(User.FIRST_NAME_FIELD_DESCRIPTION_ID);
		cell.click(function(){
			scope.sortBy('firstName');
		});
		cell = this.table.headerAppendLable(User.LAST_NAME_FIELD_DESCRIPTION_ID);
		cell.click(function(){
			scope.sortBy('lastName');
		});
		cell = this.table.headerAppendLable(User.LAST_LOGIN_FIELD_DESCRIPTION_ID);
		cell.click(function(){
			scope.sortBy('lastlogin');
		});
	}
	
	renderRow(entry) {
		let scope = this;
		let usernameA = $('<a href="' + Objects.rootURL + '/Frontend/User/page.jsp?userToEdit=' + entry.id + '"></a>');
//		fileNameA.click({fileId: entry.id}, function(event) {
//				event.preventDefault();
//				new FileDetailOverlay({
//					file: entry
//				});
//				return false; 
//			});
		usernameA.text(entry.getDisplayUsername());
		this.table.addCellDomObj(usernameA);
		this.table.addText(entry.firstName);
		this.table.addText(entry.lastName);
		this.table.addDate(entry.lastlogin);
	}
}
export default UserList;