import UI from "../../defaultUi.js";
import ContentBoxGroup from "../../content/ContentBoxGroup.js";
import ContentHeadline from "../../content/ContentHeadline.js";
import ContentBox from "../../ContentBox.js";
import Translation from "../../../objects/Translation.js";
import Button from "../../Button.js";
import Objects from "../../../objects/Objects.js";
import MonthlyPlot from "./analysis/MonthlyPlot.js";
import YearlyPlot from "./analysis/YearlyPlot.js";

class MeterPointConsumptionAnalysis extends UI{
	constructor(config) {
		super(config);
		
		if (config) {
			if (config.meterPoint) {
				this.meterPoint = config.meterPoint;
			}
		}
		
		this.contentBoxGroup = new ContentBoxGroup();
		super.setBaseJQueryObject(this.contentBoxGroup);
		this.header = new ContentHeadline();
		this.contentBoxGroup.append(this.header);
		
		let scope = this;
		Translation.load("Auswertung", function(translation){
			scope.header.text(translation.text + ": ");
		});

		this.header.getShowButton();
		this.header.getHideButton().hide();

		this.isDisplayed = false;
		
		this.header.getShowButton().click(function(){
			scope.isDisplayed = true;
			scope.renderGraphs();
		});
		
		this.header.getHideButton().click(function(){
			scope.isDisplayed = false;
			if (scope.contentBoxLocal != null) {
				scope.contentBoxLocal.remove();
			}
		});
//		this.createGraphs();
	}
	
	createGraphs() {
		this.mPlot = new MonthlyPlot({
			meterPoint: this.meterPoint
		});
		this.yPlot = new YearlyPlot({
			meterPoint: this.meterPoint
		});
	}
	
	renderGraphs() {
		this.contentBoxLocal = new ContentBox();
		this.contentBoxGroup.append(this.contentBoxLocal);
		
		if (this.mPlot == null || this.yPlot == null) {
			this.createGraphs();
		}
		
		this.contentBoxLocal.append(this.mPlot);
		this.mPlot.render();
		
		this.contentBoxLocal.append(this.yPlot);
		this.yPlot.render();
	}
}
export default MeterPointConsumptionAnalysis;