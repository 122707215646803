import DynamicText from "../../../misc/DynamicText.js";
import UiElement from "../../UiElement.js";
import ContentBoxGroup from "../../content/ContentBoxGroup.js";
import ContentHeadline from "../../content/ContentHeadline.js";
import ContentBox from "../../ContentBox.js";

class UnsubscribeNewsletter extends UiElement{
	constructor(config) {
		super(config);
		if(config && config.text) {
			this.dynamicPageText = new DynamicText({
				text: config.text
			});
		} else {
			this.dynamicPageText = new DynamicText({
				translationKey: UnsubscribeNewsletter.TRANSLATION_KEY
			});	
		}
		this.contentBoxGroup = new ContentBoxGroup();
		super.setBaseJQueryObject(this.contentBoxGroup);
		this.contentHeadline = new ContentHeadline(config);
		this.contentBoxGroup.append(this.contentHeadline);
		this.appendToUi();

		if (config) {
			if (config.emailAddress) {
				this.setEmailAddress(config.emailAddress);
			}
		}
	}
	
	setEmailAddress(emailAddress) {
		this.emailAddress = emailAddress;
		this.dynamicPageText.replace('emailAddress', emailAddress);
		this.contentBox = new ContentBox();
		this.contentBoxGroup.append(this.contentBox);
		this.contentBox.text(this.dynamicPageText.getText());
	}
}
UnsubscribeNewsletter.TRANSLATION_KEY = "UnsubscribeNewsletter";
export default UnsubscribeNewsletter;