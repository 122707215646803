import ContentHeadline from "./ContentHeadline.js";
import Button from "../Button.js";
import ObjectTranslation from "../../objects/ObjectTranslation.js";
import Translation from "../../objects/Translation.js";

class ListDivHeader extends ContentHeadline {

	constructor(config) {
		super(config);
		if (config == null || config.addDisplayAction == null || config.addDisplayAction) {
			this.getNewButton();
			this.getShowButton();
			this.getHideButton().hide();
		}
	}
	
	setHeadlineById(objectDescriptionId) {
		let scope = this;
		ObjectTranslation.getObjectNameTranslation(objectDescriptionId, function(objectNameTranslation){
				scope.text(objectNameTranslation.namePlural);
			});
	}
	
	setHeadlineByTranslation(text) {
		let scope = this;
		Translation.load(text, function(translation){
			scope.text(translation.text);
		});
	}
}
export default ListDivHeader;