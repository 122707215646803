import LabeledTableCell from "./LabeledTableCell.js";
class LabeledDateTableCell extends LabeledTableCell{
	constructor(config) {
		if (config == null) {
			config = new Object();
		}
		config.type = LabeledTableCell.TYPE_DATE;
		super(config);

		this.setDate(config.date);
	}
	
	setDate(date) {
		if (date) {
			let dateObj = new Date(date);
			this.getContentCell().text(dateObj.toLocaleDateString());
		} else {
			this.getContentCell().text("--");
		}
	}
	
}
export default LabeledDateTableCell;