import UI from "./defaultUi.js";

class Div extends UI{
	constructor(config) {
		super(config);
		this.div = $('<div></div>');
		super.setBaseJQueryObject(this.div);
	}
	
	append(toAppend) {
		if (toAppend instanceof UI) {
			this.div.append(toAppend.getJQueryRepresentation());
		} else{
			this.div.append(toAppend);
		}
	}
	
	clear() {
		this.div.html('');
	}
}
export default Div;