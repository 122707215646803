import UI from "../defaultUi.js";

class BooleanInput extends UI{
	constructor(config){
		super(config);
		this.booleanInput = $('<input type="checkbox" class="boolean_default" />');
		super.setBaseJQueryObject(this.booleanInput);
		if (config) {
			if (config.id) {
				this.booleanInput.attr('id', config.id);
			}
			if (config.val != null) {
				this.val(config.val);
			}
		}
	}
	
	setFieldError(){
		this.dateInput.addClass('RequiredFieldNotSet');
	}
	
	removeFieldError(){
		this.dateInput.removeClass('RequiredFieldNotSet');
	}
	
	
	uiInit() {
	}
	
	change(callback){
		this.booleanInput.change(callback);
	}
	
	trigger(event, data) {
		this.booleanInput.trigger(event, data);
	}

	val(newVal) {
		if (newVal != null) {
			return this.booleanInput.prop('checked', newVal);
		}
		return this.booleanInput.prop('checked');
	}
}
export default BooleanInput;