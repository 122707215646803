import UI from "../../defaultUi.js";
import OverlayBox from "../../OverlayBox.js";
import Button from "../../Button.js";
import InputTable from "../../table/InputTable.js";
import Goal from "../../../objects/Goal.js";
import EVAEvent from "../../EVAEvent.js";
import Observable from "../../../misc/Observable.js";

class EditGoalOverlay extends Observable {
	constructor(config) {
		super(config);
		if (config) {
			this.objectTypeId = config.objectTypeId;
			this.objectId = config.objectId;
			this.goal = config.goal;
		}
		let scope = this;
		this.overlayBox = new OverlayBox({
			isCloseable: true
		});
		let table = new InputTable(1);
		this.overlayBox.append(table);
		
		this.nameInputField = table.addInput(Goal.NAME_FIELD_ID, 'name_input');
		this.progressInputField = table.addPercentageInput(Goal.PROGRESS_FIELD_ID, 'progress_input');
		this.startInputField = table.addDateInput(Goal.START_FIELD_ID, 'start_input');
		this.endInputField = table.addDateInput(Goal.END_FIELD_ID, 'end_input');
		this.descriptionInputField = table.addTextArea(Goal.DESCRIPTION_FIELD_ID, 'description_input');
		
		if (this.goal) {
			this.nameInputField.val(this.goal.name);
			this.progressInputField.val(this.goal.progress);
			this.startInputField.val(this.goal.start);
			this.endInputField.val(this.goal.end);
			this.descriptionInputField.val(this.goal.description);
			
		}
		
		let saveButton = Button.createSaveButton()
		this.overlayBox.getFooter().append(saveButton);
		saveButton.click(function(event){
			if (scope.goal == null) {
				scope.goal = new Object();
				scope.goal.objectDescriptionId = scope.objectTypeId;
				scope.goal.objectId = scope.objectId;
			}
			scope.goal.name = scope.nameInputField.val();
			scope.goal.progress = scope.progressInputField.val();
			if (scope.startInputField.val() && scope.startInputField.val() != null){ 
				scope.goal.start = new Date(scope.startInputField.val());
			}
			if (scope.endInputField.val() && scope.endInputField.val() != null) {
				scope.goal.end = new Date(scope.endInputField.val());
			}
			scope.goal.description = scope.descriptionInputField.val();
			Goal.upsert(scope.goal, function(goal){
				scope.overlayBox.remove();
				let event = new EVAEvent({
					type: EVAEvent.TYPE_SAVE,
					data: goal
				});
				scope.notify(event);
			});
		});
	}
}
export default EditGoalOverlay;