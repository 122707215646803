import SortableListOverlay from "../../table/SortableListOverlay.js";
import History from "../../../objects/History.js";
import LabeledTableCell from "../../table/LabeledTableCell.js";
import LabeledTable from "../../table/LabeledTable.js";
import User from "../../../objects/User.js";
import Button from "../../Button.js";
import EVAEvent from "../../EVAEvent.js";
import ContentBox from "../../ContentBox.js";

class HistoryList extends SortableListOverlay{

	constructor(config) {
		super({
			isDivTable: true,
			addDisplayAction: false,
			objectDescriptionId: History.OBJECT_DESCRIPTION_ID,
			isCloseable: true
		});
		this.objectTypeId = config.objectTypeId;
		this.objectId = config.objectId;
		this.elementRenderer = config.elementRenderer;
		
		this.sortBy("created");

		this.load();
	}
	
	load() {
		let scope = this;
		History.loadAll(this.objectTypeId, this.objectId, function(historyList){
					scope.rowDatas.pushAll(historyList);
					scope.render();
				}
			);
	}
	
	renderHead() {
		//no head;
	}
	
	renderRow(entry){
		let contentBox = this.table.newRow();
		let scope = this;
		let labeledTable = new LabeledTable(2);
		contentBox.append(labeledTable);
		labeledTable.append(
				new LabeledTableCell({
					fieldId: History.CHANGED_FIELD_ID,
					contentText: (new Date(entry.created).toLocaleString())
				}));

		let createdByCell = new LabeledTableCell({
			fieldId: History.CREATED_BY_FIELD_ID
		})
		labeledTable.append(createdByCell);
		User.load(entry.createdBy, function(user){
			createdByCell.getContentCell().text(user.firstName + " " + user.lastName);
		});
		
		let displayChangeDiv = new ContentBox();
		contentBox.append(displayChangeDiv);
		displayChangeDiv.append(this.elementRenderer(entry.data));
	}
}
export default HistoryList;