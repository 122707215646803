import SortableListBoxGroup from "../table/SortableListBoxGroup.js";
import Standort from "../../objects/Standort.js";
import ObjectTranslation from "../../objects/ObjectTranslation.js";
import License from "../../objects/License.js";
import Label from "../content/Label.js"
import Objects from "../../objects/Objects.js";
import Env from "../../misc/Env.js";
import Link from "../content/Link.js"

class StandortList extends SortableListBoxGroup{
	constructor(config) {
		super(config);

		var scope = this;

		ObjectTranslation.getObjectNameTranslation(Standort.DESCRIPTON_ID, function(translation){
			scope.contentHeadline.text(translation.namePlural);
		});
		this.contentHeadline.getNewButton().click(function(event) {
			window.location.href = Objects.rootURL + "/Frontend/Standort/create/page.jsp";
		}, {});
		this.load();
		if (config) {
			if (config.action) {
				this.action = config.action;
			}
		}
	}
	
	load() {
		var scope = this;
		Standort.loadAll(function(standorts){
			let rerender = false;
			for (let i = 0; i < standorts.length; i++) {
				if (i ==  standorts.length -1) {
					rerender = true;
				}
				let entry = {};
				entry.standort = standorts[i];
				if (Env.isLizenzPackageManager) {
					License.load(entry.standort.lizenzID, function(license){
						entry.license = license;
						scope.pushRow(entry, rerender);
					});
				} else {
					scope.pushRow(entry, rerender);
				}
			}
		});
	}

	renderHead() {
		this.addSortHeaderByFDI(Standort.FIELD_NAME_ID, 'standort.name');
		this.addSortHeaderByFDI(Standort.FIELD_STREET_ID, 'standort.strasse');
		this.addSortHeaderByFDI(Standort.FIELD_ZIP_ID, 'standort.plz');
		this.addSortHeaderByFDI(Standort.FIELD_REGION_ID, 'standort.ort');
		this.addSortHeaderByFDI(Standort.FIELD_START_ID, 'standort.beginn');
		this.addSortHeaderByFDI(Standort.FIELD_END_ID, 'standort.ende');
		
		if (Env.isLizenzPackageManager) {
			var scope = this;
			ObjectTranslation.getObjectNameTranslation(License.DESCRIPTON_ID, function(translation){
				scope.addSortHeaderText(translation.name, 'license.art')
			});
		}
	}
	
	renderRow(entry, row) {
		let link = new Link({
			text:entry.standort.name
		});
		if(this.action == null){
			link.href(entry.standort.getURL());
		} else {
			let scope = this;
			link.click(function(){
				scope.action(entry.standort);
			});
		}
		row.addCellDomObj(link);
		row.addText(entry.standort.strasse);
		row.addText(entry.standort.plz);
		row.addText(entry.standort.ort);
		row.addDate(entry.standort.beginn);
		row.addDate(entry.standort.ende);
		if (Env.isLizenzPackageManager) {
			row.addText(entry.license.art);
		}
	}
}
export default StandortList;