import UI from "../../defaultUi.js";
import OverlayBox from "../../OverlayBox.js";
import EVAEvent from "../../EVAEvent.js";
import Observable from "../../../misc/Observable.js";
import FloorArea from "../../../objects/FloorArea.js";
import InputTable from "../../table/InputTable.js";
import Button from "../../Button.js";

class EditFloorArea extends OverlayBox {
	constructor(config) {
		if (config == null) {
			config = new Object();
		}
		config.isCloseable = true;
		super(config);
		this.standort = config.standort;
		let scope = this;
		let table = new InputTable(1);
		this.append(table);

		this.nameInputField = table.addTextInput(FloorArea.FIELD_NAME_ID, "name");
		this.areaInputField = table.addNumberUnitInput(FloorArea.FIELD_AREA_M2_ID,"area","m²");
		this.startInputField = table.addDateInput(FloorArea.FIELD_START_ID, "start_date");
		this.endInputField = table.addDateInput(FloorArea.FIELD_END_ID, "end_date");
		
		let saveButton = Button.createSaveButton()
		this.getFooter().append(saveButton);
		saveButton.click(function(event){
			if (scope.floorArea == null) {
				scope.floorArea = new FloorArea();
				if (scope.standort != null) {
					scope.floorArea.standortId = scope.standort.id;
				}
			}
			scope.floorArea.bezeichnung = scope.nameInputField.val();
			scope.floorArea.bg_flaeche_m2 = scope.areaInputField.val();
			scope.floorArea.start = scope.startInputField.val();
			scope.floorArea.end = scope.endInputField.val();
			scope.floorArea.upsert(function(floorArea){
				let event = new EVAEvent({
					type: EVAEvent.TYPE_SAVE,
					data: floorArea
				});
				scope.notify(event);
				scope.remove();
			});
		});
		this.setFloorArea(config.floorArea);
	}
	
	setFloorArea(floorArea) {
		if (floorArea == null) {
			this.floorArea = null;
			return;
		}
		this.floorArea = floorArea;
		this.nameInputField.val(floorArea.bezeichnung);
		this.areaInputField.val(floorArea.bg_flaeche_m2);
		this.startInputField.val(floorArea.start);
		this.endInputField.val(floorArea.end);
	}
	
	
	
}
export default EditFloorArea;