import Button from "../../Button.js";
import Translation from "../../../objects/Translation";
class ActivateUserButton extends Button{
	constructor(config) {
		super(config);
		if (config) {
			this.setUser(config.user);
			this.setCallback(config.callBack);
		}
		let scope = this;
		this.click(function() {
			if (scope.user.isActive()) {
				scope.user.deactivate(scope.callBack);
			} else {
				scope.user.activate(scope.callBack);
			}
		},null);
	}
	
	setUser(user) {
		this.user = user;
		if (user == null) {
			return;
		}
		if (this.user.isActive()) {
			this.textTranslate('deactivate');
			this.iconClass('glyphicon-remove-sign');
		} else {
			this.textTranslate('activate');
			this.iconClass('glyphicon-ok-sign');
		}	
	}
	
	setCallback(callBack) {
		this.callBack = callBack;
	}
}
export default ActivateUserButton;