import Observable from "../../../misc/Observable.js";
import UI from "../../defaultUi.js";
import OverlayBox from "../../OverlayBox.js";
import Button from "../../Button.js";
import InputTable from "../../table/InputTable.js";
import Comment from "../../../objects/Comment.js";
import EVAEvent from "../../EVAEvent.js";

class EditCommentOverlay extends Observable {
	constructor(config) {
		super(config);
		if (config) {
			this.objectTypeId = config.objectTypeId;
			this.objectId = config.objectId;
			this.comment = config.comment;
		}
		let scope = this;
		this.overlayBox = new OverlayBox({
			isCloseable: true
		});

		this.textArea = $('<textarea id="createCommentArea"></textarea>');
		this.overlayBox.append(this.textArea);
		
		if (this.comment != null) {
			this.textArea.val(this.comment.text);
		}

		let saveButton = Button.createSaveButton()
		this.overlayBox.getFooter().append(saveButton);
		saveButton.click(function(event){
			if (scope.comment == null) {
				scope.comment = new Object();
				scope.comment.objectDescriptionId = scope.objectTypeId;
				scope.comment.objectId = scope.objectId;
				scope.comment.date = new Date();
			}
			
			scope.comment.text = scope.textArea.val();
			
			Comment.save(scope.comment, function(comment){
				scope.overlayBox.remove();
				let event = new EVAEvent({
					type: EVAEvent.TYPE_SAVE,
					data: comment
				});
				scope.notify(event);
			});
		});
	}
}
export default EditCommentOverlay;