import UI from "../../../defaultUi.js";
import Translation from "../../../../objects/Translation.js";
import JqpDefaultSetting from "../../../plot/DefaultSetting.js";
import BalanceGroup from "../../../../objects/BalanceGroup.js";

class MonthlyPlot extends UI{
	constructor(config){
		super(config);
		this.isReady = false;
		this.plotId = config.typ + 'monthly_newplot';
		let plotDiv = $('<div id="' + this.plotId + '" class="jqplot-target" style="height: 400px; width: 1100px; position: relative;"></div>');
		super.setBaseJQueryObject(plotDiv);
		let scope = this;
		
		config.meterPoint.getBalanceGroup(function(balanceGroup){
			if (BalanceGroup.ELECTRICITY_ID == balanceGroup.id) {
				Translation.load('Strom',function(translElec){
					Translation.load('Verbrauch',function(translUsage){
						let plotTitel = translElec.text + ' ' + translUsage.text + ' (kWh)';
						let seriesLegend = scope.getSeriesLegend();
						scope.jqpDefaultSetting = new JqpDefaultSetting(plotTitel,seriesLegend);
					});
				});
			} else if (BalanceGroup.WATER_ID == balanceGroup.id) {
				Translation.load('Wasser',function(translElec){
					Translation.load('Verbrauch',function(translUsage){
						let plotTitel = translElec.text + ' ' + translUsage.text + ' (m&sup3;)';
						let seriesLegend = scope.getSeriesLegend();
						scope.jqpDefaultSetting = new App.JqpDefaultSetting(plotTitel,seriesLegend);
					});
				});
			} else if(BalanceGroup.HEAT_ID == balanceGroup.id){
				Translation.load('Waerme',function(translElec){
					Translation.load('Verbrauch',function(translUsage){
						let plotTitel = translElec.text + ' ' + translUsage.text + ' (kWh)';
						let seriesLegend = scope.getSeriesLegend();
						scope.jqpDefaultSetting = new App.JqpDefaultSetting(plotTitel,seriesLegend);
					});
				});
			} else {
				console.log("blance group not matching no graph shown " + balanceGroup.id);
			}
		});
		config.meterPoint.getMonthlyConsumption(function(graphData){
			scope.graphData = graphData;
			if (scope.isReady) {
				scope.render();
			}
		});
		this.isReady = true;
	}
	
	render() {
		if (this.isReady && this.graphData != null && $('#' + this.plotId).length > 0) {
			var plot = $.jqplot(this.plotId, this.graphData, this.jqpDefaultSetting);
		}
	}
	
	getSeriesLegend() {
		if(this.seriesLegend != null){
			return this.seriesLegend;
		}
		this.seriesLegend = [];
		let endYear = new Date().getFullYear();
		let startYear = endYear - 3;
		for (let year = startYear; year <= endYear; year++) {
			this.seriesLegend.push({label:year});
		}
		return this.seriesLegend;
	}
}
export default MonthlyPlot;