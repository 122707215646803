import UI from "./defaultUi.js";
import ObjectFieldHelp from "../objects/ObjectFieldHelp.js";
import OverlayCloseCross from "./OverlayCloseCross.js";
class HelpOverlay extends UI{
	
	constructor(config) {
		super();
		this.init();
		if (config) {
			if (config.fieldId) {
				this.loadByFieldId(config.fieldId);
			} else {
				this.config.helpBoxContent = config.helpBoxContent;
				this.config.helpBoxText = config.helpBoxText;
			}
		}
	}
	
	init() {
		this.helpBoxDiv = $('<div id="helpBox" class="helpBoxOverlay">');
		UI.append(this.helpBoxDiv);
		this.setBaseJQueryObject(this.helpBoxDiv);
		this.boxOverlayContent = $('<div class="boxOverlay-content content_box_group"></div>');
		this.helpBoxDiv.append(this.boxOverlayContent);
		var scope = this;
		window.onclick = function(event) {
		    if (event.target == document.getElementById('helpBox')) {
		    	scope.remove();
		    }
		};
		this.hedlineCB = $('<div class="content_box"></div>');
		this.boxOverlayContent.append(this.hedlineCB);
		this.hedlineDiv = $('<div class="content_box"></div>');
		this.hedlineCB.append(this.hedlineDiv);
		let closeCross = new OverlayCloseCross();
		this.hedlineDiv.append(closeCross.getJQueryRepresentation());
		closeCross.click(function(event){
			scope.remove();
		});
		this.contentCB = $('<div class="content_box"></div>');
		this.boxOverlayContent.append(this.contentCB);
	}
	
	append(toAppend){
		if (toAppend instanceof UI) {
			this.helpBoxDiv.append(toAppend.getJQueryRepresentation());
		}
		this.helpBoxDiv.append(jqObject);
	}
	
	text(text){
		this.helpBoxDiv.text(text);
	}
	
	loadByFieldId(fieldId) {
		if (this.helptextP) {
			this.helptextP.remove();
		}
		this.helptextP = $('<p></p>');
		this.contentCB.append(this.helptextP);
		var scope = this;
		ObjectFieldHelp.load(fieldId, function(objectFieldHelp){
			if(objectFieldHelp == null){
				return;
			}
			scope.helptextP.text(objectFieldHelp.helpText);
		});
	}
}
export default HelpOverlay;