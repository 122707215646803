class EVAEvent {
	constructor(config) {
		if (config) {
			this.type = config.type;
			this.data = config.data;
		}
	}
	
	isError() {
		return this.type == EVAEvent.TYPE_ERROR;
	}
	
	isSaved() {
		return this.type == EVAEvent.TYPE_SAVE;
	}
}
EVAEvent.TYPE_SAVE = "save";
EVAEvent.TYPE_ERROR = "error";
EVAEvent.TYPE_REMOVE = "remove";
export default EVAEvent;