import UploadingOverlay from "../ui/content/UploadingOverlay.js";
import EVAEvent from "../ui/EVAEvent.js";
import Objects from "../objects/Objects.js";
import UI from "../ui/defaultUi.js";

class Upload {
	
	static uploadByInput(url, input, callback){
		let formData = new FormData();
		formData.append("myFile", input[0].files[0]);
		this.uploadingOverlay = new UploadingOverlay();
		let scope = this;
		Upload.postFile(url, formData, function(data){
			scope.uploadingOverlay.remove();
			callback(data);
		});
	}
	
	static postFile(url, formData, callback){
		jQuery.ajax({
		    url: Objects.rootURL + url,
		    data: formData,
		    cache: false,
		    contentType: false,
		    processData: false,
		    method: 'POST',
		    type: 'POST', // For jQuery < 1.9
		    success: function(data){
		    	callback(data);
		    },
			error: function(jqXHR, textStatus, errorThrown ) {
				let event = new EVAEvent({
					type: EVAEvent.TYPE_ERROR,
					data: jqXHR.responseText,
					status: textStatus
				});
				callback(event);
			}
		});
	}
}
export default Upload;