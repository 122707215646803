import SortableListBoxGroup from "../../table/SortableListBoxGroup.js";
import File from "../../../objects/File.js";
import ObjectTranslation from "../../../objects/ObjectTranslation.js";
import Button from "../../Button.js";
import FileUploadOverlay from "./FileUploadOverlay.js";
import Objects from "../../../objects/Objects.js";
import EVAEvent from "../../EVAEvent.js";
import Translation from "../../../objects/Translation.js";
import FileDetailOverlay from "./FileDetailOverlay.js";

class FileList extends SortableListBoxGroup{

	constructor(config) {
		super();
		this.objectTypeId = config.objectTypeId;
		this.objectId = config.objectId;
		this.priviusVersion = config.priviusVersion;

		this.contentHeadline.setHeadlineById(File.OBJECT_DESCRIPTION_ID);
		
		let scope = this;
		this.contentHeadline.getNewButton().click(function(event) {
			new FileUploadOverlay({
				eventCallback: function(event){
					if (event.type == EVAEvent.TYPE_SAVE) {
						scope.pushRow(event.data, true);
					}
				},
				objectTypeId: scope.objectTypeId,
				objectId: scope.objectId,
				priviusVersion: scope.priviusVersion
			});
		}, {});
		this.sortBy("name");

		Translation.load("Datei wirklich loeschen?", function(translation){
			scope.confirmDeleteText = translation.text;
		});
		if (config.files) {
			this.setFiles(config.files);
		} else {
			this.load();
		}
	}
	
	load() {
		let scope = this;
		File.loadAll(this.objectTypeId, this.objectId, function(filesList){
			scope.setFiles(filesList);
		});
	}
	
	setFiles(filesList) {
		this.rowDatas.clear();
		this.pushAll(filesList);
	}

	renderHead() {
		let scope = this;
		let cell = this.table.headerAppendLable(File.FILE_FIELD_DESCRIPTION_ID);
		cell.click(function(){
			scope.sortBy('name');
		});
		cell = this.table.headerAppendLable(File.UPLOADED_FDI);
		cell.click(function(){
			scope.sortBy('uploaded');
		});
	}
	
	renderRow(entry) {
		let scope = this;
		let fileNameA = $('<a/>');
		fileNameA.click({fileId: entry.id}, function(event) {
				event.preventDefault();
				new FileDetailOverlay({
					file: entry
				});
				return false; 
			});
		fileNameA.text(entry.name);
		this.table.addCellDomObj(fileNameA);
		this.table.addDate(entry.uploaded);
		let downloadButton = Button.createDownloadButton();
		downloadButton.click(function(event) {
				window.location.assign(Objects.rootURL + '/api/File/download/' + event.data.file.id + '/' + event.data.file.name);
			}, {file: entry});
		this.table.addCellDomObj(downloadButton);
		
		let deletButton = Button.createDeleteButton();
		deletButton.click(function(event) {
			let r = confirm(scope.confirmDeleteText);
			if (r == true) {
				File.remove(entry.id, function(data){
					scope.load();
				});
			}
			});
		this.table.addCellDomObj(deletButton);
	}
}
export default FileList;