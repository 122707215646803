import UI from "../defaultUi.js";
import ContentBoxGroup from "../content/ContentBoxGroup.js"
import ListDivHeader from "../content/ListDivHeader.js";
import ContentBox from "../ContentBox.js";
import SortableList from "../../misc/SortableList.js";
import Translation from "../../objects/Translation.js";
import StandortUser from "../../objects/StandortUser.js";
import Objects from "../../objects/Objects.js";
import User from "../../objects/User.js";
import ListTable from "../table/ListTable.js";
import Label from "../content/Label.js";
import Button from "../Button.js";

class StandortUserList extends UI{
	
	constructor(config) {
		super();
		this.standort = config.standort;
		this.contentBoxGroup = new ContentBoxGroup();
		super.setBaseJQueryObject(this.contentBoxGroup);
		this.contentHeadline = new ListDivHeader();
		this.contentBoxGroup.append(this.contentHeadline);

		this.rowDatas = new SortableList();
		
		let scope = this;
		Translation.load("Sharings", function(translation){
			scope.contentHeadline.text(translation.text);
		});
		
		this.contentHeadline.getShowButton().click(function(event) {
			scope.render();
			}, {});
		this.contentHeadline.getNewButton().click(function(event) {
			window.location.href = Objects.rootURL + "/Frontend/Standort/Sharings/create/page.jsp?standortId=" + scope.standort;
		}, {});	
		this.contentHeadline.getHideButton().click(function(event) {
			scope.hideContent();
		}, {});
		this.load();
	}
	
	load() {
		var scope = this;
		StandortUser.loadAll(this.standort, function(users){
			for (let i = 0; i < users.length; i++) {
				var entry = {};
				entry.user = users[i];
				scope.pushRow(entry);
			}
		});
	}
	
	pushRow(data) {
		this.rowDatas.push(data);
		this.rerender();
	}
	
	hideContent() {
		this.contentBox.remove();
	}
	
	render() {
		this.contentBox = new ContentBox();
		this.contentBoxGroup.append(this.contentBox);
		this.table = new ListTable();
		this.contentBox.append(this.table);
		this.renderHead();
		this.renderRows();
	}

	renderHead() {
		var scope = this;
		let label = new Label({
			translationKey: "Benutzername",
			table: true
		});
		let cell = this.table.headerAppend(label);
//		cell.click(function(){
//			scope.sortBy('user.username');
//		});
		label = new Label({
			translationKey: "delete",
			table: true
		});
		cell = this.table.headerAppend(label);
//		cell.click(function(){
//			scope.sortBy('meterPoint.name');
//		});
	}
	
	rerender() {
		if (this.table) {
			this.table.clearBody();
			this.renderRows();
		}
	}
	
	sortBy(fieldPath) {
		this.rowDatas.sortBy(fieldPath);
		this.rerender();
	}
	
	renderRows() {
		var scope = this;
		this.rowDatas.forEach(function(entry){
			scope.table.newRow();
			let userA = $('<a href="' + Objects.rootURL + '/Frontend/User/page.jsp?userToEdit=' + entry.user.id + '"></a>');
			userA.text(entry.user.username);
			scope.table.addCellDomObj(userA);
			let deleteButton = Button.createDeleteButton();
			deleteButton.click(function(){
				let userStandort = {
						standortId: scope.standort,
						userId: entry.user.id
				};
				StandortUser.dbDelete(userStandort);
			});
			scope.table.addCellDomObj(deleteButton);
		});
	}
}
export default StandortUserList;