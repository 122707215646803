import Standort from "../../../objects/Standort.js";
import UI from "../../defaultUi.js";
import ShowDetailsHeader from "../../content/ShowDetailsHeader.js";
import Objects from "../../../objects/Objects.js";
import Translation from "../../../objects/Translation.js";
import ContentBox from "../../ContentBox.js";
import LabeledTableCell from "../../table/LabeledTableCell.js";
import LabeledTable from "../../table/LabeledTable.js";
import ContentBoxGroup from "../../content/ContentBoxGroup.js";
import ObjectTranslation from "../../../objects/ObjectTranslation.js";
import FieldTranslation from "../../../objects/FieldTranslation.js";
import WeatherStation from "../../../objects/WeatherStation.js";
import WeatherStationOverlay from "../weatherStation/WeatherStationOverlay.js";

class StandortShowBoxGroup extends UI{
	constructor(config){
		super(config);
		this.contentBoxGroup = new ContentBoxGroup();
		super.setBaseJQueryObject(this.contentBoxGroup);
		this.header = new ShowDetailsHeader();
		this.contentBoxGroup.append(this.header);
		this.contentBox = new ContentBox();
		this.contentBoxGroup.append(this.contentBox);

		let scope = this;
		ObjectTranslation.getObjectNameTranslation(Standort.DESCRIPTON_ID, function(translation){
			scope.header.text(translation.name + ":");
		});
		
		if (config) {
			if (config.standort) {
				this.setStandort(config.standort);
			} else if (config.standortId){
				let scope = this;
				Standort.load(config.standortId, function(standort){
					scope.setStandort(standort);
				});
			}
		}
	}
	
	setStandort(standort) {
		this.standort = standort;
		let scope = this;
		this.header.getEditButton().click(function(){
			window.location.href = Objects.rootURL + "/Frontend/Standort/edit/page.jsp?standortToEdit=" + scope.standort.id;
		});
		Translation.load("Standort wirklich loeschen?", function(translation){
			scope.confirmDeleteText = translation.text;
		});
		this.header.getDeleteButton().click(function(){
			let r = confirm(scope.confirmDeleteText);
			if (r == true) {
				Standort.remove(scope.standort.id, function(data){
					window.location.href = Objects.rootURL + "/Frontend/Standort/page.jsp";
				});
			}
		});
		let table = new LabeledTable({
			realTable:true
		});
		this.contentBox.append(table);
		let cell = new LabeledTableCell({
			fieldId: Standort.FIELD_NAME_ID,
			contentText: this.standort.name,
			realTable:true
		});
		table.append(cell);
		let beginnText = "";
		if(this.standort.beginn != null){
			beginnText = (new Date(this.standort.beginn)).toLocaleDateString();
		}
		cell = new LabeledTableCell({
			fieldId: Standort.FIELD_START_ID,
			contentText: beginnText,
			realTable:true
		});
		table.append(cell);
		cell = new LabeledTableCell({
			fieldId: Standort.FIELD_STREET_ID,
			contentText: this.standort.strasse,
			realTable:true
		});
		table.append(cell);
		let endText = "";
		if(this.standort.ende != null){
			endText = (new Date(this.standort.ende)).toLocaleDateString();
		}
		cell = new LabeledTableCell({
			fieldId: Standort.FIELD_END_ID,
			contentText: endText,
			realTable:true
		});
		table.append(cell);
		
		let zipRegionCell = new LabeledTableCell({
			realTable:true
		});
		table.append(zipRegionCell);
		FieldTranslation.load(Standort.FIELD_ZIP_ID, function(translation){
			let zipRegionLabel = $('<label></label>');
			zipRegionCell.labelCell.append(zipRegionLabel);
			let zipTextSpan = $('<span></span>');
			zipRegionLabel.append(zipTextSpan);
			zipTextSpan.text(translation.name + ' ');
			let zipHelpSpan = $('<span class="helpTextButton glyphicon glyphicon-info-sign" aria-hidden="true"></span>');
			zipRegionLabel.append(zipHelpSpan);
			zipHelpSpan.click(function(){
				let lelpOverlay = new HelpOverlay({
					fieldId: Standort.FIELD_ZIP_ID
				});
				return false;
			});
			FieldTranslation.load(Standort.FIELD_REGION_ID, function(translation){
				let regionTextSpan = $('<span></span>');
				zipRegionLabel.append(regionTextSpan);
				regionTextSpan.text(', ' + translation.name + ' ');
				let regionHelpSpan = $('<span class="helpTextButton glyphicon glyphicon-info-sign" aria-hidden="true"></span>');
				zipRegionLabel.append(regionHelpSpan);
				regionHelpSpan.click(function(){
					let lelpOverlay = new HelpOverlay({
						fieldId: Standort.FIELD_REGION_ID
					});
					return false;
				});
				zipRegionLabel.append(':');
			});
		});
		let zipContentSpan = $('<span></span>');
		zipRegionCell.contentCell.append(zipContentSpan);
		zipRegionCell.contentCell.append(", ");
		zipContentSpan.text(this.standort.plz);
		let regionContentSpan = $('<span></span>');
		zipRegionCell.contentCell.append(regionContentSpan);
		regionContentSpan.text(this.standort.ort);
		
		cell = new LabeledTableCell({
			fieldId: Standort.FIELD_COMMENT_ID,
			contentText: this.standort.bemerkung,
			realTable:true,
			colSpan: 3
		});
		table.append(cell);
		
		let wetherStationCell = new LabeledTableCell({
			fieldId: Standort.FIELD_GTZ_STATION_ID,
			realTable:true,
		});
		table.append(wetherStationCell);
		if (this.standort.gtzStationId != null && this.standort.gtzStationId != 0) {
//			let gtzStationA = $('<a  href="' + Objects.rootURL + '/Frontend/WetterStation/show/page.jsp?wetterStationId=' + this.standort.gtzStationId + '"></a>');
			let gtzStationA = $('<a></a>');
			let scope = this;
			gtzStationA.click(function(){
				if (scope.standort.weatherStation != null) {
					new WeatherStationOverlay({
						weatherStation: scope.standort.weatherStation
					});
					return;
				}
				WeatherStation.load(scope.standort.gtzStationId, function (weatherStation){
					scope.standort.weatherStation = weatherStation;
					new WeatherStationOverlay({
						weatherStation: scope.standort.weatherStation
					});
				});
				
			});
			wetherStationCell.contentCell.append(gtzStationA);
			let stationNameSpan = $('<span></span>');
			gtzStationA.append(stationNameSpan);
			gtzStationA.append(' ');
			let stationDistanceSpan = $('<span></span>');
			gtzStationA.append(stationDistanceSpan);
			WeatherStation.getDistance(this.standort.gtzStationId, this.standort.id, function(distanceResult){
				if(distanceResult == null){
					stationDistanceSpan.text("-- km");
					return;
				}
				let formater = Intl.NumberFormat();
				stationDistanceSpan.text(formater.format(distanceResult.distance) + " km");
			});
			WeatherStation.load(this.standort.gtzStationId, function(weatherStation){
				stationNameSpan.text(weatherStation.name);
			});
		}
	}
}
export default StandortShowBoxGroup;