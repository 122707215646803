import UI from "../defaultUi.js";
import NumberInput from "./NumberInput.js";
import InputGroup from "./InputGroup.js";
import InputGroupAddon from "./InputGroupAddon.js";

class NumberUnitInput extends UI{
	constructor(config){
		super(config);
		this.inputgroup = new InputGroup();
		super.setBaseJQueryObject(this.inputgroup);
		this.inputElement = new NumberInput(config);
		this.inputgroup.append(this.inputElement);
		this.unitAddon = new InputGroupAddon({text:config.unit});
		this.inputgroup.append(this.unitAddon);
	}
	
	setFieldError(){
		this.inputElement.setFieldError();
	}
	
	removeFieldError(){
		this.inputElement.setFieldError();
	}
	
	uiInit() {
	}

	val(newVal) {
		return this.inputElement.val(newVal);
	}
	
	setUnit(unit) {
		this.unitAddon.text(unit);
	}
}
export default NumberUnitInput;