import SortableListBoxGroup from "../../table/SortableListBoxGroup.js";
import License from "../../../objects/License.js";

class LicenseList extends SortableListBoxGroup{
	
	constructor(config) {
		super(config);
		this.licenses = null;
		if (config) {
			if (config.licenses) {
				this.setLicenses(licenses);
			}
		}
		if (this.licenses == null) {
			License.loadAll(this.setLicenses);
		}
	}
	
	setLicenses(licenses) {
		//this.licenses = licenses;
		pushAll(licenses);
	}
	
	renderHead() {
		this.addSortHeaderByFDI(License.TYPE_FDI, 'art');
		this.addSortHeaderByFDI(License.VALID_FROM_FDI, 'gueltigVon');
		this.addSortHeaderByFDI(License.VALID_UNTILL_FDI, 'gueltigBis');
	}
	
	renderRow(entry, row) {
		row.append(entry.getLink());
		row.addDate(entry.gueltigVon);
		row.addDate(entry.gueltigBis);
	}
}
export default LicenseList;