import UI from "../defaultUi.js";

class ContentBoxGroup extends UI{
	constructor(){
		super();
		this.contentBoxGroupDiv = $('<div class="content_box_group"></div>');
		super.setBaseJQueryObject(this.contentBoxGroupDiv);
		
	}
	
	append(toAppend) {
		if (toAppend instanceof UI) {
			this.contentBoxGroupDiv.append(toAppend.getJQueryRepresentation());
		} else{
			this.contentBoxGroupDiv.append(toAppend);
		}
	}
}
export default ContentBoxGroup;