import TableCell from "./TableCell.js";
import Link from "../content/Link.js";

class EmailAddressTableCell extends TableCell {
	constructor(config) {
		if (config == null) {
			config = new Object();
		}
		super(config);
		this.setEmailAddress(config.emailAddress);
	}
	
	setEmailAddress(emailAddress) {
		if (emailAddress == null) {
			this.getContentCell().text("--");
		}
		let scope = this;
		this.clear();
		let mailLink = new Link({
			href: "mailto:" + emailAddress,
			text: emailAddress
		});
		this.append(mailLink);
	}
	
}
export default EmailAddressTableCell;