import UiElement from "../../UiElement.js";
import FloorArea from "../../../objects/FloorArea.js";
import FloorAreaDetailBox from "./FloorAreaDetailBox.js";
import ListByFloorArea from "../floorAreaMeterpoint/ListByFloorArea.js";

class ShowFloorArea extends UiElement {
	constructor(config) {
		super(config);
		this.showDiv = $('<div></div>');
		super.setBaseJQueryObject(this.showDiv);
		this.appendToUi();
		if (config != null) {
			if (config.floorArea != null) {
				this.setFloorArea(floorArea);
			}
			if (config.floorAreaId != null) {
				let scope = this;
				FloorArea.load(config.floorAreaId, function(floorArea){
					scope.setFloorArea(floorArea);
				});
			}
		}
	}
	
	setFloorArea(floorArea) {
		this.floorArea = floorArea;
		this.clear();
		let floorAreaDetail = new FloorAreaDetailBox({
			floorArea: floorArea
		});
		this.append(floorAreaDetail);
		let listByFloorArea = new ListByFloorArea({
			floorArea: floorArea
		});
		this.append(listByFloorArea);
	}
	
	
}
export default ShowFloorArea;