import DetailTable from "../../table/DetailTable.js";
import FloorArea from "../../../objects/FloorArea.js";

class FloorAreaDetailTable extends DetailTable{
	constructor(config) {
		super(config);
		if (config != null) {
			if (config.floorArea != null) {
				this.setFloorArea(config.floorArea);
			}
		}
	}
	
	setFloorArea(floorArea) {
		this.floorArea = floorArea;
		let standortCell = this.addFieldCellBig(FloorArea.FIELD_STANDORT_ID);
		floorArea.getStandort(function(standort){
			if (standort == null) {
				standortCell.text("--");
				return;
			}
			standortCell.getContentCell().append(standort.getLink());
		});
		this.addText(FloorArea.FIELD_NAME_ID, floorArea.bezeichnung);
		this.addNumber(FloorArea.FIELD_AREA_M2_ID, floorArea.bg_flaeche_m2, 'm²');
		this.addDate(FloorArea.FIELD_START_ID, floorArea.start);
		this.addDate(FloorArea.FIELD_END_ID, floorArea.end);
	}
	
}
export default FloorAreaDetailTable