import UI from "../../defaultUi.js";
import OverlayBox from "../../OverlayBox.js";
import Button from "../../Button.js";
import InputTable from "../../table/InputTable.js";
import CorrectionValue from "../../../objects/CorrectionValue.js";
import EVAEvent from "../../EVAEvent.js";
import Observable from "../../../misc/Observable.js";
import MeterPoint from "../../../objects/MeterPoint.js";
import BalanceGroup from "../../../objects/BalanceGroup.js";
import CorrectionValueCategory from "../../../objects/CorrectionValueCategory.js"; 
import Translation from "../../../objects/Translation.js";

class EditCorrectionValueOverlay extends Observable {
	constructor(config) {
		super(config);
		let scope = this;
		this.overlayBox = new OverlayBox({
			isCloseable: true
		});
		let table = new InputTable(1);
		this.overlayBox.append(table);
		
		this.meterPointInputField = table.addSelect(CorrectionValue.FIELD_METER_POINT_ID, "meterPoint");
		this.meterPointInputField.change(function(){
			scope.setMeterPointId(scope.meterPointInputField.val());
		});
		this.balanceGroupInputField = table.addSelect(CorrectionValue.FIELD_BALANCE_GROUP_ID, "balaceGroup");
		this.balanceGroupInputField.change(function(){
			let balanceGroopId = scope.balanceGroupInputField.val();
			if (balanceGroopId == null) {
				return;
			}
			scope.setBalanceGroupId(balanceGroopId);
		});
		BalanceGroup.loadAll(function(balanceGroups) {
			let arrayLength = balanceGroups.length;
			for (let i = 0; i < arrayLength; i++) {
				let balanceGroup = balanceGroups[i];
				let option = scope.balanceGroupInputField.addOption(balanceGroup.id);
				Translation.load(balanceGroup.name, function(translation){
					option.text(translation.text);
				});
				if (scope.correctionValue != null && scope.correctionValue.bilanzgruppeId == balanceGroup.id) {
					option.setSelected(true);
				} else if (scope.meterPoint != null && scope.meterPoint.bilanzgruppeID == balanceGroup.id) {
					option.setSelected(true);
				}
			}
		});
		this.valueInputField = table.addNumberUnitInput(CorrectionValue.FIELD_VALUE_ID,"value");
		this.descriptionInputField = table.addTextArea(CorrectionValue.FIELD_DESCRIPTION_ID, "description");
		this.correctionValueCategoryInputField = table.addSelect(CorrectionValue.FIELD_CATEGORY_ID, "correctionValueCategory");
		this.dateInputField = table.addDateInput(CorrectionValue.FIELD_DATE_ID, "date");
		
		if (config) {
			if (config.standort != null) {
				this.setStandort(config.standort);
			}
			if (config.meterPoint != null) {
				this.setMeterPoint(config.meterPoint);
			}
			if (config.correctionValue != null) {
				this.setCorrectionValue(config.correctionValue);
			}
		}
		
		let saveButton = Button.createSaveButton()
		this.overlayBox.getFooter().append(saveButton);
		saveButton.click(function(event){
			if (scope.correctionValue == null) {
				scope.correctionValue = new CorrectionValue();
				scope.correctionValue.standortId = scope.standort.id;
			}

			scope.correctionValue.wert = scope.valueInputField.val();
			scope.correctionValue.beschreibung = scope.descriptionInputField.val();
			scope.correctionValue.datum = scope.dateInputField.val();
			scope.correctionValue.bilanzgruppeId = scope.balanceGroupInputField.val();
			scope.correctionValue.messpunktId = scope.meterPointInputField.val();
			scope.correctionValue.korrekturwertKategorieId = scope.correctionValueCategoryInputField.val();
			
			scope.correctionValue.upsert(function(correctionValue){
				let event = new EVAEvent({
					type: EVAEvent.TYPE_SAVE,
					data: correctionValue
				});
				scope.notify(event);
				scope.overlayBox.remove();
			});
		});
	}
	
	setCorrectionValue(correctionValue) {
		if (correctionValue == null) {
			this.correctionValue = null;
			if (this.meterPoint == null) {
				this.setBalanceGroupId(BalanceGroup.HEAT_ID);
			}
			return;
		}
		this.correctionValue = correctionValue;
		let scope = this;
		if (this.meterPoint == null) {
			this.correctionValue.getMeterPoint(function(meterPoint){
				scope.meterPoint = meterPoint;
			});
		}
		if (this.standort == null) {
			this.correctionValue.getStandort(function(standort){
				scope.standort = standort;
			});
		}
		this.valueInputField.val(this.correctionValue.wert);
		this.descriptionInputField.val(this.correctionValue.beschreibung);
		this.dateInputField.val(this.correctionValue.datum);
		this.correctionValue.getBalanceGroup(function(balanceGroup) {
			scope.setBalanceGroup(balanceGroup);
		});
	}
	
	setStandort(standort) {
		if (standort == null) {
			this.standort = null;
			return;
		}
		if (this.standort != null && this.standort.id == standort.id) {
			//nothing changed
			return;
		}
		this.standort = standort;
		this.meterPointInputField.clear();
		this.meterPointInputField.addEmpty();
		let scope = this;
		MeterPoint.loadAll(this.standort.id, function(meterPoints){
			let arrayLength = meterPoints.length;
			for (let i = 0; i < arrayLength; i++) {
				let meterPoint = meterPoints[i];
				let option = scope.meterPointInputField.addOption(meterPoint.id, meterPoint.name);
				if (scope.meterPoint != null && scope.meterPoint.id == meterPoint.id) {
					option.setSelected(true);
				}
			}
		});
	}
	
	setBalanceGroup(balanceGroup) {
		if (balanceGroup == null) {
			this.correctionValueCategoryInputField.clear();
			this.correctionValueCategoryInputField.addEmpty();
			this.balanceGroup = null;
			return;
		}
		if (this.balanceGroup != null && this.balanceGroup.id == balanceGroup.id) {
			return;
		}
		this.correctionValueCategoryInputField.clear();
		this.correctionValueCategoryInputField.addEmpty();
		this.balanceGroup = balanceGroup;
		this.balanceGroupInputField.val(balanceGroup.id);
		this.valueInputField.setUnit(this.balanceGroup.getUnit());
		let scope = this;
		CorrectionValueCategory.loadAll(balanceGroup.id, function(correctionValueCategories){
			let arrayLength = correctionValueCategories.length;
			for (let i = 0; i < arrayLength; i++) {
				let correctionValueCategory = correctionValueCategories[i];
				let option = scope.correctionValueCategoryInputField.addOption(correctionValueCategory.id);
				Translation.load(correctionValueCategory.name, function(translation){
					option.setText(translation.text);
				});
			}
		});
	}
	
	setBalanceGroupId(balanceGroupId) {
		let scope = this;
		BalanceGroup.load(balanceGroupId, function(balanceGroup){
			scope.setBalanceGroup(balanceGroup);
		});
	}
	
	setMeterPoint(meterPoint) {
		if (meterPoint == null) {
			this.meterPoint = null;
			this.balanceGroupInputField.disabled(false);
			return;
		}
		if (this.meterPoint != null && this.meterPoint.id == meterPoint.id) {
			//nothing changed
			return;
		}
		this.meterPoint = meterPoint;
		let scope = this;
		if (this.standort == null && this.meterPoint != null) {
			this.meterPoint.getStandort(function(standort){
				scope.setStandort(standort);
			});
		}
		meterPoint.getBalanceGroup(function(balanceGroup) {
			scope.setBalanceGroup(balanceGroup);
			scope.balanceGroupInputField.disabled(true);
		});
	}
	
	setMeterPointId(meterPointId) {
		let scope = this;
		MeterPoint.load(meterPointId, function(meterPoint) {
			scope.setMeterPoint(meterPoint);
		});
	}
	
	setMediumUnits() {
		let scope = this;
		this.meterPoint.getMedium(function(medium){
			medium.getAllUnits(function(mediumUnits){
				let arrayLength = mediumUnits.length;
				for (let i = 0; i < arrayLength; i++) {
					let mediumUnit = mediumUnits[i];
					let option = scope.mediumUnitsInputField.addOption(mediumUnit.id, mediumUnit.name);
					if (scope.meter != null && scope.meter.einheitMediumID == mediumUnit.id) {
						option.setSelected(true);
					}
				}
			});
		});
	}
}
export default EditCorrectionValueOverlay;