import UI from "../defaultUi.js";

class TableCell extends UI{
	constructor(config) {
		super(config);
		if (config) {
			this.realTable = config.realTable;
			if (config.colSpan) {
				this.colSpan = config.colSpan;
			}
		}
		if (this.colSpan == null) {
			this.colSpan = 1;
		}
		if (this.realTable) {
			this.contentCell = $('<td COLSPAN="' + this.colSpan + '"></td>');
		} else {
			this.contentCell = $('<div class="showtable_cell"></div>');
		}
		super.setBaseJQueryObject(this.contentCell);
		
		if(config != null) {
			if (config.contentText) {
				this.text(config.contentText);
			}
			if (config.contentHtml) {
				this.html(config.contentHtml);
			}
			if (config.type) {
				if (config.type == TableCell.TYPE_DATE) {
					this.addClass('date');
				}
				if (config.type == TableCell.TYPE_NUMBER) {
					this.addClass('number');
				}
			}
		}
	}
	
	append(toAppend) {
		if (toAppend instanceof UI) {
			this.contentCell.append(toAppend.getJQueryRepresentation());
		} else {
			this.contentCell.append(toAppend);
		}
	}
	
	getContentCell() {
		return this.contentCell;
	}
	
	text(text){
		return this.contentCell.text(text);
	}
	
	html(html){
		return this.contentCell.html(html);
	}
	
	addClass(cssClass) {
		this.contentCell.addClass(cssClass);
	}
	
	remove() {
		this.contentCell.remove();
	}
	
	clear() {
		this.contentCell.html('');
		return this;
	}
}
TableCell.TYPE_DATE = 'date';
TableCell.TYPE_NUMBER = 'number';
TableCell.TYPE_LINK = 'link';
export default TableCell;