import Observable from "../../../misc/Observable.js";
import OverlayBox from "../../OverlayBox.js";
import Button from "../../Button.js";
import InputTable from "../../table/InputTable.js";
import Consumption from "../../../objects/Consumption.js";
import EVAEvent from "../../EVAEvent.js";

class EditConsumptionOverlay extends Observable {
	constructor(config) {
		super(config);
		if (config) {
			this.meterPoint = config.meterPoint;
			this.consumption = config.consumption;
		}
		let scope = this;
		this.overlayBox = new OverlayBox({
			isCloseable: true
		});
		let table = new InputTable(1);
		this.overlayBox.append(table);
		
		let tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
		
		this.fromInputField = table.addDateInput(Consumption.FROM_FDI, 'from_input');
		this.fromInputField.setMax(tomorrow);
		this.fromInputField.change(function(){
			let fromVal = scope.fromInputField.val();
			if (fromVal) {
				scope.tillInputField.setMin(fromVal);
			}
		});
		this.tillInputField = table.addDateInput(Consumption.TILL_FDI, 'till_input');
		this.tillInputField.setMax(tomorrow);
		this.tillInputField.change(function(){
			let tillVal = scope.tillInputField.val();
			if (tillVal) {
				scope.fromInputField.setMax(tillVal);
			}
		});
		this.amountInputField = table.addNumberInput(Consumption.AMOUNT_FDI, 'amount_input');
		this.unitInputField = table.addSelect(Consumption.UNIT_FDI, 'unit_input');
		this.commentInputField = table.addTextArea(Consumption.COMMENT_FDI, 'comment_input');
		
		this.meterPoint.getMedium(function(medium){
			medium.getAllUnits(function(allUnits){
				let arrayLength = allUnits.length;
				for (let i = 0; i < arrayLength; i++) {
					let unit = allUnits[i];
					let option = scope.unitInputField.addOption(unit.id, unit.name);
					if (scope.consumption) {
						if (scope.consumption.einheitMediumId == unit.id) {
							option.setSelected(true);
						}
					}
				}
			});
		});
		
		if (this.consumption) {
			this.fromInputField.val(this.consumption.von);
			this.fromInputField.trigger("change");
			this.tillInputField.val(this.consumption.bis);
			this.tillInputField.trigger("change");
			this.amountInputField.val(this.consumption.menge);
			this.commentInputField.val(this.consumption.comment);
		}
		
		let saveButton = Button.createSaveButton();
		this.overlayBox.getFooter().append(saveButton);
		saveButton.click(function(event){
			if (scope.consumption == null) {
				scope.consumption = new Object();
				scope.consumption.messpunktId = scope.meterPoint.id;
			}
			scope.consumption.von = scope.fromInputField.val();
			scope.consumption.bis = scope.tillInputField.val();
			scope.consumption.menge = scope.amountInputField.val();
			scope.consumption.einheitMediumId = scope.unitInputField.val();
			scope.consumption.comment = scope.commentInputField.val();
			
			let tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
			let allRequiredFieldsSet = true;
			
			if (scope.consumption.von) {
				if(scope.consumption.von > tomorrow){
					scope.fromInputField.setFieldError();
					allRequiredFieldsSet = false;
				} else {
					scope.fromInputField.removeFieldError();
				}
			} else {
				scope.fromInputField.setFieldError();
				allRequiredFieldsSet = false;
			}
			if (scope.consumption.bis) {
				if(scope.consumption.bis < scope.consumption.von){
					scope.tillInputField.setFieldError();
					allRequiredFieldsSet = false;
				} else {
					scope.tillInputField.removeFieldError();
				}
			} else {
				scope.tillInputField.setFieldError();
				allRequiredFieldsSet = false;
			}
			if (scope.consumption.menge) {
				scope.amountInputField.removeFieldError();
			} else {
				scope.amountInputField.setFieldError();
				allRequiredFieldsSet = false;
			}
			
			if(allRequiredFieldsSet){
				Consumption.upsert(scope.consumption, function(consumption){
					scope.overlayBox.remove();
					let event = new EVAEvent({
						type: EVAEvent.TYPE_SAVE,
						data: consumption
					});
					scope.notify(event);
				});
			}
		});
	}
}
export default EditConsumptionOverlay;