import LabeledTableCell from "./LabeledTableCell.js";
import User from "../../objects/User.js";
import FileSizeTableCell from "./FileSizeTableCell.js";

class LabeledFileSizeTableCell extends LabeledTableCell{
	constructor(config) {
		if (config == null) {
			config = new Object();
		}
		config.contentCell = new FileSizeTableCell(config);
		super(config);
	}

	setFileSize(size) {
		config.contentCell.setFileSize(size);
	}
}
export default LabeledFileSizeTableCell;