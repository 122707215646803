import UI from "../defaultUi.js";
import OverlayCloseCross from "../OverlayCloseCross.js";
import ContentBox from "../ContentBox.js";
import Button from "../Button.js";
class ContentHeadline extends UI {
	constructor() {
		super();
		this.contentBox = new ContentBox();
		super.setBaseJQueryObject(this.contentBox);
		this.headlineDiv = $('<div class="content_headline"></div>');
		this.contentBox.append(this.headlineDiv);
		this.textSpan = $('<span></span>');
		this.headlineDiv.append(this.textSpan);
		this.middleSpan = $('<span></span>');
		this.headlineDiv.append(this.middleSpan);
		this.buttonSpan = $('<span class="content_headline"></span>');
		this.headlineDiv.append(this.buttonSpan);
	}
	
	text(headlineText) {
		return this.textSpan.text(headlineText);
	}
	
	append(toAppend){
		if (toAppend instanceof OverlayCloseCross) {
			return this.headlineDiv.append(toAppend.getJQueryRepresentation());
		}
		if (toAppend instanceof Button) {
			return this.buttonSpan.append(toAppend.getJQueryRepresentation());
		}
		if (toAppend instanceof UI) {
			return this.textSpan.append(toAppend.getJQueryRepresentation());
		}
		return this.headlineDiv.append(toAppend);
	}
	
	getShowButton() {
		if (this.showButton != null) {
			return this.showButton;
		}
		this.showButton = Button.ceateShowButton();
		this.append(this.showButton);
		var scope = this;
		this.showButton.click(function(event) {
			scope.getHideButton().show();
			scope.showButton.hide();
		}, {
			scope: scope
		});
		return this.showButton;
	}
	
	getHideButton() {
		if (this.hideButton != null) {
			return this.hideButton;
		}
		this.hideButton = Button.ceateHideButton();
		this.append(this.hideButton);
		let scope = this;
		this.hideButton.click(function(event) {
			scope.getShowButton().show();
			scope.hideButton.hide();
		}, {
			scope: scope
		});
		return this.hideButton;
	}
	
	getNewButton() {
		if (this.newButton != null) {
			return this.newButton;
		}
		this.newButton = Button.createNewButton();
		this.append(this.newButton);
		return this.newButton;
	}
}
export default ContentHeadline;