import Objects from "./Objects.js";
import UI from "../ui/defaultUi.js";
class ObjectTranslation{
	static getObjectNameTranslation(objectDescriptionId, callback){
		var endpoint = "/api/ObjectNameTranslation/" + objectDescriptionId;
		endpoint = Objects.rootURL + endpoint;
		$.ajax({   
			type: "GET",
			url: endpoint,
			dataType: "json",
			contentType: "application/json; charset=utf-8",
			success: function(data) {
				callback(data);
			},
			error: function(jqXHR, textStatus, errorThrown ) {
				if(jqXHR.status != 401){
					UI.addToError(jqXHR.responseText);
				}
			}
		});
	}
}
export default ObjectTranslation;