import Objects from "./Objects.js";
import Translation from "./Translation.js";

class StandortUser {
	constructor(data) {
		data && Object.assign(this, data);
	}
	
	static loadAll(standortId, callback) {
		Objects.load(callback, StandortUser.API_ENDPOINT + "allByStandort/" + standortId);
		
	}

	static dbDelete(standortUser) {
		Translation.load("Sharing wirklich loeschen?", function(translation){
			let r = confirm(translation.text);
			if (r == true) {
				let url = StandortUser.API_ENDPOINT + standortUser.standortId + "/" + standortUser.userId;
				$.ajax({
					type: "DELETE",  
					url: url,
					dataType: "json",
					contentType: "application/json; charset=utf-8",
					success: function(data) {
						if(data.success === true){
							window.location.href = document.referrer;
						}else{
							cleanError();
							addToError(data.statusMessage);
						}
					},
					  error: function(jqXHR, textStatus, errorThrown ) {
							cleanError();
							addToError(jqXHR.responseText);
							}
					});
			}
		});
	}
}
StandortUser.API_ENDPOINT = "/api/StandortUser/";
export default StandortUser;