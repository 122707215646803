import LabeledTableCell from "./LabeledTableCell.js";
import User from "../../objects/User.js";
import EmailAddressTableCell from "./EmailAddressTableCell.js";

class LabeledEmailAddressTableCell extends LabeledTableCell{
	constructor(config) {
		if (config == null) {
			config = new Object();
		}
		config.contentCell = new EmailAddressTableCell(config);
		super(config);
	}
	
	setEmailAddress(emailAddress) {
		this.getContentCell().setEmailAddress(emailAddress);
		return this;
	}
}
export default LabeledEmailAddressTableCell;