import SwaggerUI from 'swagger-ui'
import 'swagger-ui/dist/swagger-ui.css'
import Objects from "../objects/Objects.js";

class ApiDocuUi{
	constructor() {
		let ui = SwaggerUI({
		  url: Objects.rootURL+"/OpenApi/api.yaml",
		  dom_id: '#swagger',
		});
	}
}
export default ApiDocuUi;