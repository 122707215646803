import UI from "../../defaultUi.js";
import ContentBoxGroup from "../../content/ContentBoxGroup.js";
import ShowDetailsHeader from "../../content/ShowDetailsHeader.js";
import ContentBox from "../../ContentBox.js";
import UserDetailTable from "./UserDetailTable.js";
import User from "../../../objects/User.js";
import Translation from "../../../objects/Translation.js";
import ActivateUserButton from "./ActivateUserButton.js";
import Objects from "../../../objects/Objects.js";
import EditUserOverlay from "./EditUserOverlay.js";
import ResetPasswordButton from "./ResetPasswordButton.js";

class UserDetailBox extends UI{
	constructor(config) {
		super(config);
		
		this.contentBoxGroup = new ContentBoxGroup();
		super.setBaseJQueryObject(this.contentBoxGroup);
		this.header = new ShowDetailsHeader({
			descriptonId: User.DESCRIPTON_ID
		});
		this.contentBoxGroup.append(this.header);
		this.contentBox = new ContentBox();
		this.contentBoxGroup.append(this.contentBox);

		let scope = this;
		Translation.load("User wirklich loeschen?", function(translation){
			scope.confirmDeleteText = translation.text;
		});
		this.activateUserButton = new ActivateUserButton({
			callBack: function (user) {
				scope.setUser(user);
			}
		});
		this.header.append(this.activateUserButton);
		this.resetPasswordButton = new ResetPasswordButton({
			callBack: function (user) {
				// i think no need to react
			}
		});
		this.header.append(this.resetPasswordButton);
		
		if (config != null) {
			if (config.user != null) {
				this.setUser(config.user);
			}
		}
	}
	
	setUser(user) {
		if (this.user != null) {
			this.contentBox.clear();
			this.header.getEditButton().clearClick();
			this.header.getDeleteButton().clearClick();
		}
		this.user = user;
		this.activateUserButton.setUser(user);
		this.resetPasswordButton.setUser(user);
		this.userDetailTable = new UserDetailTable({
			user: this.user
		});
		this.contentBox.append(this.userDetailTable);
		let scope = this;
		this.header.getEditButton().click(function(){
			let editUserOverlay = new EditUserOverlay({
				user: scope.user,
				eventCallback: function(event){
					scope.setUser(event.data);
				}
			});
//			window.location.href = Objects.rootURL + "/Frontend/User/edit/page.jsp?userToEdit=" + scope.user.id;
		});
		this.header.getDeleteButton().click(function(){
			let r = confirm(scope.confirmDeleteText);
			if (r == true) {
				scope.user.remove(function(data){
					if(data.success === true){
						window.location.href = document.referrer;
					}else{
						cleanError();
						addToError(data.statusMessage);
					}
				});
			}
		});
	}
}
export default UserDetailBox;