import Objects from "./Objects.js";
import CachedLoadChain from "../misc/CachedLoadChain.js";

class SupplyType {
	constructor(obj) {
		obj && Object.assign(this, obj);
	}
	
	static load(supplyTypeId, callback){
		SupplyType.cachedLoadChain.load(supplyTypeId, function(data){
			callback(new SupplyType(data));
		});
	}
	
}
SupplyType.API_ENDPOINT = '/api/SupplyType/';
SupplyType.cachedLoadChain = new CachedLoadChain(SupplyType.API_ENDPOINT);

export default SupplyType;