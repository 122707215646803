import Objects from "./Objects.js";
import RequestChain from "../misc/RequestChain.js";
class License {

	constructor(obj) {
		obj && Object.assign(this, obj);
	}
	
	static load(id, callback){
		License.requestChain.push(new LoadRequest(id, callback));
	}

	static loadAll(callback){
		Objects.load(function(data){
			let arrayLength = data.length;
			let licenses = [];
			for (let i = 0; i < arrayLength; i++) {
				licenses.push(new License(data[i]));
			}
			callback(licenses);
		}, License.API_ENDPOINT + "all");
	}
	

	getDisplayText() {
		return this.art;
	}
	
	getURL() {
		return Objects.rootURL + "/Frontend/Lizenz/page.jsp?lizenzId="+this.id
	}
	
	getLink(){
		let link = new Link({
			href: this.getURL(),
			text: this.getDisplayText()
		});
		return link;
	}
}
License.cache = {};
License.requestChain = new RequestChain();

License.API_ENDPOINT = '/api/Lizenz/';
License.DESCRIPTON_ID = 31;
License.TYPE_FDI = 31;
License.VALID_FROM_FDI = 0;
License.VALID_UNTILL_FDI = 0;
License.MAX_USER_FDI = 0;
License.MAX_BUILDINGS_FDI = 0;
License.MAX_FLOOR_FDI = 0;

export default License;

class LoadRequest {
	
	constructor(id, callback){
		this.id = id;
		this.callback = callback;
	}
	
	run() {
		let licenseCacheed = License.cache[this.id];
		if (licenseCacheed) {
			this.callback(licenseCacheed);
			License.requestChain.next();
			return;
		}
		let scope = this;
		Objects.load(function(licenseData){
			let license = new License(licenseData);
			License.cache[scope.id] = license;
			scope.callback(license);
			License.requestChain.next();
		}, License.API_ENDPOINT + scope.id, false);
	}
}