import UI from "../defaultUi.js";
import TableCell from "./TableCell.js";
import Label from "../content/Label.js";
import DateTableCell from "./DateTableCell.js";
import DateTimeTableCell from "./DateTimeTableCell.js";
import NumberTableCell from "./NumberTableCell.js";
import BooleanTableCell from "./BooleanTableCell.js";
import EmailAddressTableCell from "./EmailAddressTableCell.js";
import PhoneNumberTableCell from "./PhoneNumberTableCell.js";

class TableRow extends UI{
	
	constructor(){
		super();
		this.rowJq = $('<tr></tr>');
		super.setBaseJQueryObject(this.rowJq);
	}
	
	append(toAppend) {
		if (toAppend instanceof TableCell) {
			this.rowJq.append(toAppend.getContentCell());
			return;
		}
		let cell = $('<td></td>');
		this.rowJq.append(cell);
		if (toAppend instanceof UI) {
			cell.append(toAppend.getJQueryRepresentation());
		} else {
			cell.append(toAppend);
		}
	}
	
	addCellDomObj(domObj, css){
		var cell = $('<td></td>');
		this.rowJq.append(cell);
		if (domObj instanceof UI) {
			cell.append(domObj.getJQueryRepresentation());
		} else {
			cell.append(domObj);
		}
		if (css) {
			cell.addClass(css);
		}
		return cell;
	}

	
	addText(text){
		let cell = new TableCell({
			contentText: text,
			realTable:true
		});
		this.append(cell);
		return cell;
	}
	
	addDate(date){
		let cell = new DateTableCell({
			date: date,
			realTable:true
		});
		this.append(cell);
		return cell;
	}
	
	addDateTime(date){
		let cell = new DateTimeTableCell({
			date: date,
			realTable:true
		});
		this.append(cell);
		return cell;
	}
	
	addNumber(number, unit){
		let cell = new NumberTableCell({
			number: number,
			unit: unit,
			realTable:true
		});
		this.append(cell);
		return cell;
	}
	
	addBoolean(boolean){
		let cell = new BooleanTableCell({
			boolean: boolean,
			realTable:true
		});
		this.append(cell);
		return cell;
	}

	
	addCell(value, css){
		var cell = $('<td></td>');
		this.rowJq.append(cell);
		cell.text(value);
		if (css) {
			cell.addClass(css);
		}
		return cell;
	}
	
	addEmailAddress(emailAddress) {
		let cell = new EmailAddressTableCell({
			emailAddress: emailAddress,
			realTable:true
		});
		this.append(cell);
		return cell;
	}
	
	addPhoneNumberTableCell(phoneNumber){
		let cell = new PhoneNumberTableCell({
			phoneNumber: phoneNumber,
			realTable:true
		});
		this.append(cell);
		return cell;
	}
}
export default TableRow;