import FileDetailTable from "./FileDetailTable.js";
import Observable from "../../../misc/Observable.js";
import OverlayBox from "../../OverlayBox.js";
import FileList from "./FileList.js";
import File from "../../../objects/File.js";

class FileDetailOverlay extends Observable {
	constructor(config) {
		super(config);
		if (config) {
			this.file = config.file;
			if (!(this.file instanceof File)) {
				this.file = new File(this.file);
			}
		}
		let scope = this;
		this.overlayBox = new OverlayBox({
			isCloseable: true
		});
		this.table = new FileDetailTable({
			file: this.file
		});
		this.overlayBox.append(this.table);
		
		this.priviusFiles = new FileList({
			objectTypeId: this.file.objectDescriptionId,
			objectId: this.file.objectId,
			files: this.file.getPriviusVersions(),
			priviusVersion: this.file.id
		});
		this.overlayBox.append(this.priviusFiles);
	}
}
export default FileDetailOverlay;