import CachedLoadChain from "../misc/CachedLoadChain.js";
import Objects from "./Objects.js";

class Language {
	constructor(obj){
		obj && Object.assign(this, obj);
	}
	
	static load(id, callback){
		Language.cachedLoadChain.load(id, function(data){
			callback(new Language(data));
		});
	}
	
	static loadAll(callback) {
		let scope = this;
		Objects.load(function(data){
			let arrayLength = data.length;
			let languages = [];
			for (let i = 0; i < arrayLength; i++) {
				let language = new Language(data[i]);
				languages.push(language);
				Language.cachedLoadChain.cache[language.id] = language;
			}
			callback(languages);
		}, Language.API_ENDPOINT + "all");
	}
}
Language.API_ENDPOINT = "/api/Language/";
Language.cachedLoadChain = new CachedLoadChain(Language.API_ENDPOINT);
export default Language;