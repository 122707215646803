import UI from "../../defaultUi.js";
import OverlayBox from "../../OverlayBox.js";
import Button from "../../Button.js";
import InputTable from "../../table/InputTable.js";
import User from "../../../objects/User.js";
import EVAEvent from "../../EVAEvent.js";
import Observable from "../../../misc/Observable.js";
import Language from "../../../objects/Language.js";

class EditUserOverlay extends Observable {
	constructor(config) {
		super(config);
		if (config) {
			this.user = config.user;
			this.license = config.license;
		}
		let scope = this;
		this.overlayBox = new OverlayBox({
			isCloseable: true
		});
		let table = new InputTable(1);
		this.overlayBox.append(table);

		this.userNameInputField = table.addInput(User.USER_NAME_FIELD_DESCRIPTION_ID, 'edit_user_username');
		this.emailInputField = table.addEmailInput(User.EMAIL_FIELD_DESCRIPTION_ID, 'edit_email');
		this.languageSelectField = table.addSelect(User.LANGUAGE_FIELD_DESCRIPTION_ID, 'edit_user_spracheId');
		Language.loadAll(function(languages) {
			let arrayLength = languages.length;
			for (let i = 0; i < arrayLength; i++) {
				let language = languages[i];
				let option = scope.languageSelectField.addOption(language.id, language.name);
				if (scope.user != null) {
					if (scope.user.sprache_id == language.id) {
						option.setSelected(true);
					}
				}
			}
		});
		this.firstNameInputField = table.addInput(User.FIRST_NAME_FIELD_DESCRIPTION_ID, 'edit_user_first_name');
		this.lastNameInputField = table.addInput(User.LAST_NAME_FIELD_DESCRIPTION_ID, 'edit_user_last_name');
		this.newsletterInputField = table.addBooleanInput(User.NEWSLETTER_FDI, 'edit_user_newsletter');
		
		if(this.user != null){
			this.userNameInputField.val(this.user.username);
			this.emailInputField.val(this.user.email);
			this.firstNameInputField.val(this.user.firstName);
			this.lastNameInputField.val(this.user.lastName);
			this.newsletterInputField.val(this.user.newsLetter);
		}
		
		let saveButton = Button.createSaveButton()
		this.overlayBox.getFooter().append(saveButton);
		saveButton.click(function(event){
			if (scope.user == null) {
				scope.user = new User();
				scope.user.lizenzID = scope.license;
			}
			scope.user.username = scope.userNameInputField.val();
			scope.user.email = scope.emailInputField.val();
			scope.user.sprache_id = scope.languageSelectField.val();
			scope.user.firstName = scope.firstNameInputField.val();
			scope.user.lastName = scope.lastNameInputField.val();
			scope.user.newsLetter = scope.newsletterInputField.val();
			scope.user.upsert(function(user){
				let event = new EVAEvent({
					type: EVAEvent.TYPE_SAVE,
					data: user
				});
				scope.notify(event);
				scope.overlayBox.remove();
			});
		});
	}
}
export default EditUserOverlay;