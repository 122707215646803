import UI from "../defaultUi.js";
import FieldTranslation from "../../objects/FieldTranslation.js";
import Translation from "../../objects/Translation.js";
import HelpOverlay from "../HelpOverlay.js";
class Label extends UI{
	constructor(config){
		super();
		this.label = $('<label></label>');
		super.setBaseJQueryObject(this.label);
		if (config.forField) {
			this.label.attr('for', config.forField);
		}
		this.textSpan = $('<span></span>');
		this.label.append(this.textSpan);
		var scope = this;
		if (config.fieldId != null) {
			FieldTranslation.load(config.fieldId, function(translation){
				if (translation) {
					scope.text(translation.name + ' ');
				} else {
					scope.text(config.fieldId + ' ');
				}
				var span = $('<span class="helpTextButton glyphicon glyphicon-info-sign" aria-hidden="true"></span>');
				scope.label.append(span);
				if (config.table == null) {
					scope.label.append(':');
				}
				span.click(function(){
					let lelpOverlay = new HelpOverlay({
						fieldId: config.fieldId
					});
					return false;
				});
			});
		} else if (config.translationKey != null) {
			Translation.load(config.translationKey, function(translation){
				scope.text(translation.text);
				if (config.table == null) {
					scope.label.append(':');
				}
			});
		} else if (config.text != null) {
			scope.text(config.text);
			if (config.table == null) {
				scope.label.append(':');
			}
		}
	}
	
	text(text) {
		return this.textSpan.text(text);
	}
	
	append(toAppend) {
		return this.label.append(toAppend);
	}
}
export default Label;