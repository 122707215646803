import Button from "../../Button.js";
import Translation from "../../../objects/Translation";
import ConfirmOverlay from "../../content/ConfirmOverlay.js";
import InfoOverlay from "../../content/InfoOverlay.js";
class ResetPasswordButton extends Button{
	constructor(config) {
		super(config);
		if (config) {
			this.setUser(config.user);
			this.setCallback(config.callBack);
		}
		this.textTranslate('reset password');
		this.iconClass('glyphicon-refresh');	
		let scope = this;
		this.click(function() {
			let confirmOverlay = new ConfirmOverlay({
				text: 'reset user password?',
				okCallback: function(){
					scope.user.resetPassword(function(user){
						let infoOverlay = new InfoOverlay({
							text: 'password reseted.'
						});
						scope.callBack(user);
					});
				}
			});
		},null);
	}
	
	setUser(user) {
		this.user = user;
		if (user == null) {
			return;
		}
	}
	
	setCallback(callBack) {
		this.callBack = callBack;
	}
}
export default ResetPasswordButton;