import UI from "../../defaultUi.js";
import OverlayBox from "../../OverlayBox.js";
import Button from "../../Button.js";
import InputTable from "../../table/InputTable.js";
import Goal from "../../../objects/Goal.js";
import EVAEvent from "../../EVAEvent.js";
import Observable from "../../../misc/Observable.js";

class EditGoalProgressOverlay extends Observable {
	constructor(config) {
		super(config);
		if (config) {
			this.objectTypeId = config.objectTypeId;
			this.objectId = config.objectId;
			this.goal = config.goal;
		}
		let scope = this;
		this.overlayBox = new OverlayBox({
			isCloseable: true
		});
		let table = new InputTable(1);
		this.overlayBox.append(table);
		
		this.progressInputField = table.addPercentageInput(Goal.PROGRESS_FIELD_ID, 'progress_input');
		
		if (this.goal) {
			this.progressInputField.val(this.goal.progress);
		} else {
			console.debug("no goal set to edit progress!");
			return;
		}
		
		let saveButton = Button.createSaveButton()
		this.overlayBox.getFooter().append(saveButton);
		saveButton.click(function(event){
			if (scope.goal == null) {
				console.debug("no goal set to edit progress!");
				return;
			}
			scope.goal.progress = scope.progressInputField.val();
			
			Goal.updateProgress(scope.goal, function(goal){
				scope.overlayBox.remove();
				let event = new EVAEvent({
					type: EVAEvent.TYPE_SAVE,
					data: goal
				});
				scope.notify(event);
			});
		});
	}
}
export default EditGoalProgressOverlay;