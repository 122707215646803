import Objects from "./Objects.js";
import CachedLoadChain from "../misc/CachedLoadChain.js";
import Link from "../ui/content/Link.js";

class Reading {
	
	constructor(obj) {
		obj && Object.assign(this, obj);
	}
	
	static loadNewest(id, callback){
		Objects.load(function(data){
			let reading = new Reading(data);
			Reading.cachedLoadChain.cache[reading.id] = reading;
			callback(reading);
		}, Reading.API_ENDPOINT + 'newest/' + id);
	}
	
	static loadAll(meterId, callback){
		Objects.load(function(data){
			Reading.handleListResult(callback, data);
		}, Reading.API_ENDPOINT + "all/" + meterId);
	}
	
	static handleListResult(callback, data) {
		let arrayLength = data.length;
		let readings = [];
		for (let i = 0; i < arrayLength; i++) {
			let reading = new Reading(data[i]);
			readings.push(reading);
			Reading.cachedLoadChain.cache[reading.id] = reading;
		}
		callback(readings);
	}
	
	static uploadCSV(formData, meterId, callback){
		let adress;
		if (priviusVersion) {
			adress = Objects.rootURL + File.ENDPOINT + "uploadNewVersion?objectTypeId=" + objectTypeId + "&objectId=" + objectId + "&priviusVersion=" + priviusVersion;
		} else {
			adress = Objects.rootURL + File.ENDPOINT + "upload/" + objectTypeId + "/" + objectId;
		}
		jQuery.ajax({
		    url: adress,
		    data: formData,
		    cache: false,
		    contentType: false,
		    processData: false,
		    method: 'POST',
		    type: 'POST', // For jQuery < 1.9
		    success: function(data){
		    	callback(data);
		    },
			error: function(jqXHR, textStatus, errorThrown ) {
				let event = new EVAEvent({
					type: EVAEvent.TYPE_ERROR,
					data: textStatus
				});
				callback(event);
				if(jqXHR.status != 401){
					UI.addToError(jqXHR.responseText);
				}
			}
		});
	}
	
	getURL() {
		return Objects.rootURL + "/Frontend/Ablesung/page.jsp?ablesungID="+this.id
	}
	
	getLink(){
		let dateObj = new Date(this.datum);
		let link = new Link({
			href: this.getURL(),
			text: dateObj.toLocaleDateString()
		});
		return link;
	}
	
}
Reading.API_ENDPOINT = "/api/Ablesung/";
Reading.cachedLoadChain = new CachedLoadChain(Reading.API_ENDPOINT);
Reading.OBJECT_DESCRIPTION_ID = 30;
Reading.DATE_FDI = 78;
Reading.READING_FDI = 79;
export default Reading;