import User from "../../objects/User.js";
export default class JqpDefaultSetting {
	constructor(titleText, seriesLabels) {
		let scope = this;
		User.getCurrentUser(function(user){
			 user.getNumberformat(function(formater){
				 scope.userFormater = formater;
			},0);
		});
		
    	this.title= {
            text: titleText, // title for the plot,
            show: true,
        };
        // The "seriesDefaults" option is an options object that will
        // be applied to all series in the chart.
    	this.seriesDefaults={
        	renderer:$.jqplot.BarRenderer,
        	rendererOptions: {fillToZero: true}
        };
        // Custom labels for the series are specified with the "label"
        // option on the series option.  Here a series option object
        // is specified for each series.
    	this.series = seriesLabels;
        
    	this.legend= {
            show:true,
            placement: 'outsideGrid'
        };
    	this.axesDefaults= {
            tickRenderer: $.jqplot.CanvasAxisTickRenderer ,
        };
    	this.axes= {
            // Use a category axis on the x axis and use our custom ticks.
            xaxis: {
                renderer: $.jqplot.CategoryAxisRenderer,
	            tickOptions: {
		              angle: -30,
		            },
            },
            // Pad the y axis just a little so bars can get close to, but
            // not touch, the grid boundaries.  1.2 is the default padding.
            yaxis: {
                pad: 1.25,
                min: 0.0,
                tickOptions: {
                	formatter: function(format, value) {return scope.userFormater.format(value)},
                	//formatString: "%'d",
                	},
            }
        };
		this.cursor={
			zoom:true,
			show:true
		};
		this.highlighter= {
			show: true,
			tooltipAxes: 'y',
		};
	}
}