import UI from "./defaultUi.js";
import Translation from "../objects/Translation.js"
class Button extends UI {

	constructor(config) {
		super();
		this.initButton(config);
	}
	
	initButton(config) {
		this.button = $('<button type="button"></button>');
		super.setBaseJQueryObject(this.button);
		this.iconSpan = $('<span class="glyphicon" aria-hidden="true"></span>');
		this.button.append(this.iconSpan);
		if (config && config.iconCssClass) {
			this.iconClass(config.iconCssClass);
		} else {
			this.iconSpan.hide();
		}
		this.textSpan = $('<span></span>');
		this.button.append(this.textSpan);
		if (config && config.buttonText) {
			this.text(config.buttonText);
		}
	}
	
	click(onClick, onClickParam) {
		this.button.click(onClickParam, onClick);
	}
	
	triggerClick() {
		this.button.trigger( "click" );
	}
	
	text(buttonText) {
		this.textSpan.text(buttonText);
	}
	
	textTranslate(buttonText) {
		let scope = this;
		Translation.load(buttonText, function(translation){
			scope.text(' ' + translation.text);
		});
	}
	
	iconClass(iconCssClass) {
		this.iconSpan.removeClass();
		this.iconSpan.addClass('glyphicon');
		this.iconSpan.addClass(iconCssClass);
		this.iconSpan.show();
	}
	
	clearClick() {
		this.button.off('');
	}
	
	static createEditButton(){
		let button = new Button({
			iconCssClass: Button.EDIT_ICON	
		});
		button.textTranslate('edit');
		return button;
	}
	
	static createCancelButton(){
		let button = new Button({
			iconCssClass: Button.CANCEL_ICON	
		});
		button.textTranslate('cancel');
		return button;
	}
	
	static createNewButton(){
		let button = new Button({
			iconCssClass: Button.NEW_ICON	
		});
		button.textTranslate('create');
		return button;
	}
	
	static ceateShowButton(){
		let button = new Button({
			iconCssClass: Button.SHOW_ICON	
		});
		button.textTranslate('show');
		return button;
	}
	
	static ceateHideButton(){
		let button = new Button({
			iconCssClass: Button.HIDE_ICON	
		});
		button.textTranslate('hide');
		return button;
	}
	
	static createSaveButton(){
		let button = new Button({
			iconCssClass: Button.SAVE_ICON	
		});
		button.textTranslate('save');
		return button;
	}
	
	static createDeleteButton(){
		let button = new Button({
			iconCssClass: Button.DELETE_ICON	
		});
		button.textTranslate('delete');
		return button;
	}
	
	static createDownloadButton(){
		let button = new Button({
			iconCssClass: Button.DOWNLOAD_ICON	
		});
		button.textTranslate('download');
		return button;
	}
	
	static createHistoryButton(){
		let button = new Button({
			iconCssClass: Button.HISTORY_ICON	
		});
		button.textTranslate('history');
		return button;
	}
	
	static createUploadCsvButton(){
		let button = new Button({
			iconCssClass: Button.UPLOAD_ICON	
		});
		button.textTranslate('upload csv');
		return button;
	}
	
	static createDownloadCsvButton(){
		let button = new Button({
			iconCssClass: Button.DOWNLOAD_ICON	
		});
		button.textTranslate('download csv');
		return button;
	}
}
Button.SAVE_ICON = "glyphicon-floppy-disk";
Button.EDIT_ICON = "glyphicon-pencil";
Button.CANCEL_ICON = "glyphicon-remove";
Button.NEW_ICON = "glyphicon-pencil";
Button.SHOW_ICON = "glyphicon-eye-open";
Button.HIDE_ICON = "glyphicon-eye-close";
Button.DELETE_ICON = "glyphicon-trash";
Button.DOWNLOAD_ICON = "glyphicon-save-file";
Button.HISTORY_ICON = "glyphicon-eye-open";
Button.UPLOAD_ICON = "glyphicon-open-file";

export default Button;