import SortableListBoxGroup from "../../table/SortableListBoxGroup.js";
import Reading from "../../../objects/Reading.js";
import Objects from "../../../objects/Objects.js";
import Translation from "../../../objects/Translation.js";
import Meter from "../../../objects/Meter.js";
import Label from "../../content/Label.js";
import Button from "../../Button.js";
import UploadReadingsCSV from "./UploadReadingsCSV.js";

class ReadingList extends SortableListBoxGroup{
	constructor(config) {
		super(config);
		this.contentHeadline.setHeadlineById(Reading.OBJECT_DESCRIPTION_ID);
		let scope = this;
		this.contentHeadline.getNewButton().click(function(event) {
			window.location.href = Objects.rootURL + "/Frontend/Ablesung/create/page.jsp?validate_date=false&create_ablesung.Zaehler=" + scope.meter.id;
		}, {});
		
		let uploadCsvButton = Button.createUploadCsvButton();
		this.contentHeadline.append(uploadCsvButton);
		uploadCsvButton.click(function(event) {
			new UploadReadingsCSV({
				meter:scope.meter,
				eventCallback: function(event) {
					scope.clearRows();
					scope.load();
				}
				});
		});
		let downloadCsvButton = Button.createDownloadCsvButton();
		this.contentHeadline.append(downloadCsvButton);
		downloadCsvButton.click(function(event) {
			window.location.assign(Objects.rootURL + '/api/Ablesung/readings_for_meter' + scope.meter.id + '.csv');
		});

		if (config) {
			if (config.meterId) {
				Meter.load(config.meterId, function(meter){
					this.setMeter(meter);
				});
			}
			if (config.meter) {
				this.setMeter(config.meter);
			}
		}
	}
	
	setMeter(meter){
		this.meter = meter;
		if (this.meter != null) {
			let show = this.showing;
			if (this.showing) {
				this.hideContent();
			}
			this.load();
			if (show) {
				this.render();
			}
		}
	}
	
	load() {
		let scope = this;
		Reading.loadAll(this.meter.id, function(readings){
			scope.pushAll(readings);
		});
	}
	
	renderHead() {
		this.addSortHeaderByFDI(Reading.DATE_FDI, 'datum');
		this.addSortHeaderByFDI(Reading.READING_FDI, 'zaehlerStand');
		let label = new Label({});
		this.addSortHeaderElement(label, 'berechneterVerbrauch');
		let scope = this;
		Translation.load('Verbrauch', function(translation){
			let consuptionTranslationSpan = $('<span></span>');
			consuptionTranslationSpan.text(translation.text);
			label.append(consuptionTranslationSpan);
			label.append($('<br>'));
			let unitSpan = $('<span></span>');
			label.append(unitSpan);
			if (scope.meter) {
				scope.meter.getMediumUnit(function(mediumUnit){
					unitSpan.text('(' + mediumUnit.name + ')');
				});
			}
		});
	}

	renderRow(entry) {
		this.table.addCellDomObj(entry.getLink(), 'date');
		this.table.addNumber(entry.zaehlerStand);
		this.table.addNumber(entry.berechneterVerbrauch * this.meter.faktor);
	}
	
}
export default ReadingList;