import UI from "../../defaultUi.js";
import SortableListBoxGroup from "../../table/SortableListBoxGroup.js";
import Label from "../../content/Label.js";
import FloorArea from "../../../objects/FloorArea.js";
import ObjectTranslation from "../../../objects/ObjectTranslation.js";
import Objects from "../../../objects/Objects.js";
import FloorAreaMeterPointJunction from "../../../objects/FloorAreaMeterPointJunction.js";

class MeterPointFloorAreaList extends SortableListBoxGroup {
	
	constructor(config) {
		super(config);
		if (config) {
			if (config.meterPointId) {
				this.meterPointId = config.meterPointId;
			}
			if (config.meterPoint) {
				this.meterPointId = config.meterPoint.id;
			}
		}
		this.contentHeadline.setHeadlineById(FloorArea.OBJECT_DESCRIPTION_ID);
		if (this.meterPointId != null) {
			let scope = this;
			this.contentHeadline.getNewButton().click(function(event) {
				window.location.href = Objects.rootURL + "/Frontend/FlaecheMesspunkt/create/page.jsp?messpunktId=" + scope.meterPointId;
			}, {});
			this.load();
		}
	}
	
	load() {
		let scope = this;
		FloorAreaMeterPointJunction.allByMeterPoint(this.meterPointId, function(floorAreaMeterPointJunctions){
			scope.pushAll(floorAreaMeterPointJunctions);
		});
	}

	renderHead() {
		let scope = this;
		let label = new Label({
			fieldId: FloorArea.FIELD_NAME_ID,
			table: true
		});
		let cell = this.table.headerAppend(label);
		cell.click(function(){
			scope.sortBy('floorArea.bezeichnung');
		});
		label = new Label({
			fieldId: FloorArea.FIELD_AREA_M2_ID,
			table: true
		});
		cell = this.table.headerAppend(label);
		cell.click(function(){
			scope.sortBy('floorArea.bg_flaeche_m2');
		});
	}
	
	renderRow(entry) {
		let scope = this;
		entry.getFloorArea(function(floorArea){
			let areaA = $('<a href="' + Objects.rootURL + '/Frontend/Flaeche/page.jsp?flaecheID=' + floorArea.id + '"></a>');
			areaA.text(floorArea.bezeichnung);
			scope.table.addCellDomObj(areaA);
			scope.table.addNumber(floorArea.bg_flaeche_m2);
		});
	}
}
export default MeterPointFloorAreaList;